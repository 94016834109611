import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Button,
} from "@material-ui/core"
import { useEffect, useState } from "react"
import { Permissions } from "../../../config/Permissions"
import { useAppSelector } from "../../../hooks/storeHooks"
import { hasPermission } from "../../../services/auth"
import {
    deleteEmailDestinatary,
    getEmailDestinataries,
} from "../../../services/destinatary"
import DeleteButton from "../../Shared/DeleteButton"
import QuestionModal from "../../Shared/QuestionModal"
import EmailDestinataryFormModal from "./EmailDestinataryFormModal"

const SmsDestinatariesTable: React.FC = () => {
    const emailDestinataries = useAppSelector(
        (s) => s.destinataryReducer.emailDestinataries
    )
    const [targetDestinatary, setTargetDestinatary] =
        useState<EmailDestinatary>(null)
    useEffect(() => {
        getEmailDestinataries()
    }, [])
    const [deleteDestinataryModalOpen, setDeleteDestinataryModalOpen] =
        useState(false)
    const [emailDestinataryFormModalOpen, setEmailDestinataryFormModalOpen] =
        useState(false)

    const canEdit =
        useAppSelector((s) => s.authReducer.isEditing) &&
        hasPermission(Permissions.ModifyEmailDestinataries)

    return (
        <div className="p-3">
            <div className="flex justify-between p-1">
                Destinatarios Email
                {canEdit && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEmailDestinataryFormModalOpen(true)}
                    >
                        Nuevo
                    </Button>
                )}
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Email</TableCell>
                        {canEdit && <TableCell></TableCell>}
                    </TableHead>
                    <TableBody>
                        {emailDestinataries.map((ed) => (
                            <TableRow key={ed.id}>
                                <TableCell>{ed.name}</TableCell>
                                <TableCell>{ed.address}</TableCell>
                                {canEdit && (
                                    <TableCell>
                                        <DeleteButton
                                            onClick={async () => {
                                                setTargetDestinatary(ed)
                                                setDeleteDestinataryModalOpen(
                                                    true
                                                )
                                            }}
                                        ></DeleteButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <QuestionModal
                onYes={async () => {
                    await deleteEmailDestinatary(targetDestinatary)
                    getEmailDestinataries()
                }}
                open={deleteDestinataryModalOpen}
                text={"¿Seguro que desea remover este destinatario?"}
                onClose={() => setDeleteDestinataryModalOpen(false)}
                snackbarNotificationText="Destinatario eliminado"
            ></QuestionModal>
            <EmailDestinataryFormModal
                open={emailDestinataryFormModalOpen}
                onClose={() => setEmailDestinataryFormModalOpen(false)}
            ></EmailDestinataryFormModal>
        </div>
    )
}
export default SmsDestinatariesTable
