import { Reducer } from 'react'

interface State {
	isLoading: boolean
	dataloggers: Datalogger[]
}

const initialState: State = {
	isLoading: false,
	dataloggers: []
}

export enum DataloggerActions {
	RequestDataloggers = 'REQUEST_DATALOGGERS',
	GetDataloggers = 'GET_DATALOGGERS',
	SetDataloggers = 'SET_DATALOGGERS',
}

const reducer: Reducer<State, { type: DataloggerActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case DataloggerActions.RequestDataloggers:
			return {
				...state,
				isLoading: state.isLoading
			}
		case DataloggerActions.SetDataloggers:
			return {
				...state,
				isLoading: state.isLoading,
				dataloggers: [
					...payload.dataloggers,
				],
			}
		default:
			return state
	}
}

export default reducer
