import { Reducer } from "react";

interface State {
    isLoading: boolean,
    projectUnits: ProjectUnit[],
    selectedProjectUnits: ProjectUnit[]
}

const initialState: State = {
    isLoading: false,
    projectUnits: [],
    selectedProjectUnits: [],
}

export enum ProjectUnitActions {
    RequestProjectUnits = 'REQUEST_PROJECT_UNITS',
    SetProjectUnits = 'SET_PROJECT_UNITS',
    SetSelectedProjectUnits = 'SET_SELECTED_PROJECT_UNITS',
    AddSelectedProjectUnit = 'ADD_SELECTED_PROJECT_UNIT',
    RemoveSelectedProjectUnit = 'REMOVE_SELECTED_PROJECT_UNIT',
    ClearSelectedProjectUnits = 'CLEAR_SELECTED_PROJECT_UNITS',
}

const reducer: Reducer<State, { type: ProjectUnitActions, payload: any }> = (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case ProjectUnitActions.RequestProjectUnits:
            return { ...state, isLoading: true }
        case ProjectUnitActions.SetProjectUnits:
            return { ...state, isLoading: false, projectUnits: payload }
        case ProjectUnitActions.SetSelectedProjectUnits:
            return { ...state, selectedProjectUnits: payload }
        case ProjectUnitActions.AddSelectedProjectUnit:
            return { ...state, selectedProjectUnits: [...state.selectedProjectUnits, payload] }
        case ProjectUnitActions.RemoveSelectedProjectUnit:
            return { ...state, selectedProjectUnits: state.selectedProjectUnits.filter(p => p.id !== payload.id) }
        case ProjectUnitActions.ClearSelectedProjectUnits:
            return { ...state, selectedProjectUnits: [] }
        default:
            return state;
    }
}

export default reducer