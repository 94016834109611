import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
} from '@material-ui/core'
import { useEffect } from 'react'
import { useState } from 'react'
import { useAppSelector } from '../../hooks/storeHooks'
import { useForm } from '../../hooks/useForm'
import { getChannels } from '../../services/channel'
import { createDefaultDeltaChannel } from '../../services/sensor'
import { withError } from '../../services/snackbarNotification'

interface Props {
	sensor: Sensor
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
}

const ChannelFormModal: React.FC<Props> = ({ sensor, open, onClose }) => {
	const [values, setValues, handleChange] = useForm({
		label: '',
		isVirtual: true,
		formula: '',
		icon: '',
	})
	const channels = useAppSelector((s) => s.channelReducer.channels)
	const [baseChannel, setbaseChannel] = useState(null);

	useEffect(() => {
		if (open) {
			const mesa = sensor.label.split('-')[2]
			const base_channel = channels.find(o => o.label === 'Distancia-' + mesa)
			setbaseChannel(base_channel)
			const vals = {
				label: 'vdelta-' + mesa,
				isVirtual: true,
				formula: '(_' + base_channel.id + '_ - ' + base_channel.last_reading_value + ') * 100',
				icon: 'distance',
			}
			setValues(vals)
		}
	}, [open]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
			<DialogContent className='p-2'>
				<div className='w-full flex'>

					<div className='w-1/2 p-2'>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Etiqueta
						</div>
						<TextField
							id='channel_label'
							fullWidth
							color='primary'
							label='Etiqueta'
							size='medium'
							value={values.label}
							onChange={handleChange}
							disabled={true}
						/>
					</div>
					<div className='w-1/2 p-2'>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Virtual
						</div>
						<Checkbox
							id='virtual'
							color='primary'
							size='medium'
							value={values.isVirtual}
							onChange={handleChange}
							checked={true}
							disabled={true}
						/>
					</div>
				</div>
				<div></div>
				<div className='flex w-full'>
					<div className='w-1/2 p-2'>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Icono
						</div>
						<TextField
							id='icon'
							fullWidth
							color='primary'
							label='Icono'
							size='medium'
							value={values.icon}
							onChange={handleChange}
							disabled={true}
						/>
					</div>
					<div className={'w-1/2 p-2' + redIfnull(baseChannel)}>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Formula
						</div>
						<TextField
							id='formula'
							fullWidth
							color='primary'
							label='Formula'
							size='medium'
							value={values.formula}
							onChange={handleChange}
							disabled={true}
						/>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					color='primary'
					className='m-2'
					variant='contained'
					onClick={withError(async () => {
						// const val = await createSensor(values)
						const val = await createDefaultDeltaChannel(sensor)
						if (true) {
							getChannels()
							// fetchSensors()
							onClose()

						}
					})}
				>
					Crear Canal
				</Button>
				<Button
					color='secondary'
					className='m-2'
					variant='contained'
					onClick={async () => {
						onClose()
					}}
				>
					Cancelar
				</Button>
			</DialogActions>
		</Dialog >
	)
}
export default ChannelFormModal

function redIfnull(baseChannel) {
	return baseChannel?.last_reading_value ? '' : ' bg-red-600'
}

