import { Button, Input, InputAdornment } from '@material-ui/core'
import { mdiBarcode, mdiEmail, mdiKey } from '@mdi/js'
import { Icon } from '@mdi/react'
import { useEffect, useRef, useState } from 'react'
import { useForm } from '../../hooks/useForm'
import { passwordResetConfirm, passwordResetRequest } from '../../services/auth'
import { addSnackbarNotification } from '../../services/snackbarNotification'
import { mainColor } from '../../styles/styleVars'

interface Props {
	goBack: () => void
}

const PasswordRecovery: React.FC<Props> = ({ goBack }) => {
	const [hasToken, setHasToken] = useState(false)

	const emailInput = useRef<HTMLInputElement>(null)

	const [values, setValues, handleChange] = useForm({
		email: '',
		token: '',
		new_password: '',
	})

	useEffect(() => {
		setValues({
			email: '',
			token: '',
			new_password: '',
		})
	}, [hasToken])

	return (
		<div className='bg-white rounded-xl shadow-sm pt-10 pb-2 px-10 flex flex-col'>
			{!hasToken ? (
				<form
					className='text-center'
					key='password-reset-request'
					id='password-reset-request'
				>
					<input type='hidden' value='something' />
					<div
						className='mt-10 text-sm text-main-1 text-center cursor-pointer'
						onClick={() => setHasToken(true)}
					>
						¿Ya tienes <b>tu código?</b>
					</div>
					<Input
						type='text'
						placeholder='Email'
						id='email'
						value={values.email}
						className='mt-8 w-8/12 mx-auto'
						onChange={handleChange}
						endAdornment={
							<InputAdornment position='end'>
								<Icon
									path={mdiEmail}
									title=''
									size={1}
									color={mainColor}
								/>
							</InputAdornment>
						}
					/>
					<div className='mt-2'>
						<Button
							variant='contained'
							color='primary'
							onClick={async () => {
								try {
									await passwordResetRequest(values.email)
									addSnackbarNotification({
										message: 'Código enviado a tu correo.',
									})
								} catch (e) {
									addSnackbarNotification({
										type: 'error',
										message: 'Ha ocurrido un error',
									})
								}
							}}
						>
							Enviar Código
						</Button>
					</div>
				</form>
			) : (
				<form
					className='text-center'
					key='password-reset-confirm'
					id='password-reset-confirm'
				>
					<input type='hidden' value='something' />

					<div
						className='mt-10 text-sm text-main-1 text-center cursor-pointer'
						onClick={() => setHasToken(false)}
					>
						¿No tienes <b>tu código?</b>
					</div>
					<Input
						inputRef={emailInput}
						autoComplete='off'
						type='text'
						placeholder='Email'
						id='email'
						value={values.email}
						className='mt-8 w-8/12 mx-auto'
						onChange={handleChange}
						readOnly
						onClick={() => {
							emailInput.current.readOnly = false
						}}
						endAdornment={
							<InputAdornment position='end'>
								<Icon
									path={mdiEmail}
									title=''
									size={1}
									color={mainColor}
								/>
							</InputAdornment>
						}
					/>
					<Input
						autoComplete='text'
						type='text'
						placeholder='Código de recuperación'
						id='token'
						value={values.token}
						className='mt-8 w-8/12 mx-auto'
						onChange={handleChange}
						endAdornment={
							<InputAdornment position='end'>
								<Icon
									path={mdiBarcode}
									title=''
									size={1}
									color={mainColor}
								/>
							</InputAdornment>
						}
					/>
					<Input
						type='password'
						autoComplete='password'
						placeholder='Contraseña'
						id='new_password'
						value={values.new_password}
						className='mt-8 w-8/12 mx-auto'
						onChange={handleChange}
						endAdornment={
							<InputAdornment position='end'>
								<Icon
									path={mdiKey}
									title='Password'
									size={1}
									color={mainColor}
								/>
							</InputAdornment>
						}
					/>
					<div
						className='mt-2'
						onClick={async () => {
							try {
								await passwordResetConfirm(
									values.email,
									values.token,
									values.new_password
								)
								addSnackbarNotification({
									message: 'Contraseña cambiada',
								})
								goBack()
							} catch (e) {
								addSnackbarNotification({
									type: 'error',
									message: 'Ha ocurrido un error',
								})
							}
						}}
					>
						<Button variant='contained' color='primary'>
							Cambiar Password
						</Button>
					</div>
				</form>
			)}
			<div className='flex items-center space-x-2 justify-center mt-5'>
				<Button onClick={goBack} variant='outlined'>
					Volver al login
				</Button>
			</div>
		</div>
	)
}
export default PasswordRecovery
