import { mdiAlertOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { useState } from 'react'
import { setHoverChannel } from '../../../../services/channel'
import { defaultMarkerColor } from '../../../../styles/styleVars'
import { ParameterIcons } from '../../../Utilities/ParameterIcons'
import InfoWindow from './InfoWindow'

interface Props {
	lat: number
	lng: number
	sensor: Sensor
	id?: string
}
const SensorMarker: React.FC<Props> = ({ sensor, id }) => {
	const [showInfoWindow, setShowInfoWindow] = useState(false)

	return (
		<div
			className='rounded-full  w-6 p-1items-center border border-black'
			onMouseOver={() => setHoverChannel(sensor.default_channel)}
			onMouseLeave={() => setHoverChannel(null)}
			id={id}
			style={{
				backgroundColor: sensor.is_operative
					? sensor.default_channel?.last_condition?.color ||
					  defaultMarkerColor
					: sensor.condition?.color,
			}}
			onClick={() => setShowInfoWindow(!showInfoWindow)}
		>
			<Icon
				//color={show ? 'black' : 'transparent'}
				path={
					sensor.is_operative
						? ParameterIcons[
								sensor.default_channel?.parameter?.icon ||
									'default'
						  ]
						: mdiAlertOutline
				}
				style={{ width: 'inherit' }}
				className={`p-1  ${sensor.is_operative ? '' : 'animate-blink'}`}
			></Icon>
			{showInfoWindow && <InfoWindow sensor={sensor}></InfoWindow>}
		</div>
	)
}
export default SensorMarker
