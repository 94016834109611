import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    createStyles,
    makeStyles,
    Theme,
} from "@material-ui/core"
import { mdiAutorenew, mdiChevronDown, mdiMemory } from "@mdi/js"
import Icon from "@mdi/react"
import { useState } from "react"
import { useAppSelector } from "../../../hooks/storeHooks"
import {
    addSelectedChannel,
    getChannelsBySensor,
    removeSelectedChannel,
} from "../../../services/channel"
import { setSelectedSensor } from "../../../services/sensor"
import { analysisProjectUnitIconColor } from "../../../styles/styleVars"
import Checkbox from "../../Shared/Checkbox"

interface Props {
    sensor: Sensor
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({ root: { padding: 0 }, content: { margin: 0 } })
)

const MultiSelectSensorExpandable: React.FC<Props> = ({ sensor }) => {
    const classes = useStyles()
    const [channels, setChannels] = useState<Channel[]>([])
    const [loading, setLoading] = useState(false)
    const selectedChannels = useAppSelector(
        (s) => s.channelReducer.selectedChannels
    )

    const loadChannels = async () => {
        setLoading(true)
        const channelData = await getChannelsBySensor(sensor)
        setChannels(channelData)
        setLoading(false)
    }

    return (
        <Accordion
            classes={{ root: classes.root }}
            elevation={0}
            className="p-0 m-0"
            onChange={(_, isExpanded) => isExpanded && loadChannels()}
        >
            <AccordionSummary
                classes={{ root: classes.root, content: classes.content }}
                expandIcon={
                    <Icon
                        path={mdiChevronDown}
                        className="w-6"
                        color="#BBB"
                    ></Icon>
                }
            >
                <div
                    className="flex w-ful items-center font-light cursor-pointer text-sm px-2"
                    onClick={() => setSelectedSensor(sensor)}
                >
                    <div>
                        <Icon
                            path={mdiMemory}
                            color={analysisProjectUnitIconColor}
                            className="w-6 mr-2"
                        ></Icon>
                    </div>
                    <div className="flex-1 text-sm">{sensor.label}</div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                {loading ? (
                    <div className="w-full flex justify-center">
                        <Icon
                            path={mdiAutorenew}
                            color={"#AAA"}
                            className="w-7 m-auto"
                            spin
                        ></Icon>
                    </div>
                ) : (
                    <div className="font-light cursor-pointer">
                        {!!channels?.length &&
                            channels.map((c) => {
                                const selected = !!selectedChannels.find(
                                    (sc) => c.id === sc.id
                                )
                                console.log(selected)
                                return (
                                    <div
                                        className={`
                                    px-2 rounded
                                    flex space-x-2 items-center
                                    ${
                                        selected
                                            ? "bg-secondary-1 bg-opacity-30"
                                            : ""
                                    }`}
                                        key={c.id}
                                    >
                                        <Checkbox
                                            checked={selected}
                                            onChange={(e, checked) =>
                                                checked
                                                    ? addSelectedChannel(c)
                                                    : removeSelectedChannel(c)
                                            }
                                        ></Checkbox>
                                        {c.label}
                                    </div>
                                )
                            })}
                    </div>
                )}
            </AccordionDetails>
        </Accordion>
    )
}
export default MultiSelectSensorExpandable
