import { useAppSelector } from "../../../hooks/storeHooks"
import ProjectUnitCard from "./ProjectCard/ProjectUnitCard"

const ProjectUnitList: React.FC = () => {
    const projectUnits = useAppSelector((s) => s.projectReducer.projectUnits)
    return (
        <div className="flex-1 overflow-auto px-3 pt-4">
            {!!projectUnits.length ? (
                <>
                    {projectUnits.map((p) => (
                        <ProjectUnitCard
                            key={p.id}
                            projectUnit={p}
                        ></ProjectUnitCard>
                    ))}
                </>
            ) : (
                <div>No hay proyectos</div>
            )}
        </div>
    )
}
export default ProjectUnitList
