import { Reducer } from "react";

interface State {
    snackbarNotifications: SnackbarNotification[]
}

const initialState: State = {
    snackbarNotifications: []
}

export enum SackbarNotificationActions {
    AddSnackbarNotification = 'ADD_SNACKBAR_NOTIFICATION',
    RemoveSnackbarNotification = 'REMOVE_SNACKBAR_NOTIFICATION',
}

const reducer: Reducer<State, { type: SackbarNotificationActions, payload: any }> = (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case SackbarNotificationActions.AddSnackbarNotification:
            return { ...state, snackbarNotifications: [...state.snackbarNotifications, payload] }
        case SackbarNotificationActions.RemoveSnackbarNotification:
            return { ...state, snackbarNotifications: state.snackbarNotifications.filter(n => n.id !== payload) }
        default:
            return state;
    }
}

export default reducer