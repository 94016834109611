import { Reducer } from "react";

interface State {
    isLoading: boolean,
    organizations: Organization[],
}

const initialState: State = {
    isLoading: false,
    organizations: [],
}

export enum OrganizationActions {
    RequestOrganizations = 'REQUEST_ORGANIZATIONS',
    SetOrganizations = 'SET_PROJECT_ORGANIZATIONS',
}

const reducer: Reducer<State, { type: OrganizationActions, payload: any }> = (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case OrganizationActions.RequestOrganizations:
            return { ...state, isLoading: true }
        case OrganizationActions.SetOrganizations:
            return { ...state, isLoading: false, organizations: payload }
        default:
            return state;
    }
}

export default reducer