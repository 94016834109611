import { useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks/storeHooks'
import useStyles from '../../styles/appStyles'
import MultiSelectProjectUnitsListCard from './MultiSelectProjectUnitsListCard/MultiSelectProjectUnitsListCard'
import moment from 'moment-timezone'
import { getChannelInterval } from '../../services/channel'
import MultiChannelChart from './MultiChannelChart'
const DEFAULT_DELTA = +process.env
	.REACT_APP_SINGLE_CHANNEL_CHART_DEFAULT_DELTA_IN_SECONDS as number

const MultiChannelContainer: React.FC = () => {
	const timeZone = useAppSelector((s) => s.timeReducer.timeZone)
	const classes = useStyles(true)()

	const selectedChannels = useAppSelector(
		(s) => s.channelReducer.selectedChannels
	)

	const [timeRange, setTimeRange] = useState({
		start: moment().tz(timeZone).subtract(DEFAULT_DELTA, 'seconds'),
		end: moment().tz(timeZone),
	})
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		getData()
	}, [selectedChannels])

	const [data, setData] = useState<{
		[id: number]: {
			[field_name_origin: string]: number
			timestamp: number
		}[][]
	}>({})

	const getData = async ({ setY } = { setY: false }) => {
		const start = timeRange.start.utc().format('YYYY-MM-DD HH:mm:ss')
		const end = timeRange.end.utc().format('YYYY-MM-DD HH:mm:ss')
		setLoading(true)
		const channelsData = {}
		for await (const channel of selectedChannels) {
			channelsData[channel.id] = await getChannelInterval(
				channel,
				start,
				end
			)
		}
		setData(() => channelsData)
		setLoading(false)
		// setY && centerY(data)
	}

	return (
		<div className={`flex bg-gray-100 ${classes.bodyContent}`}>
			<div className='w-1/6 overflow-auto'>
				<MultiSelectProjectUnitsListCard></MultiSelectProjectUnitsListCard>
			</div>
			<div className='w-5/6'>
				<MultiChannelChart></MultiChannelChart>
			</div>
		</div>
	)
}
export default MultiChannelContainer
