import moment from 'moment-timezone'
import { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Routes } from '../../../../config/Routes'
import { useAppSelector } from '../../../../hooks/storeHooks'
import { setSelectedChannel } from '../../../../services/channel'

interface Props {
	sensor: Sensor
}

const InfoWindow: React.FC<Props> = ({ sensor }) => {
	const bubble = useRef<HTMLDivElement>()
	const history = useHistory()
	const timeZone = useAppSelector((s) => s.timeReducer.timeZone)

	return (
		<div
			style={{
				position: 'absolute',
				bottom: 20,
				left: -72,
				zIndex: 1000,
			}}
		>
			<div
				className='p-2 rounded bg-white w-80  relative shadow flex text-xs'
				ref={bubble}
			>
				<div className='w-5/6'>
					<div className='trucate overflow-ellipsis'>
						<span className='font-semibold'>Nodo:</span>{' '}
						{sensor.label}
					</div>
					<div className='trucate overflow-ellipsis'>
						<span className='font-semibold'>Canal:</span>{' '}
						{sensor.default_channel?.label}
					</div>
					<div className='trucate overflow-ellipsis'>
						<span className='font-semibold'>
							Valor Última Lectura:
						</span>{' '}
						{sensor.default_channel?.last_reading_value}
						{sensor.default_channel?.parameter.symbol}
					</div>
					<div className='trucate overflow-ellipsis'>
						<span className='font-semibold'>
							Fecha Última Lectura:
						</span>{' '}
						{moment(sensor.default_channel?.last_reading_datetime)
							.tz(timeZone)
							.format('DD/MM/YYYY HH:mm:ss')}
					</div>
				</div>
				<div className='w-1/6 flex flex-col justify-around'>
					<div
						className='text-center p-1 rounded bg-gray-400 text-white cursor-pointer hover:bg-gray-300 transition-all'
						onClick={() => {
							setSelectedChannel(sensor.default_channel)
							sensor.default_channel &&
								history.push(Routes.Analysis)
						}}
					>
						Análisis
					</div>
					<div
						className='text-center p-1 rounded bg-gray-400 text-white cursor-pointer hover:bg-gray-300 transition-all'
						onClick={() => {
							setSelectedChannel(sensor.default_channel)
							sensor.default_channel &&
								history.push(Routes.Alerts)
						}}
					>
						Alertas
					</div>
				</div>
			</div>
			<span
				style={{
					content: '',
					position: 'absolute',
					border: '8px solid transparent',
					borderTopColor: '#fff',
					borderBottom: '0',
					borderRight: '0',
					left: '50%',
					bottom: '0',
					marginLeft: '-85px',
					marginBottom: '-7px',
				}}
				className='w-0 h-0 '
			></span>
		</div>
	)
}
export default InfoWindow
