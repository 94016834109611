import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
} from '@material-ui/core'
import { useState } from 'react'
import { getChannels } from '../../services/channel'
import { getDataloggers } from '../../services/datalogger'
import { deleteSensor, fetchSensors } from '../../services/sensor'
import { withError } from '../../services/snackbarNotification'

interface Props {
	open: boolean
	targetSensor: Sensor
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
}

const DeleteSensorModal: React.FC<Props> = ({ open, targetSensor, onClose }) => {
	const [unlinkSensor, setUnlinkSensor] = useState(false)

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
			<DialogContent className=''>
				<div className='w-full'>
					<div className='w-full text-main-1 text-lg mb-2 font-medium uppercase'>
						Seguro que desea eliminar el sensor {targetSensor?.label}?
					</div>
				</div>
				<div className='w-full text-main-1 text-md mb-2 font-medium '>
					Si no exporta antes los datos no se podrán recuperar.
				</div>
				<div className='w-full text-main-1 text-md mb-2 font-medium '>
					<Checkbox
						value={unlinkSensor}
						checked={unlinkSensor}
						onClick={() => setUnlinkSensor(!unlinkSensor)}
					/>
					Desenlazar
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					color='primary'
					className='m-2'
					variant='contained'
					onClick={withError(async () => {
						const val = await deleteSensor(targetSensor, unlinkSensor)
						fetchSensors()
						getDataloggers()
						getChannels()
						onClose()
					})}
				>
					Estoy seguro, eliminar.
				</Button>
			</DialogActions>
		</Dialog>
	)
}
export default DeleteSensorModal
