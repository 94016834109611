import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    createStyles,
    makeStyles,
    Theme,
} from "@material-ui/core"
import { mdiAutorenew, mdiChevronDown, mdiMemory } from "@mdi/js"
import Icon from "@mdi/react"
import { useState } from "react"
import { useAppSelector } from "../../../hooks/storeHooks"
import {
    getChannelsBySensor,
    setSelectedChannel,
} from "../../../services/channel"
import { setSelectedSensor } from "../../../services/sensor"
import { analysisProjectUnitIconColor } from "../../../styles/styleVars"

interface Props {
    sensor: Sensor
}

const sensorLabel = (sensor: Sensor) => {
    return (
        sensor.installation.equipment.model +
        " " +
        sensor.installation.equipment.serial_number
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({ root: { padding: 0 }, content: { margin: 0 } })
)

const SensorExpandable: React.FC<Props> = ({ sensor }) => {
    const classes = useStyles()
    const selectedChannel = useAppSelector(
        (s) => s.channelReducer.selectedChannel
    )
    const [channels, setChannels] = useState<Channel[]>([])
    const [loading, setLoading] = useState(false)

    const loadChannels = async () => {
        setLoading(true)
        const channelData = await getChannelsBySensor(sensor)
        setChannels(channelData)
        setLoading(false)
    }

    return (
        <Accordion
            classes={{ root: classes.root }}
            elevation={0}
            className="p-0 m-0"
            onChange={(_, isExpanded) => isExpanded && loadChannels()}
        >
            <AccordionSummary
                classes={{ root: classes.root, content: classes.content }}
                expandIcon={
                    <Icon
                        path={mdiChevronDown}
                        className="w-6"
                        color="#BBB"
                    ></Icon>
                }
            >
                <div
                    className="flex w-ful items-center font-light cursor-pointer text-sm px-2"
                    onClick={() => setSelectedSensor(sensor)}
                >
                    <div>
                        <Icon
                            path={mdiMemory}
                            color={analysisProjectUnitIconColor}
                            className="w-6 mr-2"
                        ></Icon>
                    </div>
                    <div className="flex-1 text-sm">{sensor.label}</div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                {loading ? (
                    <div className="w-full flex justify-center">
                        <Icon
                            path={mdiAutorenew}
                            color={"#AAA"}
                            className="w-7 m-auto"
                            spin
                        ></Icon>
                    </div>
                ) : (
                    <div className="font-light cursor-pointer">
                        {!!channels?.length &&
                            channels.map((c) => (
                                <div
                                    className={`
                                    px-2 rounded
                                    ${
                                        selectedChannel?.id === c.id
                                            ? "bg-secondary-1 bg-opacity-30"
                                            : ""
                                    }`}
                                    key={c.id}
                                    onClick={() => setSelectedChannel(c)}
                                >
                                    {c.label}
                                </div>
                            ))}
                    </div>
                )}
            </AccordionDetails>
        </Accordion>
    )
}
export default SensorExpandable
