import { Reducer } from "react";

interface State {
    loadingAlerts: boolean,
    alertsPopupVisible: boolean,
    alerts: AlertStatus[]
}

const initialState: State = {
    loadingAlerts: false,
    alertsPopupVisible: false,
    alerts: []
}

export enum AlertActions {
    RequestAlerts = 'REQUEST_ALERTS',
    SetAlerts = 'SET_ALERTS',
    ShowAlertsPopup = 'SHOW_ALERTS_POPUP',
    HideAlertsPopup = 'HIDE_ALERTS_POPUP'
}

const reducer: Reducer<State, { type: AlertActions, payload: any }> = (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case AlertActions.RequestAlerts:
            return { ...state, loadingAlerts: true }
        case AlertActions.SetAlerts:
            return { ...state, loadingAlerts: false, alerts: payload }
        case AlertActions.ShowAlertsPopup:
            return { ...state, alertsPopupVisible: true }
        case AlertActions.HideAlertsPopup:
            return { ...state, alertsPopupVisible: false }
        default:
            return state;
    }
}

export default reducer