import { Card } from '@material-ui/core'
import { useAppSelector } from '../../../hooks/storeHooks'
import MultiSelectProjectUnitExpandable from './MultiSelectProjectUnitExpandable'

const MultiSelectProjectUnitsListCard: React.FC = () => {
	const projectUnits = useAppSelector((s) => s.projectReducer.projectUnits)
	return (
		<div className='overflow-auto  flex-1'>
			<Card>
				{!!projectUnits?.length ? (
					projectUnits.map((p) => (
						<MultiSelectProjectUnitExpandable
							projectUnit={p}
							key={p.id}
						></MultiSelectProjectUnitExpandable>
					))
				) : (
					<div>No hay proyectos</div>
				)}
			</Card>
		</div>
	)
}
export default MultiSelectProjectUnitsListCard
