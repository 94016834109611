import { Card } from "@material-ui/core"
import { useAppSelector } from "../../../hooks/storeHooks"
import ProjectUnitExpandable from "./ProjectUnitExpandable"

const ProjectUnitsListCard: React.FC = () => {
    const projectUnits = useAppSelector((s) => s.projectReducer.projectUnits)
    return (
        <div className="overflow-y-auto  flex-1">
            <Card>
                {!!projectUnits?.length ? (
                    projectUnits.map((p) => (
                        <ProjectUnitExpandable
                            projectUnit={p}
                            key={p.id}
                        ></ProjectUnitExpandable>
                    ))
                ) : (
                    <div>No hay proyectos</div>
                )}
            </Card>
        </div>
    )
}
export default ProjectUnitsListCard
