import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    MenuItem,
    Select,
} from "@material-ui/core"
import { useAppSelector } from "../../../hooks/storeHooks"
import { useForm } from "../../../hooks/useForm"
import { getChannels } from "../../../services/channel"
import {
    assignChannel,
    getConditionGroups,
} from "../../../services/conditionGroup"
import { withError } from "../../../services/snackbarNotification"

interface Props {
    open: boolean
    onClose?: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void
}

const AddConditionToChannellGroupModal: React.FC<Props> = ({
    open,
    onClose,
}) => {
    const channel = useAppSelector((s) => s.channelReducer.selectedChannel)
    const conditionGroups = useAppSelector(
        (s) => s.conditionGroupReducer.conditionGroups
    )
    const [values, , handleChange] = useForm<{ conditionGroup: number }>({
        conditionGroup: 0,
    })
    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose()
            }}
            maxWidth="xs"
            fullWidth
        >
            <DialogContent>
                <Select
                    onChange={handleChange}
                    id="conditionGroup"
                    name="conditionGroup"
                    value={values.conditionGroup}
                    fullWidth
                >
                    <MenuItem value={0}>Seleccione un grupo</MenuItem>
                    {conditionGroups
                        .filter(
                            (cg) =>
                                !channel?.condition_groups.find(
                                    (ccg) => ccg === cg.id
                                )
                        )
                        .map((cg) => (
                            <MenuItem key={cg.id} value={cg.id}>
                                {cg.name}
                            </MenuItem>
                        ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={withError(async () => {
                        await assignChannel(
                            channel,
                            conditionGroups.find(
                                (cg) => cg.id === values.conditionGroup
                            )
                        )
                        getConditionGroups()
                        getChannels()
                        onClose()
                    })}
                    variant="contained"
                >
                    Agregar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default AddConditionToChannellGroupModal
