import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useAppSelector } from "../../../hooks/storeHooks"
import { changeConditionProperties } from "../../../services/condition"
import { getConditionGroups } from "../../../services/conditionGroup"
import { withError } from "../../../services/snackbarNotification"
import Checkbox from "../../Shared/Checkbox"

interface Props {
    open: boolean
    onClose?: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void
    condition: Condition
}

const AsignActionModesModal: React.FC<Props> = ({
    open,
    onClose,
    condition,
}) => {
    const actionModes = useAppSelector((s) => s.actionModeReducer.actionModes)
    const [values, setValues] = useState<{ [id: number]: boolean }>({})

    useEffect(() => {
        if (condition && open) {
            const v = {}
            actionModes.forEach((am) => {
                v[am.id] = condition.action_modes.includes(am.id)
            })
            setValues(v)
        }
    }, [actionModes, condition, open])

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose()
            }}
            maxWidth="xs"
            fullWidth
        >
            <DialogContent>
                <div className="flex flex-col">
                    {actionModes?.map((am) => (
                        <Checkbox
                            checked={values[am.id]}
                            key={am.id}
                            label={am.name}
                            onChange={(e, checked) =>
                                setValues((prev) => ({
                                    ...prev,
                                    [am.id]: checked,
                                }))
                            }
                        ></Checkbox>
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={withError(async () => {
                        await changeConditionProperties(condition, {
                            action_modes: Object.keys(values)
                                .filter((id) => values[+id])
                                .map((id) => +id),
                        }).then(getConditionGroups)

                        onClose()
                    })}
                >
                    Guardar
                </Button>
                <Button onClick={() => onClose()}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    )
}
export default AsignActionModesModal
