import { useAppSelector } from "../../hooks/storeHooks"
import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"

import { TransitionProps } from "@material-ui/core/transitions"
import store from "../../store"
import { AlertActions } from "../../reducers/AlertReducer"
import moment from "moment-timezone"
import { mdiChevronDown } from "@mdi/js"
import { resolveAlert } from "../../services/alert"
import { Checkbox, FormControlLabel } from "@material-ui/core"
import Icon from "@mdi/react"
import { withError } from "../../services/snackbarNotification"

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />
})

// const StyledTableCell = withStyles((theme: Theme) =>
//   createStyles({
//     head: {
//       backgroundColor: theme.palette.common.black,
//       color: theme.palette.common.white,
//     },
//     body: {
//       fontSize: 14,
//     },
//   }),
// )(TableCell);

// const StyledTableRow = withStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       '&:nth-of-type(odd)': {
//         backgroundColor: theme.palette.action.hover,
//       },
//     },
//   }),
// )(TableRow);
enum SortColumns {
    Priority = "PRIORITY",
    Time = "TIME",
    Condition = "CONDITION",
    Channel = "CHANNEL",
    Sensor = "SENSOR",
    Status = "STATUS",
    Value = "VALUE",
}

const AlertsStatus: React.FC = () => {
    const open = useAppSelector((s) => s.alertReducer.alertsPopupVisible)
    const alerts = useAppSelector((s) => s.alertReducer.alerts)
    const [showResolved, setShowResolved] = useState(false)
    const sensors = useAppSelector((s) =>
        Object.values(s.sensorReducer.sensorsByProjectUnit).reduce(
            (acc, s) => [...acc, ...s],
            []
        )
    )
    const timeZone = useAppSelector((s) => s.timeReducer.timeZone)
    const channels = useAppSelector((s) => s.channelReducer.channels)
    const [orderHistory, setOrderHistory] = useState<
        { column: SortColumns; desc: boolean }[]
    >([])

    //   const handleClickOpen = () => {
    //     setOpen(true);
    //   };

    const handleClose = () => {
        store.dispatch({ type: AlertActions.HideAlertsPopup, payload: {} })
    }
    const getValue = (row) => {
        // console.log(row)
        if (row.condition.condition_type === "time") {
            // alert()
            return moment
                .duration(
                    row.value,
                    "seconds"
                )
                .humanize()
        } else {
            return row.value
        }
    }
    const mapPriorities = (row) => {
        return ["Alta", "Normal", "Baja"][+row.condition.priority - 1]
    }

    const filterAlerts = (row) => {
        if (showResolved) {
            return true
        } else {
            return row.status !== "resolved"
        }
    }
    const sort = (col: SortColumns) => {
        const desc = orderHistory.length
            ? orderHistory[orderHistory.length - 1].column === col
                ? !orderHistory[orderHistory.length - 1].desc
                : true
            : true
        setOrderHistory((oh) => [
            ...oh.filter((o) => o.column !== col),
            { column: col, desc },
        ])
    }

    const sortedAlerts = [...alerts]
    if (orderHistory.length) {
        orderHistory.forEach((o) => {
            switch (o.column) {
                case SortColumns.Priority:
                    sortedAlerts.sort(
                        (a, b) => a.condition.priority - b.condition.priority
                    )
                    break
                case SortColumns.Time:
                    sortedAlerts.sort(
                        (a, b) =>
                            new Date(a.update).getTime() -
                            new Date(b.update).getTime()
                    )
                    break
                case SortColumns.Channel:
                    sortedAlerts.sort((a, b) => a.channel - b.channel)
                    break

                case SortColumns.Status:
                    sortedAlerts.sort((a, b) =>
                        a.status.localeCompare(b.status)
                    )
                    break
                case SortColumns.Condition:
                    sortedAlerts.sort((a, b) =>
                        a.condition.name.localeCompare(b.condition.name)
                    )
                    break
                case SortColumns.Sensor:
                    sortedAlerts.sort((a, b) =>
                        sensors
                            .find((s) => s.id === a.sensor)
                            ?.installation.equipment.serial_number.localeCompare(
                                sensors.find((s) => s.id === b.sensor)
                                    ?.installation.equipment.serial_number
                            )
                    )
                    break
                case SortColumns.Value:
                    sortedAlerts.sort((a, b) => a.value - b.value)
                    break
                default:
                    break
            }
            o.desc && sortedAlerts.reverse()
        })
    }

    const primaryOrder = orderHistory.length
        ? orderHistory[orderHistory.length - 1]
        : null

    return (
        <div>
            <Dialog
                maxWidth="lg"
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {"Alertas"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        component="div"
                    >
                        <div className="mr-1 p-5">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={showResolved}
                                        onChange={() => {
                                            setShowResolved(!showResolved)
                                        }}
                                        size="small"
                                    />
                                }
                                label="Mostrar Resueltas"
                            ></FormControlLabel>
                        </div>
                        <TableContainer component={Paper}>
                            <Table className="" aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align="right"
                                            onClick={() =>
                                                sort(SortColumns.Priority)
                                            }
                                            className="cursor-pointer"
                                        >
                                            <div className="flex">
                                                Prioridad
                                                {primaryOrder?.column ===
                                                    SortColumns.Priority && (
                                                        <Icon
                                                            className="transition-all w-5"
                                                            rotate={
                                                                primaryOrder.desc
                                                                    ? 0
                                                                    : 180
                                                            }
                                                            path={mdiChevronDown}
                                                        ></Icon>
                                                    )}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            onClick={() =>
                                                sort(SortColumns.Time)
                                            }
                                            className="cursor-pointer"
                                        >
                                            <div className="flex">
                                                Hora
                                                {primaryOrder?.column ===
                                                    SortColumns.Time && (
                                                        <Icon
                                                            className="transition-all w-5"
                                                            rotate={
                                                                primaryOrder.desc
                                                                    ? 0
                                                                    : 180
                                                            }
                                                            path={mdiChevronDown}
                                                        ></Icon>
                                                    )}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            onClick={() =>
                                                sort(SortColumns.Condition)
                                            }
                                            className="cursor-pointer"
                                        >
                                            <div className="flex">
                                                Condición
                                                {primaryOrder?.column ===
                                                    SortColumns.Condition && (
                                                        <Icon
                                                            className="transition-all w-5"
                                                            rotate={
                                                                primaryOrder.desc
                                                                    ? 0
                                                                    : 180
                                                            }
                                                            path={mdiChevronDown}
                                                        ></Icon>
                                                    )}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            onClick={() =>
                                                sort(SortColumns.Channel)
                                            }
                                            className="cursor-pointer"
                                        >
                                            <div className="flex">
                                                Canal
                                                {primaryOrder?.column ===
                                                    SortColumns.Channel && (
                                                        <Icon
                                                            className="transition-all w-5"
                                                            rotate={
                                                                primaryOrder.desc
                                                                    ? 0
                                                                    : 180
                                                            }
                                                            path={mdiChevronDown}
                                                        ></Icon>
                                                    )}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className="cursor-pointer"
                                            align="right"
                                            onClick={() =>
                                                sort(SortColumns.Sensor)
                                            }
                                        >
                                            <div className="flex">
                                                Sensor
                                                {primaryOrder?.column ===
                                                    SortColumns.Sensor && (
                                                        <Icon
                                                            className="transition-all w-5"
                                                            rotate={
                                                                primaryOrder.desc
                                                                    ? 0
                                                                    : 180
                                                            }
                                                            path={mdiChevronDown}
                                                        ></Icon>
                                                    )}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            onClick={() =>
                                                sort(SortColumns.Status)
                                            }
                                            className="cursor-pointer"
                                        >
                                            <div className="flex">
                                                Status
                                                {primaryOrder?.column ===
                                                    SortColumns.Status && (
                                                        <Icon
                                                            className="transition-all w-5"
                                                            rotate={
                                                                primaryOrder.desc
                                                                    ? 0
                                                                    : 180
                                                            }
                                                            path={mdiChevronDown}
                                                        ></Icon>
                                                    )}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className="cursor-pointer"
                                            align="right"
                                            onClick={() =>
                                                sort(SortColumns.Value)
                                            }
                                        >
                                            <div className="flex">
                                                Valor
                                                {primaryOrder?.column ===
                                                    SortColumns.Value && (
                                                        <Icon
                                                            className="transition-all w-5"
                                                            rotate={
                                                                primaryOrder.desc
                                                                    ? 0
                                                                    : 180
                                                            }
                                                            path={mdiChevronDown}
                                                        ></Icon>
                                                    )}
                                            </div>
                                        </TableCell>
                                        <TableCell align="right">
                                            Acciones
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedAlerts
                                        .filter(filterAlerts)
                                        .map((row) => {
                                            const channel = channels.find(
                                                (c) => c.id === row.channel
                                            )
                                            const sensor = sensors.find(
                                                (s) => s.id === row.sensor
                                            )
                                            return (
                                                <TableRow
                                                    key={row.id}
                                                    style={{
                                                        backgroundColor:
                                                            row.condition.color,
                                                    }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {mapPriorities(row)}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {moment(row.update)
                                                            .tz(timeZone)
                                                            .format(
                                                                "DD-MM-YYYY HH:mm"
                                                            )}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.condition.name}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {channel?.label ??
                                                            channel?.field_name_origin ??
                                                            ""}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {sensor?.label ?? ""}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.status}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {getValue(row)}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.status !==
                                                            "resolved" && (
                                                                <Button
                                                                    onClick={withError(() =>
                                                                        resolveAlert(
                                                                            row,
                                                                            "comment"
                                                                        ))
                                                                    }
                                                                    color="primary"
                                                                >
                                                                    Resolver
                                                                </Button>
                                                            )}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AlertsStatus
