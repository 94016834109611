import { applyMiddleware, createStore } from 'redux'
import rootReducer from './reducers/RootReducer'
import thunk from "redux-thunk"
import { composeWithDevTools } from 'redux-devtools-extension'

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export const newStore = () => createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store