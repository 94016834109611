import { Reducer } from "react";

interface State {
    isLoading: boolean,
    conditionGroup: ConditionGroup
    conditionGroups: ConditionGroup[]
    loadingConditionGroups: boolean
    selectedConditionGroup: ConditionGroup
}

const initialState: State = {
    isLoading: false,
    conditionGroup: null,
    conditionGroups: [],
    loadingConditionGroups: false,
    selectedConditionGroup: null
}

export enum ConditionGroupActions {
    RequestConditionGroup = 'REQUEST_CONDITION_GROUP',
    RequestConditionGroups = 'REQUEST_CONDITION_GROUPS',
    SetConditionGroup = 'SET_CONDITION_GROUP',
    SetSelectedConditionGroup = 'SET_SELECTED_CONDITION_GROUP',
    SetConditionGroups = 'SET_CONDITION_GROUPS',
}

const reducer: Reducer<State, { type: ConditionGroupActions, payload: any }> = (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case ConditionGroupActions.RequestConditionGroup:
            return { ...state, isLoading: true }
        case ConditionGroupActions.SetConditionGroup:
            return { ...state, isLoading: false, conditionGroup: payload }
        case ConditionGroupActions.SetSelectedConditionGroup:
            return { ...state, isLoading: false, selectedConditionGroup: payload }
        case ConditionGroupActions.RequestConditionGroups:
            return { ...state, loadingConditionGroups: true }
        case ConditionGroupActions.SetConditionGroups:
            return { ...state, loadingConditionGroups: false, conditionGroups: payload }
        default:
            return state;
    }
}

export default reducer