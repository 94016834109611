import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	LinearProgress,
} from '@material-ui/core'
import { useState } from 'react'
import { exportAllChannelsToExcel } from '../../services/sensor'
import { withError } from '../../services/snackbarNotification';

interface Props {
	sensor: Sensor
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
}

const DownloadModal: React.FC<Props> = ({ sensor, open, onClose }) => {
	const [running, setRunning] = useState(false);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
			<DialogContent className='p-2'>
				<div className='w-full flex'>
					Esta acción puede demorar varios minutos.
					Desea continuar?
				</div>
				<div style={{ visibility: running ? "visible" : "hidden" }}>
					<LinearProgress />
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					color='primary'
					className='m-2'
					variant='contained'
					onClick={withError(async () => {
						setRunning(true)
						const val = await exportAllChannelsToExcel(sensor)
						if (true) {
							setRunning(false)
							onClose()
						}
					})}
				>
					Descargar
				</Button>
				<Button
					color='secondary'
					className='m-2'
					variant='contained'
					onClick={async () => {
						onClose()
					}}
				>
					Cancelar
				</Button>
			</DialogActions>
		</Dialog >
	)
}
export default DownloadModal