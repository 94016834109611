import store from '../store'
import { ProjectUnitActions } from '../reducers/ProjectUnitsReducer'
import axios from 'axios';
import { authHeader } from './auth'
import { getSensorsByProjectUnit, removeSensorsByProjectUnit } from './sensor';

const API_URL = process.env.REACT_APP_API_URL

export const getProjectUnits = async () => {
    store.dispatch({ type: ProjectUnitActions.RequestProjectUnits, payload: {} })
    const response = await axios
        .get(API_URL + process.env.REACT_APP_PATH_PROJECT_UNITS,
            { headers: authHeader() }
        );
    store.dispatch({ type: ProjectUnitActions.SetProjectUnits, payload: response.data })
}

export const selectProjectUnit = async (projectUnit: ProjectUnit) => {
    store.dispatch({ type: ProjectUnitActions.AddSelectedProjectUnit, payload: projectUnit })
    getSensorsByProjectUnit(projectUnit)
};

export const removeProjectUnit = async (projectUnit: ProjectUnit) => {
    store.dispatch({ type: ProjectUnitActions.RemoveSelectedProjectUnit, payload: projectUnit })
    removeSensorsByProjectUnit(projectUnit)
};

export const clearSelectedProjectUnits = () => {
    store.dispatch({ type: ProjectUnitActions.ClearSelectedProjectUnits, payload: {} })
}