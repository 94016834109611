import { Reducer } from "react";

interface State {
    center: {
        lat: number
        lng: number
    },
    map: any
}

const initialState: State = {
    center: null,
    map: null
}

export enum MapActions {
    SetCenter = 'SET_CENTER',
}

const reducer: Reducer<State, { type: MapActions, payload: any }> = (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case MapActions.SetCenter:
            return { ...state, center: payload }

        default:
            return state;
    }
}

export default reducer