import { Button, Tab, Tabs } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Permissions } from '../../config/Permissions'
import { Routes } from '../../config/Routes'
import { useAppSelector } from '../../hooks/storeHooks'
import { getChannels } from '../../services/channel'
import { Redirect } from 'react-router-dom'
import {
	getConditionGroups,
	selectConditionGroup,
} from '../../services/conditionGroup'
import useStyles from '../../styles/appStyles'
import ProjectListCard from '../AnalysisContainer/ProjectUnitsListCard/ProjectUnitListCard'
import AddConditionToChannellGroupModal from './ConditionGroupTable/AddConditionToChannellGroupModal'
import ConditionGroupFormModal from './ConditionGroupFormModal/ConditionGroupFormModal'
import ConditionGroupHistory from './ConditionGroupHistory/ConditionGroupHistory'
import ConditionGroupList from './ConditionGroupList'
import ConditionGroupTable from './ConditionGroupTable/ConditionGroupTable'
import { getActionModes } from '../../services/actionMode'
import Icon from '@mdi/react'
import {
	mdiAlphaGCircleOutline,
	mdiEmailAlertOutline,
	mdiHistory,
} from '@mdi/js'
import { mainColor } from '../../styles/styleVars'
import { getUsers, hasPermission } from '../../services/auth'
import EmailDestinatariesTable from './EmailDestinatariesTable/EmailDestinatariesTable'
import SmsDestinatariesTable from './SmsDestinatariesTable/SmsDestinatariesTable'
import ChannelConditionGroupList from './ChannelConditionGroupList'

const AlertsContainer: React.FC = () => {
	const classes = useStyles(true)()
	const channel = useAppSelector((s) => s.channelReducer.selectedChannel)
	const conditionGroup = useAppSelector(
		(s) => s.conditionGroupReducer.selectedConditionGroup
	)
	const permissions = useAppSelector((s) => s.authReducer.permissions)
	const [tab, setTab] = useState(0)
	const [conditionGroupModalOpen, setConditionGroupModalOpen] =
		useState(false)
	useEffect(() => {
		getConditionGroups()
		getActionModes()
		getUsers()
		return () => {
			selectConditionGroup(null)
		}
	}, [])
	const [assignConditionGroupModalOpen, setAssignConditionGroupModalOpen] =
		useState(false)

	if (!permissions.find((p) => p === Permissions.ViewAlerts))
		return <Redirect to={Routes.Monitoring}></Redirect>
	const canEdit = permissions.find((p) => p === Permissions.ModifyConditions)
	return (
		<div className={`flex bg-gray-100 ${classes.bodyContent}`}>
			<div className='w-1/6  p-2 overflow-auto overflow-x-hidden'>
				<div className='text-main-1 flex-1 text-lg '>
					<Tabs
						centered
						onChange={(_, n) => setTab(n)}
						value={tab}
						indicatorColor='primary'
						textColor='primary'
						variant='standard'
					>
						<Tab
							icon={
								<Icon
									path={mdiHistory}
									className='w-7 h-7'
									color={mainColor}
								></Icon>
							}
							style={{
								minWidth: 50,
								paddingLeft: 0,
								paddingRight: 0,
								letterSpacing: '-0.4em',
							}}
							classes={classes}
						></Tab>
						<Tab
							icon={
								<Icon
									path={mdiAlphaGCircleOutline}
									className='w-7 h-7'
									color={mainColor}
								></Icon>
							}
							style={{
								minWidth: 50,
								paddingLeft: 0,
								paddingRight: 0,
								letterSpacing: '-0.4em',
							}}
							classes={classes}
						></Tab>
						<Tab
							icon={
								<Icon
									path={mdiEmailAlertOutline}
									className='w-7 h-7'
									color={mainColor}
								></Icon>
							}
							style={{
								minWidth: 50,
								paddingLeft: 0,
								paddingRight: 0,
								letterSpacing: '-0.4em',
							}}
							classes={classes}
						></Tab>
					</Tabs>
				</div>
				{tab === 0 && <ProjectListCard />}
				{tab === 1 && <ConditionGroupList />}
			</div>
			<div className='w-5/6  bg-gray-50 overflow-auto'>
				{tab === 0 && channel && (
					<>
						<div className='h-full p-4  overflow-y-auto '>
							<div className='text-center text-lg text-gray-600 mb-2'>
								{`Grupos de condición del canal ${channel?.label}`}
								{canEdit && (
									<div className='flex float-right gap-x-2 '>
										<Button
											color='primary'
											variant='contained'
											className='mr-2'
											onClick={() =>
												setConditionGroupModalOpen(true)
											}
										>
											Nuevo Grupo
										</Button>
										<Button
											color='primary'
											variant='contained'
											className='ml-2'
											onClick={() =>
												setAssignConditionGroupModalOpen(
													true
												)
											}
										>
											Asignar Grupo
										</Button>
									</div>
								)}

							</div>
							<div className='overflow-auto p-4'>
								<ConditionGroupHistory
									channel={channel}
								></ConditionGroupHistory>
								<ChannelConditionGroupList
									channel={channel}
								>

								</ChannelConditionGroupList>
							</div>
						</div>
					</>
				)}
				{tab === 1 && (
					<>
						<div className='p-2 h-full flex flex-col'>
							<div className='flex justify-end p-4'>
								<Button
									color='primary'
									variant='contained'
									onClick={() =>
										setConditionGroupModalOpen(true)
									}
								>
									Nuevo grupo
								</Button>
							</div>
							{conditionGroup ? (
								<ConditionGroupTable
									conditionGroupId={conditionGroup.id}
								></ConditionGroupTable>
							) : (
								<div className='text-center font-medium text-gray-600'>
									Seleccione un grupo de condiciones
								</div>
							)}
						</div>
					</>
				)}
				{tab === 2 && (
					<div className='h-full flex flex-col'>
						{hasPermission(Permissions.ViewEmailDestinataries) && (
							<div className='flex-1 resize-y overflow-auto'>
								<EmailDestinatariesTable></EmailDestinatariesTable>
							</div>
						)}
						{hasPermission(Permissions.ViewSmsDestinataries) && (
							<div className='flex-1 resize-y overflow-auto'>
								<SmsDestinatariesTable></SmsDestinatariesTable>
							</div>
						)}
					</div>
				)}
			</div>
			<ConditionGroupFormModal
				open={conditionGroupModalOpen}
				onClose={() => {
					setConditionGroupModalOpen(false)
					getChannels()
				}}
			></ConditionGroupFormModal>
			<AddConditionToChannellGroupModal
				open={assignConditionGroupModalOpen}
				onClose={() => {
					setAssignConditionGroupModalOpen(false)
					getChannels()
				}}
			></AddConditionToChannellGroupModal>
		</div>
	)
}
export default AlertsContainer
