import { TextField } from "@material-ui/core"
import { mdiCheck, mdiPencil, mdiSync } from "@mdi/js"
import Icon from "@mdi/react"
import { PropsWithChildren, useState } from "react"
import { Permissions } from "../../config/Permissions"
import { useAppSelector } from "../../hooks/storeHooks"
import { useForm } from "../../hooks/useForm"
import { addSnackbarNotification } from "../../services/snackbarNotification"

interface Props<T> {
    onSubmit: (value: T) => Promise<void>
    value: T
    className?: string
    labelClassName?: string
    id: string
    label: string
    notificationMessage?: string
    permissions?: Permissions[]
    fullwidth?: boolean
}

const EditableLabel: <T = string>(
    p: PropsWithChildren<Props<T>>
) => React.ReactElement = ({
    onSubmit,
    className,
    labelClassName,
    value,
    label,
    id,
    notificationMessage,
    permissions,
    fullwidth,
}) => {
    const isEditing = useAppSelector((s) => s.authReducer.isEditing)
    const [isOver, setIsOver] = useState(false)
    const [editing, setEditing] = useState(false)
    const [inputValue, , handleChange] = useForm({ [id]: value })
    const [loading, setLoading] = useState(false)
    const userPermissions = useAppSelector((s) => s.authReducer.permissions)

    const allowEdit =
        isEditing &&
        (permissions
            ? permissions.reduce<boolean>(
                  (acc, p) => acc && !!userPermissions.find((up) => up === p),
                  true
              )
            : true)

    return (
        <div
            className={className ?? "w-full flex items-center"}
            onMouseOver={() => !isOver && setIsOver(true)}
            onMouseLeave={() => setIsOver(false)}
        >
            {(!allowEdit || !editing) && (
                <div className={labelClassName ?? "flex items-center"}>
                    {value}
                </div>
            )}
            {allowEdit && editing && (
                <TextField
                    size="small"
                    id={id}
                    name={id}
                    value={inputValue[id]}
                    onChange={handleChange}
                    fullWidth={fullwidth ?? false}
                ></TextField>
            )}
            {allowEdit && isOver ? (
                loading ? (
                    <div className="w-7 ml-2">
                        <Icon
                            className="w-5 text-primary transition-opacity opacity-70 cursor-pointer hover:opacity-100"
                            path={mdiSync}
                            spin
                        ></Icon>
                    </div>
                ) : (
                    <div
                        className="w-7 ml-2"
                        onClick={async () => {
                            if (editing) {
                                setLoading(true)
                                await onSubmit(inputValue[id])
                                    .then(
                                        () =>
                                            notificationMessage &&
                                            addSnackbarNotification({
                                                message: notificationMessage,
                                                type: "success",
                                            })
                                    )
                                    .catch((e) =>
                                        addSnackbarNotification({
                                            message: "Ha ocurrido un error",
                                            type: "error",
                                        })
                                    )

                                setLoading(false)
                                setEditing(false)
                            } else {
                                setEditing(true)
                            }
                        }}
                    >
                        <Icon
                            className="w-5 text-primary transition-opacity opacity-70 cursor-pointer hover:opacity-100"
                            path={editing ? mdiCheck : mdiPencil}
                        ></Icon>
                    </div>
                )
            ) : (
                <div className="w-7 ml-2"></div>
            )}
        </div>
    )
}
export default EditableLabel
