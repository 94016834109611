import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core"
import { addSnackbarNotification } from "../../services/snackbarNotification"
interface Props {
    open: boolean
    onClose?: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void
    onYes: () => void
    text: string
    yesButtonText?: string
    noButtonText?: string
    yesButtonColor?: "inherit" | "primary" | "secondary" | "default"
    yesButtonVariant?: "text" | "outlined" | "contained"
    noButtonColor?: "inherit" | "primary" | "secondary" | "default"
    noButtonVariant?: "text" | "outlined" | "contained"
    snackbarNotificationText?: string
    snackbarNotificationType?: "error" | "info" | "success" | "warning"
}

const QuestionModal: React.FC<Props> = ({
    open,
    onClose,
    onYes,
    text,
    yesButtonText,
    yesButtonColor,
    yesButtonVariant,
    noButtonText,
    noButtonColor,
    noButtonVariant,
    snackbarNotificationText,
    snackbarNotificationType,
}) => {
    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose()
            }}
            maxWidth="sm"
            fullWidth
        >
            <DialogContent>{text}</DialogContent>
            <DialogActions>
                <Button
                    variant={yesButtonVariant}
                    color={yesButtonColor ?? "default"}
                    onClick={async () => {
                        await onYes()
                        snackbarNotificationText &&
                            addSnackbarNotification({
                                message: snackbarNotificationText,
                                type: snackbarNotificationType ?? "success",
                            })
                        onClose()
                    }}
                >
                    {yesButtonText ?? "Sí"}
                </Button>
                <Button
                    variant={noButtonVariant}
                    color={noButtonColor ?? "default"}
                    onClick={onClose}
                >
                    {noButtonText ?? "No"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default QuestionModal
