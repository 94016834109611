import Map from './Map/Map'

import useStyles from '../../styles/appStyles'

import { getProjectUnits } from '../../services/projectUnit'
import { useEffect } from 'react'
import ProjectUnitList from './ProjectUnitList/ProjectUnitList'
import DeltaProfileChart from './DeltaProfileChart/DeltaProfileChart'
import { clearSensors, stopSync, syncSensors } from '../../services/sensor'
import { getChannels } from '../../services/channel'
import SensorDetail from './SensorDetail'
import { getConditionGroup } from '../../services/conditionGroup'

const MonitoringContainer: React.FC = () => {
	const classes = useStyles(true)()
	useEffect(() => {
		clearSensors()
		getProjectUnits().then(syncSensors)
		getChannels()
		getConditionGroup()
		return () => {
			stopSync()
		}
	}, [])

	return (
		<div
			className={`flex ${classes.bodyContent} `}
			style={{ transform: 'translate(0,-5px)' }}
		>
			<div className='w-1/5 bg-gray-100 flex flex-col border-1'>
				<ProjectUnitList />
				<SensorDetail />
			</div>
			<div className='w-4/5 h-full'>
				<div className='h-1/2 bg-blue-500'>
					<Map></Map>
				</div>
				<div className='h-1/2'>
					<DeltaProfileChart></DeltaProfileChart>
				</div>
			</div>
		</div>
	)
}
export default MonitoringContainer
