import { default as realStore } from '../store'
import { AuthActions } from '../reducers/AuthReducer'
import { default as realAxios } from 'axios'
import { Permissions } from '../config/Permissions'


const API_URL = process.env.REACT_APP_API_URL

export const login = async (username: string, password: string, axios = realAxios, store = realStore) => {
	store.dispatch({ type: AuthActions.RequestLogin, payload: {} })
	const response = await axios.post(API_URL + 'api-token-auth/', {
		username,
		password,
	})
	const { token } = response.data
	token && localStorage.setItem('token', token)
	store.dispatch({ type: AuthActions.SetToken, payload: token })
}

export const logout = (store = realStore) => {
	store.dispatch({ type: AuthActions.Logout, payload: {} })
	localStorage.removeItem('token')
}

export const getUser = async (axios = realAxios, store = realStore) => {
	const { data } = await axios.get(
		API_URL + process.env.REACT_APP_PATH_USERS + 'me/',
		{ headers: authHeader() }
	)
	store.dispatch({ type: AuthActions.SetUser, payload: data })
}

export const getPermissions = async (axios = realAxios, store = realStore) => {
	const permissionsResponse = await axios.get(
		API_URL + 'frontend_permissions/current/',
		{ headers: authHeader() }
	)
	store.dispatch({
		type: AuthActions.SetPermissions,
		payload: permissionsResponse.data,
	})
}
export const getUsers = async (axios = realAxios, store = realStore) => {
	const { data } = await axios.get(
		API_URL + process.env.REACT_APP_PATH_USERS,
		{ headers: authHeader() }
	)
	store.dispatch({ type: AuthActions.SetUsers, payload: data })
}

export const setIsAdmin = (isAdmin: boolean, store = realStore) => {
	store.dispatch({ type: AuthActions.SetIsAdmin, payload: isAdmin })
}

export const authHeader = (store = realStore) => {
	const token = store.getState().authReducer.token
	return token ? { Authorization: 'token ' + token } : {}
}

export const hasPermission = (permission: Permissions, store = realStore) =>
	store.getState().authReducer.permissions.includes(permission)

export const getUserById = (id: number, store = realStore) =>
	store.getState().authReducer.users.find((u) => u.id === id)

export const passwordResetRequest = async (email: string, axios = realAxios) => {
	const response = await axios.post(API_URL + 'password_reset/', { email })
	return response.data
}

export const passwordResetConfirm = async (
	email: string,
	token: string,
	new_password: string,
	axios = realAxios
) => {
	const response = await axios.post(API_URL + 'password_reset/confirm/', {
		email,
		token,
		new_password,
	})
	return response.data
}
