import useStyles from '../../styles/appStyles'
import { useAppSelector } from '../../hooks/storeHooks'
import ProjectListCard from './ProjectUnitsListCard/ProjectUnitListCard'
import { useEffect } from 'react'
import { getConditionGroup } from '../../services/conditionGroup'
import {
	getProjectUnits,
	clearSelectedProjectUnits,
} from '../../services/projectUnit'
import { stopSync, syncSensors } from '../../services/sensor'
import SingleChannelSignalChart from './SingleChannelSignalChart/SingleChannelSignalChart'
import { Permissions } from '../../config/Permissions'
import { Redirect } from 'react-router-dom'
import { Routes } from '../../config/Routes'

const AnalysisContainer: React.FC = () => {
	const selectedChannel = useAppSelector(
		(s) => !!s.channelReducer.selectedChannel
	)
	const permissions = useAppSelector((s) => s.authReducer.permissions)
	const classes = useStyles(true)()
	useEffect(() => {
		getProjectUnits()
		clearSelectedProjectUnits()
		syncSensors()
		getConditionGroup()
		return () => {
			stopSync()
		}
	}, [])

	if (!permissions.find((p) => p === Permissions.ViewAnalisys))
		return <Redirect to={Routes.Monitoring}></Redirect>

	return (
		<div className={`flex ${classes.bodyContent} `}>
			<div className='w-1/5 bg-gray-100 p-2 flex flex-col'>
				<ProjectListCard></ProjectListCard>
			</div>

			<div className='w-4/5 bg-gray-100 p-2 flex flex-col'>
				{selectedChannel && (
					<SingleChannelSignalChart></SingleChannelSignalChart>
				)}
			</div>
		</div>
	)
}
export default AnalysisContainer
