import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core'
import { useAppSelector } from '../../hooks/storeHooks'
import { useForm } from '../../hooks/useForm'
import { getDataloggers } from '../../services/datalogger'
import { createSensor, fetchSensors } from '../../services/sensor'
import { withError } from '../../services/snackbarNotification'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
}

const SensorFormModal: React.FC<Props> = ({ open, onClose }) => {
	const dataloggers = useAppSelector((s) => s.dataloggerReducer.dataloggers)
	const [values, , handleChange] = useForm({
		mesa: '',
		serial: '',
		norte: '',
		este: '',
		dataloggerSerialNumber: '',
	})

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
			<DialogContent className='p-2'>
				<div className='w-full flex'>
					<div className='w-1/3 p-2'>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Mesa
						</div>
						<TextField
							id='mesa'
							fullWidth
							color='primary'
							label='Mesa'
							size='medium'
							value={values.mesa}
							onChange={handleChange}
						/>
					</div>
					<div className='w-1/3 p-2'>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Gateway
						</div>
						<Select
							labelId="datalogger-select-label"
							id="datalogger-select"
							value={values.dataloggerSerialNumber}
							name='dataloggerSerialNumber'
							onChange={handleChange}
						>
							{dataloggers.length && dataloggers.map((datalogger) =>
								<MenuItem value={datalogger.installation.equipment.serial_number}>{datalogger.installation.equipment.serial_number}</MenuItem>

							)}
						</Select>

					</div>
					<div className='w-1/3 p-2'>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Serial
						</div>
						<Select
							labelId="datalogger-select-label"
							id="datalogger-select"
							value={values.serial}
							name='serial'
							onChange={handleChange}
						>
							{values.dataloggerSerialNumber && dataloggers.find((datalogger) => datalogger.installation.equipment.serial_number === values.dataloggerSerialNumber)?.available_sensors?.map((sensor) =>
								<MenuItem value={sensor}>{sensor}</MenuItem>
							)}
						</Select>
					</div>
				</div>
				<div>Coordenadas</div>
				<div className='flex w-full'>
					<div className='w-1/2 p-2'>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Norte
						</div>
						<TextField
							id='norte'
							fullWidth
							color='primary'
							label='Norte'
							size='medium'
							value={values.norte}
							onChange={handleChange}
						/>
					</div>
					<div className='w-1/2 p-2'>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Este
						</div>
						<TextField
							id='este'
							fullWidth
							color='primary'
							label='Este'
							size='medium'
							value={values.este}
							onChange={handleChange}
						/>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					color='primary'
					className='m-2'
					variant='contained'
					onClick={withError(async () => {
						const val = await createSensor(values)
						if (val) {
							getDataloggers()
							fetchSensors()
							onClose()
						}
					})}
				>
					Guardar
				</Button>
			</DialogActions>
		</Dialog>
	)
}
export default SensorFormModal
