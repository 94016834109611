import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from '@material-ui/core'
import { mdiChevronDown } from '@mdi/js'
import Icon from '@mdi/react'
import { useState } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import { getSensorsByProjectUnit } from '../../../services/sensor'
import MultiSelectSensorExpandable from './MultiSelectSensorExpandable'

interface Props {
	projectUnit: ProjectUnit
}

const MultiSelectProjectUnitExpandable: React.FC<Props> = ({ projectUnit }) => {
	const [expanded, setExpanded] = useState(false)
	const sensors = useAppSelector(
		(s) => s.sensorReducer.sensorsByProjectUnit[projectUnit.id]
	)

	return (
		<div className='p-1'>
			<Accordion
				elevation={0}
				expanded={expanded}
				onChange={(_, isExpanded) => {
					isExpanded && getSensorsByProjectUnit(projectUnit)
					setExpanded(isExpanded)
				}}
			>
				<AccordionSummary
					expandIcon={
						<Icon
							path={mdiChevronDown}
							className='w-6'
							color='#BBB'
						></Icon>
					}
				>
					<div className='flex'>
						<div className='font-medium text-gray-500'>
							{projectUnit.name}
						</div>
					</div>
				</AccordionSummary>
				<AccordionDetails>
					<div className='w-full'>
						{!!sensors?.length &&
							sensors
								.sort((a, b) => {
									const splittedA = a.label.split('-')
									const splittedB = b.label.split('-')
									return (
										parseInt(
											splittedA[splittedA.length - 1]
										) -
										parseInt(
											splittedB[splittedB.length - 1]
										)
									)
								})
								.map((s) => (
									<MultiSelectSensorExpandable
										key={s.id}
										sensor={s}
									/>
								))}
					</div>
				</AccordionDetails>
			</Accordion>
		</div>
	)
}
export default MultiSelectProjectUnitExpandable
