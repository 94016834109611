export enum Permissions {
	ViewAnalisys = 'view_analisys',
	ViewInventory = 'view_inventory',
	ViewAlerts = 'view_alerts',
	EditMode = 'edit_mode',
	ModifyConditions = 'modify_conditions',
	ModifyEquipments = 'modify_equipments',
	ViewEmailDestinataries = 'view_email_destinataries',
	ModifyEmailDestinataries = 'modify_email_destinataries',
	ViewSmsDestinataries = 'view_sms_destinataries',
	ModifySmsDestinataries = 'modify_sms_destinataries',
	SelectSingleChannelPoints = 'select_single_channel_points',
	ViewMultiChannel = 'view_multi_channel',
	ViewFileRepository = 'view_file_repository',
}
