import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    TextField,
} from "@material-ui/core"
import { useForm } from "../../../hooks/useForm"
import {
    createEmailDestinatary,
    getEmailDestinataries,
} from "../../../services/destinatary"
import { addSnackbarNotification, withError } from "../../../services/snackbarNotification"

interface Props {
    open: boolean
    onClose?: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void
}

const EmailDestinataryFormModal: React.FC<Props> = ({ open, onClose }) => {
    const [values, , handleChange] = useForm<Partial<EmailDestinatary>>({
        address: "",
        name: "",
    })
    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose()
            }}
            maxWidth="xs"
            fullWidth
        >
            <DialogContent>
                <div className="flex mt-2  justify-around">
                    <div>
                        <TextField
                            id="name"
                            fullWidth
                            color="primary"
                            label="Nombre"
                            size="medium"
                            value={values.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <TextField
                            id="address"
                            fullWidth
                            color="primary"
                            label="Email"
                            size="medium"
                            value={values.address}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={withError(async () => {
                        try {
                            await createEmailDestinatary(values)
                            addSnackbarNotification({
                                message: "Destinatario añadido",
                            })
                            getEmailDestinataries()
                            onClose()
                        } catch (error) {
                            addSnackbarNotification({
                                message:
                                    error?.message ?? "Ha ocurrido un error",
                                type: "error",
                            })
                        }
                    })}
                    variant="contained"
                >
                    Agregar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default EmailDestinataryFormModal
