import axios from 'axios'
import { DestinatariesActions } from '../reducers/DestinataryReducer'
import store from '../store'
import { authHeader } from './auth'

const API_URL = process.env.REACT_APP_API_URL

const EMAIL_DESTINATARY_URL = process.env.REACT_APP_PATH_DESTINATARIES_EMAIL

const SMS_DESTINATARY_URL = process.env.REACT_APP_PATH_DESTINATARIES_SMS



export const getEmailDestinataries = async () => {
    store.dispatch({ type: DestinatariesActions.RequestEmailDestinataries, payload: {} })
    const response = await axios
        .get(API_URL + EMAIL_DESTINATARY_URL,
            { headers: authHeader() }
        );
    store.dispatch({ type: DestinatariesActions.SetEmailDestinataries, payload: response.data })
}

export const getSmsDestinataries = async () => {
    store.dispatch({ type: DestinatariesActions.RequestSmsDestinataries, payload: {} })
    const response = await axios
        .get(API_URL + SMS_DESTINATARY_URL,
            { headers: authHeader() }
        );
    store.dispatch({ type: DestinatariesActions.SetSmsDestinataries, payload: response.data })
}

export const deleteEmailDestinatary = async (destinatary: EmailDestinatary) =>
    axios.delete(API_URL + EMAIL_DESTINATARY_URL + `${destinatary.id}/`,
        { headers: authHeader() }
    )
export const createEmailDestinatary = async (destinatary: Partial<EmailDestinatary>) =>
    axios.post(API_URL + EMAIL_DESTINATARY_URL, destinatary,
        { headers: authHeader() }
    )
export const deleteSmsDestinatary = async (destinatary: SmsDestinatary) =>
    axios.delete(API_URL + SMS_DESTINATARY_URL + `${destinatary.id}/`,
        { headers: authHeader() }
    )
export const createSmsDestinatary = async (destinatary: Partial<SmsDestinatary>) =>
    axios.post(API_URL + SMS_DESTINATARY_URL, destinatary,
        { headers: authHeader() }
    )



export const getActionModeById = (id: number) => store.getState().actionModeReducer.actionModes.find(am => am.id === id)

