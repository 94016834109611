import { Image as ImageLayer, Tile as TileLayer } from 'ol/layer'
import { useAppSelector } from '../../../hooks/storeHooks'
import ImageWMS from 'ol/source/ImageWMS'
import SensorMarker from './SensorMarker/SensorMarker'
import OSM from 'ol/source/OSM'
import Leyend from './Leyend/Leyend'
import proj4 from 'proj4'
import { register } from 'ol/proj/proj4'
import { get as getProjection } from 'ol/proj'
import { useEffect, useRef, useState } from 'react'
import * as ol from 'ol'
import SaveMapButton from './SaveMapButton/SaveMapButton'
import { saveMapSettings } from '../../../services/setting'

proj4.defs(
	'EPSG:32719',
	'+proj=utm +zone=19 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs '
)
register(proj4)
const UTM19SProjection = getProjection('EPSG:32719')

const Map: React.FC = () => {
	const sensors = useAppSelector((s) => s.sensorReducer.sensorsByProjectUnit)
	const center = useAppSelector((s) => s.mapReducer.center)
	const conditionGroup = useAppSelector(
		(s) => s.conditionGroupReducer.conditionGroup
	)
	const mapRef = useRef<HTMLDivElement>(null)
	const [map, setMap] = useState<ol.Map | null>(null)
	const [overlays, setOverlays] = useState<ol.Overlay[]>([])
	const isEditing = useAppSelector((s) => s.authReducer.isEditing)
	const settings = useAppSelector((s) => s.settingReducer.settings)

	useEffect(() => {
		const lng = settings.length
			? settings[0].value.center[0]
			: +process.env.REACT_APP_DEFAULT_MAP_CENTER_X
		const lat = settings.length
			? settings[0].value.center[1]
			: +process.env.REACT_APP_DEFAULT_MAP_CENTER_Y
		const zoom = settings.length
			? settings[0].value.zoom
			: +process.env.REACT_APP_ZOOM_VALUE_ON_INIT
		map?.getView().setCenter([lng, lat])
		map?.getView().setZoom(zoom)
	}, [settings])

	useEffect(() => {
		map?.getView().setCenter([center.lng, center.lat])
	}, [center])

	useEffect(() => {
		let options = {
			controls: [],
			layers: [
				new TileLayer({
					source: new OSM(),
				}),
				new ImageLayer({
					// extent: [
					// 	410416.4666758208, 7502032.6567014195,
					// 	413474.4666758208, 7505449.6567014195,
					// ],
					source: new ImageWMS({
						url: process.env.REACT_APP_MAP_SERVER_URL,
						params: {
							map: process.env.REACT_APP_MAP_SERVER_MAP,
							layers: process.env.REACT_APP_MAP_SERVER_LAYER,
						},
						ratio: 1,
						serverType: 'mapserver',
					}),
				}),
			],
			view: new ol.View({
				center: [
					+process.env.REACT_APP_DEFAULT_MAP_CENTER_X,
					+process.env.REACT_APP_DEFAULT_MAP_CENTER_Y,
				],
				zoom: +process.env.REACT_APP_ZOOM_VALUE_ON_INIT,
				projection: UTM19SProjection,
				minZoom: 8,
				maxZoom: 20,
			}),
			overlays: [],
		}

		const mapObject = new ol.Map(options)
		mapObject.setTarget(mapRef.current!)
		setMap(mapObject)
		refreshSensors()

		return () => {
			mapObject.setTarget(undefined)
			setMap(null)
		}
	}, [])
	useEffect(() => {
		refreshSensors()
	}, [sensors])

	const refreshSensors = () => {
		try {
			const trash = overlays.map((o) => () => o && map?.removeOverlay(o))
			const overlaysAux = []

			Object.values(sensors).forEach((sl) =>
				sl.forEach((s) => {
					if (document.getElementById(`marker-${s.id}`)) {
						const m = new ol.Overlay({
							element: document.getElementById(`marker-${s.id}`)!,
							position:
								s.installation.localization.position
									.coordinates,
						})
						map?.addOverlay(m)
						overlaysAux.push(m)
					}
				})
			)
			setOverlays(overlaysAux)
			trash.forEach((remover) => remover())
		} catch (e) {}
	}

	const saveCurrentView = () => {
		const center = map.getView().getCenter()
		console.log(center)
		const zoom = map.getView().getZoom()
		console.log(zoom)

		saveMapSettings('default', { center: center as [number, number], zoom })
	}

	return (
		<div style={{ width: '100%', height: '100%' }} ref={mapRef}>
			<div className='hidden'>
				{Object.values(sensors)
					.reduce((acc, sl) => [...acc, ...sl], [])
					.map((s) => (
						<div key={s.id}>
							<SensorMarker
								lat={
									s.installation.localization.position
										.coordinates[1]
								}
								lng={
									s.installation.localization.position
										.coordinates[0]
								}
								key={s.id}
								sensor={s}
								id={`marker-${s.id}`}
							></SensorMarker>
						</div>
					))}
			</div>
			{!!conditionGroup && <Leyend conditionGroup={conditionGroup} />}
			{isEditing && (
				<SaveMapButton
					label={'Guardar'}
					saveCurrentView={saveCurrentView}
				/>
			)}
		</div>
	)
}
export default Map
