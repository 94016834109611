import { chartBarColor } from "../../../styles/styleVars"

const COLOR_BAR_HEIGHT = 5

interface Props {
    height: number
    width: number
    x: number
    y: number
    color?: string
    label: string
    inverted: boolean
    value: number
    highlight: boolean
}

const Bar: React.FC<Props> = ({
    x,
    y,
    height,
    width,
    color,
    label,
    inverted,
    value,
    highlight,
}) => {
    return (
        <g width={width} height={height}>
            {highlight && (
                <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    strokeWidth={4}
                    stroke={"#777"}
                ></rect>
            )}
            <rect
                x={x}
                y={y + (inverted ? height - COLOR_BAR_HEIGHT : 0)}
                height={COLOR_BAR_HEIGHT}
                width={width}
                fill={color ?? chartBarColor}
            />
            <rect
                x={x}
                y={y + (inverted ? 0 : COLOR_BAR_HEIGHT)}
                height={height - COLOR_BAR_HEIGHT}
                width={width}
                fill={chartBarColor}
            >
                <title> {value} </title>
            </rect>

            {/* <text
                className="text-xs"
                x={x + width / 2}
                y={y + height + LABEL_OFFSET}
                textAnchor="end"
                transform={`rotate(-45,${x + width / 2} ${
                    y + height + LABEL_OFFSET
                })`}
            >
                {label}
            </text> */}
        </g>
    )
}
export default Bar
