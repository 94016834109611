import { Reducer } from "react";
import { Permissions } from './../config/Permissions'

interface State {
    user: User,
    users: User[]
    token: any,
    isLoading: boolean,
    isEditing: boolean,
    permissions: Permissions[]
}

const initialState: State = {
    user: null,
    token: null,
    isLoading: false,
    isEditing: false,
    permissions: [],
    users: []
}

export enum AuthActions {
    RequestLogin = 'REQUEST_LOGIN',
    SetUser = 'SET_USER',
    SetUsers = 'SET_USERS',
    SetToken = 'SET_TOKEN',
    Logout = 'LOGOUT',
    SetIsAdmin = 'SET_IS_ADMIN',
    SetPermissions = 'SET_PERMISSIONS'
}

const reducer: Reducer<State, { type: AuthActions, payload: any }> = (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case AuthActions.RequestLogin:
            return { ...state, isLoading: true }
        case AuthActions.SetToken:
            return { ...state, isLoading: false, token: payload }
        case AuthActions.SetUser:
            return { ...state, user: payload }
        case AuthActions.SetUsers:
            return { ...state, users: payload }
        case AuthActions.Logout:
            return { ...state, token: null }
        case AuthActions.SetIsAdmin:
            return { ...state, isEditing: payload }
        case AuthActions.SetPermissions:
            return { ...state, permissions: payload }
        default:
            return state;
    }
}

export default reducer