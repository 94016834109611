import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
} from '@material-ui/core'
import { useEffect } from 'react'
import { useForm } from '../../hooks/useForm'
import { createRepositoryElement, fetchRepositoryElements, fetchRootNodes } from '../../services/repositoryElement'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
	parent: RepositoryElement
}

const NewFolderModal: React.FC<Props> = ({ open, onClose, parent }) => {
	const [values, setValues, handleChange] = useForm({
		name: '',
		parent: {name: parent.name, id: parent.id, full_path: parent.full_path },
		allow_upload: true,
	})

	useEffect(() => {
		setValues({...values, parent: {name: parent.name, id: parent.id, full_path: parent.full_path} })
	}, [parent]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
			<DialogContent className='p-2'>
				<div className='w-full'>
					<div className='w-full text-lg'>
						Crear Nueva Carpeta en {values.parent.full_path +'/'+ values.parent.name + '/'}
						</div>
					<div className='w-full'>
						<TextField
							id='name'
							fullWidth
							color='primary'
							label='Nombre'
							size='medium'
							value={values.name}
							onChange={handleChange}
						/>
						Permitir subidas
						<Checkbox
											color="primary"
											checked={values.allow_upload}
											onChange={()=> {
												setValues({...values, allow_upload: !values.allow_upload})
											}}
											size='medium'
											disabled={false}
											
										/>

						</div>
						<div className='w-full'>
						<TextField
							id='parent'
							fullWidth
							color='primary'
							label='Ruta'
							size='medium'
							value={values.parent.full_path +'/'+ values.parent.name}
							onChange={handleChange}
							disabled={true}
						/>
						{/* <TextField
							id='id'
							fullWidth
							color='primary'
							label='Id'
							size='medium'
							value={values.parent.id}
							onChange={handleChange}
							disabled={true}
						/> */}
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					color='primary'
					className='m-2'
					variant='contained'
					onClick={async () => {
						const val = await createRepositoryElement({name: values.name, parent: values.parent.id, allow_upload: values.allow_upload, element_type: "d"})
						if (val) {
							fetchRepositoryElements()
							fetchRootNodes()
							onClose()
						}
					}}
				>
					Guardar
				</Button>
			</DialogActions>
		</Dialog>
	)
}
export default NewFolderModal
