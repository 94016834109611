import { Reducer } from "react";

interface State {
    isLoading: boolean,
    equipments: Equipment[],
}

const initialState: State = {
    isLoading: false,
    equipments: [],
}

export enum EquipmentActions {
    RequestEquipments = 'REQUEST_EQUIPMENTS',
    SetEquipments = 'SET_EQUIPMENTS',
}

const reducer: Reducer<State, { type: EquipmentActions, payload: any }> = (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case EquipmentActions.RequestEquipments:
            return { ...state, isLoading: true }
        case EquipmentActions.SetEquipments:
            return { ...state, isLoading: false, equipments: payload }
        default:
            return state;
    }
}

export default reducer