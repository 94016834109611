import { Reducer } from "react"

interface State {
    smsDestinataries: SmsDestinatary[]
    emailDestinataries: EmailDestinatary[]
    smsDestinatariesLoading: boolean
    emailDestinatariesLoading: boolean
}

const initialState: State = {
    smsDestinataries: [],
    emailDestinataries: [],
    smsDestinatariesLoading: false,
    emailDestinatariesLoading: false
}

export enum DestinatariesActions {
    RequestSmsDestinataries = 'REQUEST_SMS_DESTINATARIES',
    RequestEmailDestinataries = 'REQUEST_EMAIL_DESTINATARIES',
    SetSmsDestinataries = 'SET_SMS_DESTINATARIES',
    SetEmailDestinataries = 'SET_EMAIL_DESTINATARIES'
}

const reducer: Reducer<State, { type: DestinatariesActions, payload: any }> = (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case DestinatariesActions.RequestEmailDestinataries:
            return { ...state, emailDestinatariesLoading: true }
        case DestinatariesActions.RequestSmsDestinataries:
            return { ...state, smsDestinatariesLoading: true }
        case DestinatariesActions.SetEmailDestinataries:
            return { ...state, emailDestinataries: payload, emailDestinatariesLoading: false }
        case DestinatariesActions.SetSmsDestinataries:
            return { ...state, smsDestinataries: payload, smsDestinatariesLoading: false }
        default:
            return state;
    }
}

export default reducer