import {
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Divider,
    DialogActions,
    Button,
} from "@material-ui/core"
import { useEffect, useState } from "react"
import { useForm } from "../../../hooks/useForm"
import ColorPicker from "material-ui-color-picker"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { useSize } from "../../../hooks/useSize"
import * as d3 from "d3"
import {
    createConditionGroup,
    getConditionGroups,
} from "../../../services/conditionGroup"
import { addSnackbarNotification, withError } from "../../../services/snackbarNotification"
import { useAppSelector } from "../../../hooks/storeHooks"
import { getChannels } from "../../../services/channel"

const initialConditionValues = {
    value_1: "",
    value_2: "",
    name: "",
    condition_type: "btw",
    color: "#FFF",
    description: "",
    priority: 1,
}
interface Props {
    open: boolean
    onClose?: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void
}

const ConditionGroupFormModal: React.FC<Props> = ({ open, onClose }) => {
    const [conditions, setConditions] = useState<Partial<Condition>[]>([])
    const [conditionValues, setConditionValues, handleConditionChange] =
        useForm<Partial<Condition>>(initialConditionValues)
    const [values, setValues, handleChange] = useForm({ name: "" })
    const [rangeContainer, width, height, updateSize] =
        useSize<HTMLDivElement>()
    const [parsedConditions, setParsedRanges] = useState([])
    const selectedChannel = useAppSelector(
        (s) => s.channelReducer.selectedChannel
    )
    useEffect(() => {
        open && updateSize()
    }, [open, updateSize])

    useEffect(() => {
        if (conditions?.length) {
            setParsedRanges(
                conditions.map((c) => {
                    const v1 = c.value_1.includes("e")
                        ? Infinity * (c.value_1.includes("-") ? -1 : 1)
                        : +c.value_1
                    const v2 = c.value_2.includes("e")
                        ? Infinity * (c.value_2.includes("-") ? -1 : 1)
                        : +c.value_2
                    return {
                        ...c,
                        value_1: Math.min(v1, v2),
                        value_2: Math.max(v1, v2),
                    }
                })
            )
        }
    }, [conditions])

    const cleanUp = () => {
        setConditionValues(initialConditionValues)
        setConditions([])
        setParsedRanges([])
        setValues({ name: "" })
    }

    const yScale = (v: number): number => {
        const min = parsedConditions.reduce(
            (acc, v) =>
                v.value_1 === -Infinity ? acc : Math.min(acc, v.value_1),
            Infinity
        )
        const max = parsedConditions.reduce(
            (acc, v) =>
                v.value_2 === Infinity ? acc : Math.max(acc, v.value_2),
            -Infinity
        )
        return d3
            .scaleLinear()
            .domain([min - (max - min) / 3, max + (max - min) / 3])
            .range([0, width])(v)
    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                cleanUp()
                onClose()
            }}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle> Nuevo Grupo de Condiciones</DialogTitle>
            <DialogContent className="p-2 h-512">
                <div className="my-2">
                    <TextField
                        id="name"
                        fullWidth
                        color="primary"
                        label="Nombre"
                        size="medium"
                        value={values.name}
                        onChange={handleChange}
                    />
                </div>
                <div className="my-2 text-lg font-medium text-main-1">
                    {" "}
                    Condiciones
                </div>
                <div className="my-2">
                    {conditions
                        .sort((a, b) => a.value_1.localeCompare(b.value_1))
                        .map((c) => (
                            <div className="grid text-center grid-cols-4">
                                <div>{c.name}</div>
                                <div>{c.value_1}</div>
                                <div>{c.value_2}</div>
                                <div
                                    className="w-5 border  h-5 shadow rounded-full m-auto"
                                    style={{
                                        backgroundColor: c.color ?? "#FFF",
                                    }}
                                ></div>
                            </div>
                        ))}
                </div>
                <Divider></Divider>
                <div className="flex mt-6  justify-around">
                    <div>
                        <TextField
                            id="name"
                            fullWidth
                            color="primary"
                            label="Nombre"
                            size="medium"
                            value={conditionValues.name}
                            onChange={handleConditionChange}
                        />
                    </div>
                    <div>
                        <TextField
                            id="value_1"
                            fullWidth
                            color="primary"
                            label="Valor 1"
                            size="medium"
                            value={conditionValues.value_1}
                            onChange={handleConditionChange}
                        />
                    </div>
                    <div>
                        <TextField
                            id="value_2"
                            fullWidth
                            color="primary"
                            label="Valor 2"
                            size="medium"
                            value={conditionValues.value_2}
                            onChange={handleConditionChange}
                        />
                    </div>
                    <div>
                        <div className="flex items-center">
                            <div>
                                <ColorPicker
                                    name="color"
                                    id="color"
                                    value={conditionValues.color}
                                    size="medium"
                                    fullWidth
                                    label="Color"
                                    onChange={(color) =>
                                        setConditionValues((v) => ({
                                            ...v,
                                            color,
                                        }))
                                    }
                                    InputProps={{
                                        value: conditionValues.color,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div
                                className=" ml-1 w-5 h-5 rounded-full shadow"
                                style={{
                                    backgroundColor: conditionValues.color,
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-2">
                    <div
                        className="rounded-full text-main-1 cursor-pointer hover:text-opacity-75 transition-all w-7 m-auto"
                        onClick={() => {
                            setConditions((prev) => [
                                ...prev,
                                { ...conditionValues },
                            ])
                            setConditionValues(initialConditionValues)
                        }}
                    >
                        <Icon path={mdiPlus}></Icon>
                    </div>
                </div>
                <div
                    className="h-32 w-full"
                    ref={rangeContainer}
                    onClick={() =>
                        parsedConditions.forEach((c) =>
                            console.log(
                                parseInt(c.value_1),
                                parseInt(c.value_2)
                            )
                        )
                    }
                >
                    <svg width={width} height={height}>
                        {parsedConditions
                            .sort((a, b) => a.value_1 - b.value_1)
                            .map((c, i) => {
                                const x1 =
                                    c.value_1 === -Infinity
                                        ? 0
                                        : yScale(c.value_1)
                                const x2 =
                                    c.value_2 === Infinity
                                        ? width
                                        : yScale(c.value_2)
                                const conditionWidth = Math.abs(x1 - x2)
                                return (
                                    <>
                                        <rect
                                            x={x1}
                                            y={0}
                                            width={conditionWidth}
                                            height={height / 2}
                                            fill={c.color}
                                            opacity={0.5}
                                            stroke={"#555"}
                                        ></rect>
                                        <text
                                            x={x1 + conditionWidth / 2}
                                            color="white"
                                            y={height / 4}
                                            textAnchor="middle"
                                        >
                                            {c.name}
                                        </text>
                                        <text
                                            x={x1 + conditionWidth}
                                            color="#777"
                                            y={(height * 3) / 4}
                                            textAnchor={
                                                i ===
                                                    parsedConditions.length - 1
                                                    ? "end"
                                                    : "middle"
                                            }
                                        >
                                            {c.value_2}
                                        </text>
                                        <text
                                            x={x1}
                                            color="#777"
                                            y={(height * 3) / 4}
                                            textAnchor={
                                                i === 0 ? "start" : "middle"
                                            }
                                        >
                                            {c.value_1}
                                        </text>
                                    </>
                                )
                            })}
                    </svg>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={withError(async () => {
                        await createConditionGroup({
                            name: values.name,
                            conditions,
                            channels: selectedChannel
                                ? [selectedChannel.id]
                                : [],
                        }).then(() => {
                            getConditionGroups()
                            getChannels()
                        })
                        addSnackbarNotification({
                            message: "Grupo de condiciones creado",
                            type: "success",
                        })
                        cleanUp()
                        onClose()
                    })}
                >
                    Crear grupo
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ConditionGroupFormModal
