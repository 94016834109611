import { Reducer } from 'react'

interface State {
	rootNodes: RepositoryElement[]
	nodes: RepositoryElement[]
	isLoading: boolean
}

const initialState: State = {
	rootNodes: [],
	nodes: [],
	isLoading: false,
}


export enum RepositoryElementActions {
	SetRootNodes = 'SET_ROOT_NODES',
	SetRepositoryElements = 'SET_REPO_ELEMENTS',
	RequestRepositoryElements = 'REQUEST_REPO_ELEMENTS',
	RequestRepositoryElement = 'REQUEST_REPO_ELEMENT',
	AddRepositoryElement = 'ADD_REPO_ELEMENT',
	DeleteRepositoryElement = "DELETE_REPO_ELEMENT"
}

const reducer: Reducer<State, { type: RepositoryElementActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case RepositoryElementActions.RequestRepositoryElements:
			return { ...state, isLoading: true }
		case RepositoryElementActions.SetRootNodes:
			return {
				...state,
				rootNodes: payload,
			}
		case RepositoryElementActions.SetRepositoryElements:
			return {
				...state,
				isLoading: false,
				nodes: payload,
			}
		case RepositoryElementActions.AddRepositoryElement:
			const oldParent = state.nodes.find((node) => node.id !== payload.parent.id)
			const newParent = { ...oldParent, children: [...oldParent.children, payload.id] }
			console.log("oldParent")
			console.log(oldParent)
			console.log("newParent")
			console.log(newParent)
			console.log("payload")
			console.log(payload)
			return {
				...state,
				isLoading: false,
				nodes: [...state.nodes.filter((node) => node.id !== payload.id).filter((node) => node.id !== newParent.id), newParent, payload],
			}
		case RepositoryElementActions.DeleteRepositoryElement:
			return {
				...state,
				isLoading: false,
				nodes: [...state.nodes.filter((node) => node.id !== payload.id)],
			}
		default:
			return state
	}
}

export default reducer
