import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core"
import {
    mdiArrowUp,
    mdiChevronLeft,
    mdiChevronRight,
    mdiDownload,
    mdiFolder,
    mdiPencil,
    mdiTrashCan,
} from "@mdi/js"
import { Icon } from "@mdi/react"
import useStyles from "../../styles/appStyles"

const DocumentContainer: React.FC = () => {
    const classes = useStyles(true)()
    return (
        <div className={`${classes.bodyContent} p-4`}>
            <div className="flex text-gray-600 items-center">
                <div className="font-light">Documentos</div>
                <Icon
                    path={mdiChevronRight}
                    className="text-gray-600 w-5"
                ></Icon>
                <div className="ml-1 font-medium">Carpeta</div>
            </div>
            <div className="my-2">
                <div className="text-documentsBlue-1 text-lg">
                    Archivos Recientes
                </div>
            </div>
            <div className="flex flex-wrap items-stretch ">
                {[0, 0, 0, 0, 0].map(() => (
                    <div className="cursor-pointer p-2 rounded-lg my-1  bg-white w-48 mr-5">
                        <Icon
                            path={mdiFolder}
                            className="text-secondary-1 w-12 mb-3"
                        ></Icon>
                        <div className="text-documentsBlue-1 text-2xl  mb-2">
                            Carpeta
                        </div>
                        <div className="text-medium text-documentsBlue font-extralight">
                            {" "}
                            15 Archivos
                        </div>
                    </div>
                ))}

                <div className="w-1 my-4 rounded-full  bg-documentsBlue-1 mr-5">
                    {""}
                </div>
                <div className="cursor-pointer p-2 rounded-lg my-1 flex flex-col items-center justify-around bg-white w-48 mr-5 border-dashed">
                    <div className="flex flex-col h-full w-full items-center justify-around border-documentsBlue-1 border  border-dashed rounded-md">
                        <Icon
                            path={mdiArrowUp}
                            className="text-white w-16 bg-documentsBlue-1 rounded-full p-2 "
                        ></Icon>
                        <div className="text-documentsBlue-1 text-sm font-medium">
                            Subir Archivo
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-2">
                <div className="text-documentsBlue-1 text-lg">Documentos</div>
            </div>
            <div>
                <TableContainer component={Paper}>
                    <Table className="" aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Tipo</TableCell>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Tamaño</TableCell>
                                <TableCell>Modificado</TableCell>
                                <TableCell align="right">Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Icon
                                        className="w-5 text-gray-600"
                                        path={mdiFolder}
                                    ></Icon>
                                </TableCell>
                                <TableCell>Nombre</TableCell>
                                <TableCell>50 Mb</TableCell>
                                <TableCell>01/05/2021</TableCell>
                                <TableCell align="right">
                                    <div className="flex justify-end">
                                        <Icon
                                            className="w-5 text-gray-600 cursor-pointer mr-1"
                                            path={mdiTrashCan}
                                        ></Icon>
                                        <Icon
                                            className="w-5 text-gray-600 cursor-pointer mr-1"
                                            path={mdiPencil}
                                        ></Icon>
                                        <Icon
                                            className="w-5 text-gray-600 cursor-pointer"
                                            path={mdiDownload}
                                        ></Icon>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <div className="flex justify-end h-12 w-full p-2 items-center">
                        <div className="text-gray-500 mr-12">
                            Filas por Página: 6
                        </div>
                        <div className="text-gray-500 mr-5">1-5 de 15</div>
                        <Icon
                            className="w-5 text-gray-500 cursor-pointer mr-4"
                            path={mdiChevronLeft}
                        ></Icon>
                        <Icon
                            className="w-5 text-gray-500 cursor-pointer mr-1"
                            path={mdiChevronRight}
                        ></Icon>
                    </div>
                </TableContainer>
            </div>
        </div>
    )
}
export default DocumentContainer
