import realStore from '../store'
import { ChannelActions } from '../reducers/ChannelReducer'
import realAxios from 'axios';
import { authHeader } from './auth'
import { useAppSelector } from '../hooks/storeHooks';
import { addSnackbarNotification } from './snackbarNotification';

const API_URL = process.env.REACT_APP_API_URL

export const getChannels = async (axios = realAxios, store = realStore) => {
    store.dispatch({ type: ChannelActions.RequestChannels, payload: {} })
    const response = await axios
        .get(API_URL + process.env.REACT_APP_PATH_CHANNELS,
            { headers: authHeader() }
        );
    store.dispatch({ type: ChannelActions.SetChannels, payload: response.data })
    const selectedChannel = store.getState().channelReducer.selectedChannel
    if (selectedChannel) {
        store.dispatch({ type: ChannelActions.SetSelectedChannel, payload: response.data.find(c => c.id === selectedChannel.id) })
    }
}

export const setSelectedChannel = async (channel: Channel, store = realStore) => {
    store.dispatch({ type: ChannelActions.SetSelectedChannel, payload: channel })

}

export const addSelectedChannel = async (channel: Channel, store = realStore) => {
    store.dispatch({ type: ChannelActions.AddSelectedChannel, payload: channel })

}
export const removeSelectedChannel = async (channel: Channel, store = realStore) => {
    store.dispatch({ type: ChannelActions.RemoveSelectedChannel, payload: channel })

}

export const setHoverChannel = async (channel: Channel, store = realStore) => {
    store.dispatch({ type: ChannelActions.SetHoverChannel, payload: channel })

}

export const getChannelsBySensor = async (sensor: Sensor, axios = realAxios) => {
    // store.dispatch({ type: SensorActions.GetChannelsBySensor, payload: {} })
    const channelData = []
    const { data: { channels } } = await axios
        .get(API_URL + `sensors/${sensor.id}/?query={channels}`,
            { headers: authHeader() }
        ) as { data: { channels: number[] } };
    for await (const id of channels) {
        const channelResponse = await axios
            .get(API_URL + `channels/${id}/`,
                { headers: authHeader() }
            )
        channelData.push(channelResponse.data as Channel)
    }
    return channelData
}

export const getChannelInterval = async (channel: Channel, start: string, end: string, axios = realAxios) => {
    const response = await axios.get(
        process.env.REACT_APP_API_URL +
        "channels/" +
        channel.id +
        `/data/?start=${start}&end=${end}`,
        { headers: authHeader() }
    )
    return response.data
}

export const setAlertsEnabled = async (channel: Channel, enabled: boolean, axios = realAxios) => {
    await axios
        .patch(API_URL + `channels/${channel.id}/`, { alerts_enabled: enabled },
            { headers: authHeader() }
        )
    return getChannels()
}

export const useChannelById = (id: number) => {
    return useAppSelector(s => s.channelReducer.channels.find(c => c.id === id))
}

export const isChannelSelected = (channel: Channel, store = realStore) => !!store.getState().channelReducer.selectedChannels.find(c => c.id === channel.id)

export const resetVirtualDelta = async (channel: Channel, axios = realAxios) => {
    try {
        await axios
            .post(API_URL + `channels/${channel.id}/reset_v_delta/`, {},
                { headers: authHeader() }
            )
        addSnackbarNotification({ message: 'Canal virtual delta reseteado correctamente', type: 'success' })
    } catch (e) {
        addSnackbarNotification({ message: e.response.status + " " + e.response.data.message, type: 'error' })
    }
}