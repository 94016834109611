import axios from "axios"
import { useState, useEffect } from "react"
import { authHeader } from "../services/auth"

export const useFetch = <T extends Object>(
    url: string
): [
        T,
        boolean,
        () => void
    ] => {
    const [value, setValue] = useState<T>(null)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        if (url) {
            setLoading(true)
            const response = await axios.get(url, { headers: authHeader() })
            setValue(response.data)
            setLoading(false)
        }
    }

    return [value, loading, fetch]
}
