import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useAppSelector } from '../../hooks/storeHooks'
import { getChannelInterval } from '../../services/channel'
import { useSize } from '../../hooks/useSize'
import {
	secondaryColor,
	singleChannelChartLineColor,
} from '../../styles/styleVars'
import * as d3 from 'd3'
import {
	Button,
} from '@material-ui/core'
import ColorPicker from 'material-ui-color-picker'
import './styles.css'
import Icon from '@mdi/react'
import { mdiMenu } from '@mdi/js'
import Spinner from '../Shared/Spinner'
import { DateTimePicker } from '@material-ui/pickers'

const DEFAULT_DELTA = +process.env
	.REACT_APP_SINGLE_CHANNEL_CHART_DEFAULT_DELTA_IN_SECONDS as number

const Y_SCALE_WIDTH = 80

const reducedArray = (array: any[]) => {
	let aux = array
	while (aux?.length > 200) {
		aux = aux.filter(
			(_, i, arr) => i % 2 === 0 || i === 0 || i === arr.length - 1
		)
	}
	return aux
}

const MultiChannelChart: React.FC = () => {
	const [container, width, height] = useSize<HTMLDivElement>()
	const [data, setData] = useState<{
		[id: number]: {
			[field_name_origin: string]: number
			timestamp: number
		}[]
	}>({})

	const [loading, setLoading] = useState(false)
	const [dragging, setDragging] = useState(false)
	const [dragStart, setDragStart] = useState(0)
	const [mouse, setMouse] = useState({ x: 0, y: 0 })

	const timeZone = useAppSelector((s) => s.timeReducer.timeZone)
	const selectedChannels = useAppSelector(
		(s) => s.channelReducer.selectedChannels
	)

	const [timeRange, setTimeRange] = useState({
		start: moment().tz(timeZone).subtract(DEFAULT_DELTA, 'seconds'),
		end: moment().tz(timeZone),
	})

	const [channelColors, setChannelColors] = useState<{
		[id: number]: string
	}>({})

	useEffect(() => {
		getData()
	}, [selectedChannels])

	useEffect(() => {
		getData(true)
	}, [timeRange])

	const getData = async (wipe?: boolean) => {
		const start = timeRange.start.utc().format('YYYY-MM-DD HH:mm:ss')
		const end = timeRange.end.utc().format('YYYY-MM-DD HH:mm:ss')
		setLoading(true)
		const channelData = {}
		await Promise.all(
			selectedChannels.map(async (channel) => {
				!channelColors[channel.id] &&
					setChannelColors((prev) => ({
						...prev,
						[channel.id]: `rgb(${Math.floor(
							Math.random() * 256
						)},${Math.floor(Math.random() * 256)},${Math.floor(
							Math.random() * 256
						)})`,
					}))
				if (!data[channel.id] || !!wipe) {
					channelData[channel.id] = await getChannelInterval(
						channel,
						start,
						end
					)
				}
			})
		)
		setData((prev) => ({ ...prev, ...channelData }))
		setLoading(false)
		//setY && centerY(data)
	}

	const yScale = (channel: Channel) => {
		const channelData = data[channel.id]

		return d3
			.scaleLinear()
			.domain(
				channelData
					? [
							null ??
								d3.max(
									channelData,
									(d) => d[channel.field_name_origin]
								),
							null ??
								d3.min(
									channelData,
									(d) => d[channel.field_name_origin]
								),
					  ]
					: [0, 0]
			)
			.range([0, (height * 3) / 4])
	}

	const xScale = () =>
		d3
			.scaleLinear()
			.domain(
				selectedChannels.length && data[selectedChannels[0].id]
					? [
							Math.min(
								timeRange.start.unix(),
								d3.min(
									data[selectedChannels[0].id],
									(d) => d.timestamp
								)
							),

							Math.max(
								timeRange.end.unix(),
								d3.max(
									data[selectedChannels[0].id],
									(d) => d.timestamp
								)
							),
					  ]
					: [0, 0]
			)
			.range([yScaleWidth, width])

	const yScaleWidth = selectedChannels.length * Y_SCALE_WIDTH
	const chartWidth = width - yScaleWidth
	return (
		<div className='flex flex-col h-full relative'>
			{loading && (
				<div className='absolute top-1/3 right-1/2 text-center'>
					<Spinner></Spinner>
				</div>
			)}
			<div className='font-semibold uppercase text-center'>
				Canales:{' '}
				{selectedChannels.reduce<string>(
					(prev, channel, i) =>
						prev + `${i === 0 ? '' : ', '}${channel.label}`,
					''
				)}
				{/* {'Canales: ' +
							channel.label +
							', Unidad: ' +
							channel.parameter.unit} */}
			</div>
			<div className='font-normal	 text-sm text-center'>
				{`Inicio: ${timeRange.start.format('YYYY-MM-DD HH:mm:ss')},
                         Fin: ${timeRange.end.format('YYYY-MM-DD HH:mm:ss')} `}
			</div>
			<div className='h-full overflow-auto resize' ref={container}>
				<svg
					width={width}
					height={height}
					onMouseDownCapture={() => {
						setDragStart(mouse.x)
						setDragging(true)
					}}
					onMouseUpCapture={() => {
						setDragging(false)
						if (Math.abs(dragStart - mouse.x) > 10) {
							const v1 = xScale().invert(dragStart + yScaleWidth)
							const v2 = xScale().invert(mouse.x + yScaleWidth)
							setTimeRange({
								start: moment.unix(Math.min(v1, v2)),
								end: moment.unix(Math.max(v1, v2)),
							})
						}
					}}
					onMouseMoveCapture={(e) => {
						const currentTargerRect =
							e.currentTarget.getBoundingClientRect()
						setMouse({
							x: e.pageX - currentTargerRect.left - yScaleWidth,
							y: e.pageY - currentTargerRect.top,
						})
					}}
					onMouseLeave={() => setDragging(false)}
				>
					<defs>
						<linearGradient
							id='Gradient1'
							gradientTransform='rotate(90)'
						>
							<stop
								className='stop1'
								offset='0%'
								stopOpacity='.2'
								stopColor={secondaryColor}
							/>
							<stop
								className='stop3'
								offset='100%'
								stopColor={secondaryColor}
								stopOpacity='.8'
							/>
						</linearGradient>
					</defs>

					{!!selectedChannels?.length &&
						selectedChannels.map((sc, i) =>
							data[sc.id] ? (
								<>
									<polyline
										width={chartWidth}
										height={(height * 3) / 4}
										stroke={
											channelColors[sc.id] ??
											singleChannelChartLineColor
										}
										fill='none'
										strokeWidth={3}
										points={reducedArray(data[sc.id])
											.sort(
												(a, b) =>
													a.timestamp - b.timestamp
											)
											.reduce(
												(acc, value) =>
													`${acc}${xScale()(
														value.timestamp
													)},${Math.max(
														Math.min(
															yScale(sc)(
																value[
																	sc
																		.field_name_origin
																]
															),
															(height * 3) / 4
														),
														0
													)} `,
												''
											)}
									>
										width
									</polyline>
									{selectedChannels.map((sc, i) => (
										<line
											x1={(i + 1) * Y_SCALE_WIDTH + 5}
											y1={0}
											x2={(i + 1) * Y_SCALE_WIDTH + 5}
											y2={(height * 3) / 4}
											stroke={channelColors[sc.id]}
											strokeOpacity={0.1}
											strokeWidth={2}
										></line>
									))}
									{yScale(sc)
										.ticks()
										.map((tickValue) => (
											<g key={tickValue}>
												<line
													x1={yScaleWidth}
													y1={yScale(sc)(tickValue)}
													x2={width}
													y2={yScale(sc)(tickValue)}
													stroke={
														channelColors[sc.id] ??
														singleChannelChartLineColor
													}
													strokeOpacity={0.4}
												></line>
												<line
													x1={
														(i + 1) *
															Y_SCALE_WIDTH +
														5
													}
													y1={yScale(sc)(tickValue)}
													x2={
														(i + 1) *
															Y_SCALE_WIDTH +
														20
													}
													y2={yScale(sc)(tickValue)}
													stroke={
														channelColors[sc.id] ??
														singleChannelChartLineColor
													}
													strokeOpacity={0.4}
												></line>
												<text
													x={
														(i + 1) *
															Y_SCALE_WIDTH -
														20
													}
													y={(height * 3) / 4 + 20}
													className='text-xs font-light'
													stroke={
														channelColors[sc.id] ??
														singleChannelChartLineColor
													}
													strokeOpacity={0.4}
													fill='none'
													strokeWidth={0.5}
													textAnchor='end'
													transform={`rotate(-45,${
														(i + 1) *
															Y_SCALE_WIDTH -
														20
													} ${
														(height * 3) / 4 + 20
													})`}
												>
													{sc.label}
												</text>
												<text
													x={
														(i + 1) *
															Y_SCALE_WIDTH +
														2
													}
													y={yScale(sc)(tickValue)}
													textAnchor='end'
													fill={
														channelColors[sc.id] ??
														singleChannelChartLineColor
													}
												>
													{parseFloat(
														tickValue.toFixed(2)
													)}
													{' ' + sc.parameter.symbol}
												</text>
											</g>
										))}
									{!!selectedChannels.length &&
										xScale()
											.ticks()
											.map((tickValue) => (
												<g key={tickValue}>
													<line
														x1={xScale()(tickValue)}
														y1={0}
														x2={xScale()(tickValue)}
														y2={(height * 3) / 4}
														stroke='#DDD'
													></line>
													<text
														x={xScale()(tickValue)}
														y={(height * 3) / 4}
														className='text-xs'
														textAnchor='end'
														transform={`rotate(-45,${xScale()(
															tickValue
														)} ${
															(height * 3) / 4
														})`}
													>
														{moment
															.unix(tickValue)
															.tz(timeZone)
															.format(
																'DD/MM/YYYY HH:mm:ss'
															)}
													</text>
												</g>
											))}
									{dragging && (
										<rect
											x={
												Math.min(mouse.x, dragStart) +
												yScaleWidth
											}
											y={0}
											width={Math.abs(
												mouse.x - dragStart
											)}
											height={(height * 3) / 4}
											fill='url(#Gradient1)'
										></rect>
									)}
								</>
							) : (
								<></>
							)
						)}
				</svg>
			</div>
			<div className={`w-full bottom-5 absolute`}>
				<div className='flex'>
					{selectedChannels.map((sc, i) => (
						<div
							key={sc.id}
							className='w-1/4 flex items-center space-x-2'
						>
							<div
								className='w-5 h-5 rounded-full'
								style={{ background: channelColors[sc.id] }}
							></div>
							{sc.label}
							{!data[sc.id]?.length && ` (Sin datos)`}
						</div>
					))}
				</div>
			</div>
			<div className='h-10 toolsContainer transition-all w-full  absolute bottom-0'>
				<div className='m-auto w-7 p-1 bg-white rounded-t  text-gray-600 '>
					<Icon path={mdiMenu}></Icon>
				</div>
				<div className='flex h-full bg-white'>
					{!!selectedChannels.length && (
						<div className='p-1 m-1 border-gray-300 border rounded  h-full flex flex-col'>
							<div className=''>
								{selectedChannels.map((sc) => (
									<div
										className='flex items-center'
										key={sc.id}
									>
										<div>
											<ColorPicker
												name='color'
												id='color'
												value={
													channelColors[sc.id] ??
													singleChannelChartLineColor
												}
												size='medium'
												fullWidth
												label={sc.label}
												onChange={(color) =>
													setChannelColors(
														(colors) => ({
															...colors,
															[sc.id]: color,
														})
													)
												}
												InputProps={{
													value: channelColors[sc.id],
												}}
												InputLabelProps={{
													shrink: true,
												}}
												className='relative bottom-0'
											/>
										</div>
										<div
											className=' ml-1 w-5 h-5 rounded-full shadow'
											style={{
												backgroundColor:
													channelColors[sc.id],
											}}
										></div>
									</div>
								))}
							</div>
						</div>
					)}
					<div className='m-1 p-1 border-gray-300 flex border rounded'>
						<div>
							<div className='m-1 p-1'>
								<div className='p-1'>
									<DateTimePicker
										value={timeRange.start.tz(timeZone)}
										onChange={(time) => {
											setTimeRange((s) => ({
												...s,
												start: time,
											}))
											// adjustRange()
										}}
										label='Inicio'
										InputLabelProps={{
											shrink: true,
										}}
										ampm={false}
										disableFuture
									></DateTimePicker>
								</div>
								<div className='p-1'>
									<DateTimePicker
										disableFuture
										value={timeRange.end.tz(timeZone)}
										onChange={(time) => {
											setTimeRange((s) => ({
												...s,
												end: time,
											}))
											// adjustRange()
										}}
										label='Fin'
										InputLabelProps={{
											shrink: true,
										}}
										ampm={false}
									></DateTimePicker>
								</div>
							</div>
						</div>
						<div className='flex p-2 flex-col h-full gap-1 items-center'>
							<Button
								fullWidth
								variant='outlined'
								onClick={() =>
									setTimeRange({
										start: moment()
											.tz(timeZone)
											.subtract(1, 'hour'),
										end: moment().tz(timeZone),
									})
								}
							>
								Ultima Hora
							</Button>
							<Button
								fullWidth
								variant='outlined'
								onClick={() =>
									setTimeRange({
										start: moment()
											.tz(timeZone)
											.subtract(1, 'day'),
										end: moment().tz(timeZone),
									})
								}
							>
								Ultimo Día
							</Button>
							<Button
								fullWidth
								variant='outlined'
								onClick={() =>
									setTimeRange({
										start: moment()
											.tz(timeZone)
											.subtract(1, 'week'),
										end: moment().tz(timeZone),
									})
								}
							>
								Ultima Semana
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default MultiChannelChart
