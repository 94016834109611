import { mdiCheck, mdiPencil } from "@mdi/js"
import Icon from "@mdi/react"
import ColorPicker from "material-ui-color-picker"
import { PropsWithChildren, useState } from "react"
import { Permissions } from "../../config/Permissions"
import { useAppSelector } from "../../hooks/storeHooks"
import { useForm } from "../../hooks/useForm"
import { addSnackbarNotification } from "../../services/snackbarNotification"
import Spinner from "./Spinner"

interface Props {
    onSubmit: (value: string) => void
    value: string
    className?: string
    labelClassName?: string
    id: string
    label: string
    notificationMessage?: string
    permissions?: Permissions[]
}

const EditableColorLabel: (p: PropsWithChildren<Props>) => React.ReactElement =
    ({
        onSubmit,
        className,
        labelClassName,
        value,
        id,
        notificationMessage,
        permissions,
    }) => {
        const isEditing = useAppSelector((s) => s.authReducer.isEditing)
        const [isOver, setIsOver] = useState(false)
        const [editing, setEditing] = useState(false)
        const [inputValue, setInputValue] = useForm<{ [id: string]: string }>({
            [id]: value,
        })
        const [loading, setLoading] = useState(false)
        const userPermissions = useAppSelector((s) => s.authReducer.permissions)

        const allowEdit =
            isEditing &&
            (permissions
                ? permissions.reduce<boolean>(
                      (acc, p) =>
                          acc && !!userPermissions.find((up) => up === p),
                      true
                  )
                : true)

        return (
            <div
                className={className ?? "w-full flex items-center"}
                onMouseOver={() => !isOver && setIsOver(true)}
                onMouseLeave={() => setIsOver(false)}
            >
                {(!allowEdit || !editing) && (
                    <div className={labelClassName ?? "flex items-center"}>
                        <div
                            className="w-5 border  h-5 shadow rounded-full"
                            style={{
                                backgroundColor: inputValue[id] ?? "#FFF",
                            }}
                        ></div>
                    </div>
                )}
                {allowEdit && editing && (
                    <ColorPicker
                        name={id}
                        id={id}
                        value={inputValue[id]}
                        size="small"
                        label="Color"
                        onChange={(color) => setInputValue({ [id]: color })}
                        InputProps={{
                            value: inputValue[id],
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                {allowEdit && isOver ? (
                    loading ? (
                        <Spinner></Spinner>
                    ) : (
                        <div
                            className="w-7 ml-2"
                            onClick={async () => {
                                if (editing) {
                                    setLoading(true)
                                    await onSubmit(inputValue[id])
                                    notificationMessage &&
                                        addSnackbarNotification({
                                            message: notificationMessage,
                                            type: "success",
                                        })
                                    setLoading(false)
                                    setEditing(false)
                                } else {
                                    setEditing(true)
                                }
                            }}
                        >
                            <Icon
                                className="w-5 text-primary transition-opacity opacity-70 cursor-pointer hover:opacity-100"
                                path={editing ? mdiCheck : mdiPencil}
                            ></Icon>
                        </div>
                    )
                ) : (
                    <div className="w-7 ml-2"></div>
                )}
            </div>
        )
    }
export default EditableColorLabel
