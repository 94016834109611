import { mdiAlertOutline, mdiTarget } from "@mdi/js"
import Icon from "@mdi/react"
import { centerMap } from "../../../../services/map"
import { setSelectedSensor } from "../../../../services/sensor"
import { ParameterIcons } from "../../../Utilities/ParameterIcons"
import { Checkbox } from "@material-ui/core"
import { useState } from "react"
import { setAlertsEnabled } from "../../../../services/channel"
import moment from "moment-timezone"

interface Props {
    sensor: Sensor
}

const sensorLabel = (sensor: Sensor) => {
    return (
        sensor.installation.equipment.model +
        " " +
        sensor.installation.equipment.serial_number
    )
}

const SensorCardLine: React.FC<Props> = ({ sensor }) => {
    const [updating, setUpdating] = useState(false)

    return (
        <div
            key={sensor.id}
            className="cursor-pointer flex justify-between items-center"
        >
            <span className="relative inline-block mr-2">
                {sensor.is_operative ? (
                    <>
                        <Icon
                            color={"black"}
                            path={
                                ParameterIcons[
                                    sensor.default_channel?.parameter?.icon ||
                                        "default"
                                ]
                            }
                            // style={{ width: "inherit" }}
                            size={0.7}
                            className=""
                        ></Icon>
                        <span
                            className="absolute top-0 right-0 inline-block w-2 h-2 transform translate-x-1/2 -translate-y-1/2 rounded-full"
                            style={{
                                backgroundColor:
                                    sensor.default_channel?.last_condition
                                        ?.color,
                            }}
                        ></span>
                    </>
                ) : (
                    <Icon
                        color={"black"}
                        path={mdiAlertOutline}
                        size={0.7}
                        className=""
                    ></Icon>
                )}
            </span>
            {sensor.label}
            <div className="text-xs ml-1">{`hace ${moment
                .duration(
                    moment(sensor.default_channel?.last_reading_datetime).diff(
                        moment()
                    ),
                    "milliseconds"
                )
                .humanize()}`}</div>
            <div
                onClick={() => {
                    setSelectedSensor(sensor)
                    centerMap({
                        lat: sensor.installation.localization.position
                            .coordinates[1],
                        lng: sensor.installation.localization.position
                            .coordinates[0],
                    })
                }}
            >
                <Icon
                    color="ml-1 bg-grey-700 cursor-pointer"
                    path={mdiTarget}
                    size={0.8}
                ></Icon>
            </div>
            <Checkbox
                color="primary"
                checked={sensor.default_channel?.alerts_enabled}
                onChange={(e) => {
                    setUpdating(true)
                    setAlertsEnabled(
                        sensor.default_channel,
                        e.target.checked
                    ).then(() => setUpdating(false))
                }}
                disabled={updating}
                size="small"
            />
        </div>
    )
}

export default SensorCardLine
