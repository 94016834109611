import { mdiEye, mdiTarget } from "@mdi/js"
import Icon from "@mdi/react"
import { useHistory } from "react-router-dom"
import { Routes } from "../../config/Routes"
import { useAppSelector } from "../../hooks/storeHooks"
import { setSelectedChannel } from "../../services/channel"
import { centerMap } from "../../services/map"
import { ParameterIcons } from "../Utilities/ParameterIcons"
import moment from "moment-timezone"

const SensorDetail: React.FC = () => {
    const selectedSensor = useAppSelector((s) => s.sensorReducer.selectedSensor)
    const history = useHistory()
    const timeZone = useAppSelector((s) => s.timeReducer.timeZone)
    if (!selectedSensor || !selectedSensor.default_channel) return <></>

    return (
        <div className="flex h-30 p-2  bg-gray-100">
            <div
                className="w-24 h-full p-2 flex items-center"
                style={{
                    backgroundColor:
                        selectedSensor.default_channel?.last_condition?.color ||
                        "green",
                }}
            >
                <Icon
                    path={
                        ParameterIcons[
                            selectedSensor.default_channel?.parameter?.icon ||
                                "default"
                        ]
                    }
                    color="black"
                ></Icon>
            </div>
            <div
                className="h-30 text-2xs p-2 flex flex-col uppercase justify-between bg-white"
                style={{ flex: 3 }}
            >
                <div>
                    Canal de {selectedSensor.default_channel.parameter.name}
                </div>
                <div className=" flex justify-between">
                    <div>ID:</div>
                    <div>{selectedSensor.default_channel.id}</div>
                </div>
                <div className=" flex justify-between">
                    <div>ULTIMA LECTURA:</div>
                    <div className="text-right">
                        {`${selectedSensor.default_channel?.last_reading_value} ${selectedSensor.default_channel?.parameter.symbol}`}
                    </div>
                </div>
                <div className=" flex justify-between">
                    <div>FECHA ULTIMA LECTURA:</div>
                    <div className="text-right">
                        {moment(
                            selectedSensor.default_channel.last_reading_datetime
                        )
                            .tz(timeZone)
                            .format("DD/MM/YYYY HH:mm")}
                    </div>
                </div>
                <div className=" flex justify-between">
                    <div>INSTRUMENTO:</div>
                    <div>
                        {selectedSensor.installation.equipment.serial_number}
                    </div>
                </div>
            </div>
            <div className="h-30 flex ml-2 flex-col flex-1">
                <div
                    className="flex-1 flex items-center justify-center bg-white mb-1 cursor-pointer"
                    onClick={() => {
                        setSelectedChannel(selectedSensor.default_channel)
                        history.push(Routes.Analysis)
                    }}
                >
                    <Icon path={mdiEye} className="w-6" color="#BBB"></Icon>
                </div>
                <div
                    className="flex-1 flex items-center justify-center bg-white"
                    onClick={() => {
                        centerMap({
                            lat: selectedSensor.installation.localization
                                .position.coordinates[1],
                            lng: selectedSensor.installation.localization
                                .position.coordinates[0],
                        })
                    }}
                >
                    <Icon
                        path={mdiTarget}
                        className="w-6 cursor-pointer"
                        color="#BBB"
                    ></Icon>
                </div>
            </div>
        </div>
    )
}
export default SensorDetail
