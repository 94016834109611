import axios from 'axios'
import { SettingActions } from '../reducers/SettingReducer'
import store from '../store'
import { authHeader } from './auth'

const API_URL = process.env.REACT_APP_API_URL
const SETTINGS_URL = process.env.REACT_APP_PATH_SETTINGS

export const getSettings = async () => {
    store.dispatch({ type: SettingActions.RequestSettings, payload: {} })
    const response = await axios
        .get(API_URL + SETTINGS_URL,
            { headers: authHeader() }
        )
    store.dispatch({ type: SettingActions.SetSettings, payload: response.data })
}

export const saveMapSettings = async (key: string, settings: MapSettings) =>{
    const {data} = await axios
        .get<Setting[]>(API_URL + SETTINGS_URL + '?key=' + key ,
        {headers : authHeader()})
    if (data?.length) {
        const response = await axios
        .patch<Setting>(API_URL + SETTINGS_URL+ data[0].id.toString() + '/',
            { value: settings },
            { headers: authHeader() }
        )
    }
    
}

