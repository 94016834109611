import { ConditionGroupActions } from '../reducers/ConditionGroupReducer'
import store from '../store'
import axios from 'axios'
import { authHeader } from './auth'
import { getChannels } from './channel'

const API_URL = process.env.REACT_APP_API_URL

export const getConditionGroup = async () => {
	store.dispatch({
		type: ConditionGroupActions.RequestConditionGroup,
		payload: {},
	})
	const response = await axios.get(API_URL + 'condition_groups/1/', {
		headers: authHeader(),
	})
	store.dispatch({
		type: ConditionGroupActions.SetConditionGroup,
		payload: response.data,
	})
	const selectedConditionGroup =
		store.getState().conditionGroupReducer.selectedConditionGroup
	console.log(response.data)
	if (selectedConditionGroup) selectConditionGroup(response.data)
}

export const selectConditionGroup = async (conditionGroup: ConditionGroup) => {
	store.dispatch({
		type: ConditionGroupActions.SetSelectedConditionGroup,
		payload: conditionGroup,
	})
}

export const getConditionGroups = async () => {
	store.dispatch({
		type: ConditionGroupActions.RequestConditionGroups,
		payload: {},
	})
	const response = await axios.get(API_URL + 'condition_groups/', {
		headers: authHeader(),
	})
	store.dispatch({
		type: ConditionGroupActions.SetConditionGroups,
		payload: response.data,
	})
}

export const fetchConditionGroup = async (conditionGroupId: number) => {
	const response = await axios.get(
		API_URL + `condition_groups/${conditionGroupId}/`,
		{ headers: authHeader() }
	)
	return response.data as ConditionGroup
}

export const createConditionGroup = async (
	conditonGroup: Partial<ConditionGroup> & { channels: number[] }
) => {
	const response = await axios.post(
		API_URL + 'condition_groups/',
		conditonGroup,
		{ headers: authHeader() }
	)
}

export const createCondition = async (conditonGroup: Partial<Condition>) => {
	const response = await axios.post(
		API_URL + 'condition_groups/',
		conditonGroup,
		{ headers: authHeader() }
	)
}

export const assignChannel = async (
	channel: Channel,
	conditionGroup: ConditionGroup
) => {
	const response = await axios.put(
		API_URL +
		`condition_groups/${conditionGroup.id}/channel/?channel_id=${channel.id}`,
		{},
		{ headers: authHeader() }
	)
}

export const unassignChannel = async (
	channel: Channel,
	conditionGroup: ConditionGroup
) => {
	const response = await axios.delete(
		API_URL +
		`condition_groups/${conditionGroup.id}/channel/?channel_id=${channel.id}`,
		{ headers: authHeader() }
	)
}

export const addCondition = async (
	condition: Partial<Condition>,
	conditionGroup: ConditionGroup
) => {
	console.log(condition, conditionGroup)
	const creationResponse = await axios.post(
		API_URL + `conditions/`,
		condition,
		{ headers: authHeader() }
	)

	const assignationResponse = await axios.put(
		API_URL +
		`condition_groups/${conditionGroup.id}/condition/?condition_id=${creationResponse.data.id}`,
		{},
		{ headers: authHeader() }
	)
}

export const deleteCondition = async (
	condition: Partial<Condition>,
	conditionGroup: Partial<ConditionGroup>
) => {
	const unassignationResponse = await axios.delete(
		API_URL +
		`condition_groups/${conditionGroup.id}/condition/?condition_id=${condition.id}`,
		{ headers: authHeader() }
	)
	axios.delete(API_URL + `conditions/` + condition.id, {
		headers: authHeader(),
	})
}

export const changeConditionGroupProperties = async (
	conditionGroup: ConditionGroup,
	properties: Partial<ConditionGroup>
) => {
	return axios
		.patch(API_URL + `condition_groups/${conditionGroup.id}/`, properties, {
			headers: authHeader(),
		})
		.then(() => getChannels(axios))
}
