import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core"
import { mdiCloseThick } from "@mdi/js"
import Icon from "@mdi/react"
import { useState } from "react"
import { useAppSelector } from "../../hooks/storeHooks"
import { getChannels, setSelectedChannel } from "../../services/channel"
import { getConditionGroups, unassignChannel } from "../../services/conditionGroup"
import { withError } from "../../services/snackbarNotification"
import QuestionModal from "../Shared/QuestionModal"
import AddConditionToChannellGroupModal from "./ConditionGroupTable/AddConditionToChannellGroupModal"

interface Props {
  channel: Channel

}

const ChannelConditionGroupList: React.FC<Props> = ({ channel }) => {
  const [addGroupModalOpen, setAddGroupModalOpen] = useState(false)
  const [unassignChannelModalOpen, setUnassignChannelModalOpen] = useState(false)
  const [targetConditionGroup, setTargetConditionGroup] = useState(null)

  const conditionGroups = useAppSelector(s => s.conditionGroupReducer.conditionGroups.filter(g => channel.condition_groups.includes(g.id)))

  const canEdit = useAppSelector(
    (s) =>
      s.authReducer.isEditing
  )
  return <div className="overflow-auto w-full  mt-2">
    <div className="flex justify-between">
      Canales asignados{" "}
      {canEdit && (
        <Button
          variant="contained"
          color="primary"
          className="p-1"
          onClick={() => {
            setAddGroupModalOpen(true)
            setSelectedChannel(channel)
          }
          }
        >
          Agregar
        </Button>
      )}
    </div>
    <TableContainer component={Paper} className="overflow-auto">
      <Table className="" size="small">
        <TableBody>
          {conditionGroups?.map((cg) => (
            <TableRow key={cg.id}>
              <TableCell>{cg.name}</TableCell>

              <TableCell
                align="right"
                className="text-right"
              >
                {canEdit && (
                  <div className="flex justify-end">
                    <div
                      className="w-5 h-5"
                      onClick={() => {
                        setTargetConditionGroup(cg)
                        setUnassignChannelModalOpen(
                          true
                        )
                      }}
                    >
                      <Icon
                        path={mdiCloseThick}
                        className="text-gray-700 hover:text-red-500 cursor-pointer transition-all"
                      ></Icon>
                    </div>
                  </div>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <AddConditionToChannellGroupModal
      open={addGroupModalOpen}
      onClose={() => setAddGroupModalOpen(false)}

    >

    </AddConditionToChannellGroupModal>
    <QuestionModal
      onYes={withError(() =>
        unassignChannel(channel, targetConditionGroup).then(() => {
          getConditionGroups()
          getChannels()
        }))
      }
      open={unassignChannelModalOpen}
      text={`¿Seguro que desea remover el canal ${channel?.label} del este grupo?`}
      onClose={() => setUnassignChannelModalOpen(false)}
      snackbarNotificationText="Canal removido del grupo"
    ></QuestionModal>
  </div >
}

export default ChannelConditionGroupList