import { Redirect, Route } from "react-router-dom"
import { useAppSelector } from "../../hooks/storeHooks"

interface Props {
    path: string
    exact?: boolean
}

const ProtectedRoute: React.FC<Props> = ({ path, exact, children }) => {
    const loggedIn = useAppSelector((s) => !!s.authReducer.token)

    return (
        <Route
            path={path}
            exact={!!exact}
            render={(props) => {
                return loggedIn ? <>{children}</> : <Redirect to="/login" />
            }}
        />
    )
}
export default ProtectedRoute
