import realStore from '../store'
import { AlertActions } from '../reducers/AlertReducer'
import realAxios from 'axios';
import { authHeader } from './auth'
import { useAppSelector } from '../hooks/storeHooks';

const API_URL = process.env.REACT_APP_API_URL

let live_reload = true
const REFRESH_TIME = +process.env.REACT_APP_ALERTS_REFRESH_TIME



export const syncAlerts = () => {
    live_reload = true
    const interval = setInterval(() => {
        if (live_reload) {
            getAlerts()
        }
        else {
            clearInterval(interval)
        }
    }, REFRESH_TIME)
}


export const getAlerts = async (axios = realAxios, store = realStore) => {
    store.dispatch({ type: AlertActions.RequestAlerts, payload: {} })
    const response = await axios
        .get(API_URL + 'alert_statuses/',
            { headers: authHeader() }
        );
    store.dispatch({ type: AlertActions.SetAlerts, payload: response.data })
}

export const useRaisedAlertStatus = () => {
    return useAppSelector(s => {
        const raisedAlerts = s.alertReducer.alerts.filter(a => a.status === 'raised')
        return raisedAlerts.length ? raisedAlerts[0] : null
    })
}

export const pauseAlert = async (alert: AlertStatus, comment: string, axios = realAxios) => {
    await axios
        .patch(API_URL + 'alert_statuses/' + alert.id + '/', { status: 'paused', comment: comment },
            { headers: authHeader() }
        )
    getAlerts(axios)
}

export const resolveAlert = async (alert: AlertStatus, comment: string, axios = realAxios) => {
    await axios
        .patch(API_URL + 'alert_statuses/' + alert.id + '/', { status: 'resolved', comment: comment },
            { headers: authHeader() }
        )
    getAlerts(axios)
}

export const stopAlertSync = () => live_reload = false
