import axios from 'axios'
import { authHeader } from './auth'

const API_URL = process.env.REACT_APP_API_URL

const CONDITION_URL = process.env.REACT_APP_PATH_CONDITIONS


export const changeConditionProperties = async (condition: Condition, properties: Partial<Condition>) => {
    await axios.patch(API_URL + CONDITION_URL + `${condition.id}/`, properties, { headers: authHeader() })
}
