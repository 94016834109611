import { Redirect } from 'react-router-dom'
import { useAppSelector } from '../../hooks/storeHooks'
import { useForm } from '../../hooks/useForm'
import { login } from '../../services/auth'
import logo from '../../assets/images/GeoSinergiaLogo.svg'
import { Input, InputAdornment } from '@material-ui/core'
import { mdiAccount, mdiKey } from '@mdi/js'
import Icon from '@mdi/react'
import { mainColor } from '../../styles/styleVars.js'
import { useState } from 'react'
import PasswordRecovery from './PasswordRecovery'
import { withError } from '../../services/snackbarNotification'

const Login: React.FC = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [values, _, handleChange] = useForm({
		username: '',
		password: '',
	})

	const [passwordRecoveryView, setPasswordRecoveryView] = useState(false)

	const isAuthenticated = useAppSelector((s) => !!s.authReducer.token)

	return isAuthenticated ? (
		<Redirect to='/' />
	) : (
		<div className='flex flex-col items-center h-screen justify-around bg-gradient-to-b from-gray-100 to-gray-300 tracking-wider'>
			<div className='flex flex-col items-center w-2/4'>
				<div className='w-20 bg-main-1 rounded-full p-5'>
					<img src={logo} alt='' />
				</div>
				<div className='my-4 text-xl text-main-1 font-bold'>
					Geosinergia
				</div>
				{passwordRecoveryView ? (
					<PasswordRecovery
						goBack={() => {
							setPasswordRecoveryView(false)
						}}
					></PasswordRecovery>
				) : (
					<form
						className='w-8/12'
						id='login'
						key='login'
						data-test='login-form'
						onSubmit={withError((e) => {
							e.preventDefault()
							login(values.username, values.password)
						})}
					>
						<div className='bg-white rounded-xl shadow-sm pt-10 pb-2 px-10 flex flex-col'>
							<div className='text-main-1 text-center pt-3 text-l'>
								INICIO DE SESIÓN
							</div>
							<Input
								type='text'
								placeholder='Usuario'
								id='username'
								value={values.username}
								className='mt-8 w-8/12 mx-auto'
								onChange={handleChange}
								data-test='username-input'
								endAdornment={
									<InputAdornment position='end'>
										<Icon
											path={mdiAccount}
											title=''
											size={1}
											color={mainColor}
										/>
									</InputAdornment>
								}
							/>

							<Input
								type='password'
								placeholder='Contraseña'
								id='password'
								value={values.password}
								className='mt-8 w-8/12 mx-auto'
								onChange={handleChange}
								endAdornment={
									<InputAdornment position='end'>
										<Icon
											path={mdiKey}
											title='Password'
											size={1}
											color={mainColor}
										/>
									</InputAdornment>
								}
							/>
							<div
								className='mt-10 text-sm text-main-1 text-center cursor-pointer'
								onClick={() => setPasswordRecoveryView(true)}
							>
								¿No recuerdas <b>tu contraseña?</b>
							</div>

							<div className='flex justify-center'>
								<div className='flex-shrink'>
									<button
										className='mt-3 rounded-full bg-main-1 text-white relative top-8 px-8 py-4'
										onClick={withError(() =>
											login(
												values.username,
												values.password
											)
										)}
									>
										Ingresar
									</button>
								</div>
							</div>
						</div>
					</form>
				)}
			</div>
		</div>
	)
}
export default Login
