import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Button,
} from "@material-ui/core"
import { useEffect, useState } from "react"
import { Permissions } from "../../../config/Permissions"
import { useAppSelector } from "../../../hooks/storeHooks"
import { hasPermission } from "../../../services/auth"
import {
    deleteSmsDestinatary,
    getSmsDestinataries,
} from "../../../services/destinatary"
import DeleteButton from "../../Shared/DeleteButton"
import QuestionModal from "../../Shared/QuestionModal"
import SmsDestinataryFormModal from "./SmsDestinataryFormModal"

const SmsDestinatariesTable: React.FC = () => {
    const smsDestinataries = useAppSelector(
        (s) => s.destinataryReducer.smsDestinataries
    )
    const [targetDestinatary, setTargetDestinatary] =
        useState<SmsDestinatary>(null)
    useEffect(() => {
        getSmsDestinataries()
    }, [])
    const [deleteDestinataryModalOpen, setDeleteDestinataryModalOpen] =
        useState(false)
    const [smsDestinataryFormModalOpen, setSmsDestinataryFormModalOpen] =
        useState(false)

    const canEdit =
        useAppSelector((s) => s.authReducer.isEditing) &&
        hasPermission(Permissions.ModifySmsDestinataries)

    return (
        <div className="p-3">
            <div className="flex justify-between p-1">
                Destinatarios Sms
                {canEdit && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setSmsDestinataryFormModalOpen(true)}
                    >
                        Nuevo
                    </Button>
                )}
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Número</TableCell>
                        {canEdit && <TableCell></TableCell>}
                    </TableHead>
                    <TableBody>
                        {smsDestinataries.map((sd) => (
                            <TableRow key={sd.id}>
                                <TableCell>{sd.name}</TableCell>
                                <TableCell>{sd.number}</TableCell>
                                {canEdit && (
                                    <TableCell>
                                        <DeleteButton
                                            onClick={async () => {
                                                setTargetDestinatary(sd)
                                                setDeleteDestinataryModalOpen(
                                                    true
                                                )
                                            }}
                                        ></DeleteButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <QuestionModal
                onYes={async () => {
                    await deleteSmsDestinatary(targetDestinatary)
                    getSmsDestinataries()
                }}
                open={deleteDestinataryModalOpen}
                text={"¿Seguro que desea remover este destinatario?"}
                onClose={() => setDeleteDestinataryModalOpen(false)}
                snackbarNotificationText="Destinatario eliminado"
            ></QuestionModal>
            <SmsDestinataryFormModal
                open={smsDestinataryFormModalOpen}
                onClose={() => setSmsDestinataryFormModalOpen(false)}
            ></SmsDestinataryFormModal>
        </div>
    )
}
export default SmsDestinatariesTable
