import { useAppSelector } from "../../hooks/storeHooks"
import { selectConditionGroup } from "../../services/conditionGroup"
import Spinner from "../Shared/Spinner"

const ConditionGroupList: React.FC = () => {
    const conditionGroups = useAppSelector(
        (s) => s.conditionGroupReducer.conditionGroups
    )

    const loading = useAppSelector(
        (s) => s.conditionGroupReducer.loadingConditionGroups
    )

    const selectedConditionGroup = useAppSelector(
        (s) => s.conditionGroupReducer.selectedConditionGroup
    )
    return (
        <div className="p-2 bg-white rounded  text-gray-500 font-medium">
            {loading && !conditionGroups?.length ? (
                <Spinner></Spinner>
            ) : conditionGroups?.length ? (
                conditionGroups.map((cg) => (
                    <div
                        className={`cursor-pointer p-2 rounded bg-opacity-30 transition-all ${
                            selectedConditionGroup?.id === cg.id
                                ? "bg-secondary-1"
                                : ""
                        }`}
                        onClick={() => selectConditionGroup(cg)}
                    >
                        {cg.name}
                    </div>
                ))
            ) : (
                <div className="text bg-gray-700">
                    No hay grupos de condición
                </div>
            )}
        </div>
    )
}
export default ConditionGroupList
