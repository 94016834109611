import { combineReducers } from "redux"
import authReducer from './AuthReducer'
import projectReducer from './ProjectUnitsReducer'
import channelReducer from './ChannelReducer'
import sensorReducer from './SensorReducer'
import mapReducer from './MapReducer'
import alertReducer from './AlertReducer'
import conditionGroupReducer from './ConditionGroupReducer'
import installationReducer from './InstallationReducer'
import equipmentReducer from './EquipmentReducer'
import organizationReducer from './OrganizationReducer'
import snackbarNotificationReducer from './SnackbarReducer'
import timeReducer from './TimeReducer'
import actionModeReducer from './ActionModeReducer'
import destinataryReducer from './DestinataryReducer'
import settingReducer from './SettingReducer'
import dataloggerReducer from './DataloggerReducer'
import repositoryElementReducer from './RepositoryElementReducer'

const rootReducer = combineReducers({
    authReducer,
    projectReducer,
    channelReducer,
    sensorReducer,
    mapReducer,
    alertReducer,
    conditionGroupReducer,
    installationReducer,
    equipmentReducer,
    organizationReducer,
    snackbarNotificationReducer,
    timeReducer,
    actionModeReducer,
    destinataryReducer,
    settingReducer,
    dataloggerReducer,
    repositoryElementReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer