interface Props {
	conditionGroup: ConditionGroup
}

const Leyend: React.FC<Props> = ({ conditionGroup }) => {
	return (
		<div
			className='bg-gray-50 rounded font-bold text-xs p-2'
			style={{
				position: 'absolute',
				right: 10,
				top: 10,
				zIndex: 10000,
			}}
		>
			<div className='text-center p-1 text-base'>ESTADO {}</div>
			{!!conditionGroup.conditions?.length &&
				conditionGroup.conditions.map((condition) =>
					!!condition.color ? (
						<div
							key={condition.value_1}
							className='flex items-center font-medium'
						>
							<div
								className='h-4 w-4 m-1 rounded-full shadow'
								style={{
									backgroundColor: condition.color || '#999',
								}}
							></div>
							{condition.name}
							<div className='flex-1'></div>
							{/* <div className="ml-1 font-normal">
                                {`${condition.value_1} ${
                                    condition.value_2 ?? "o más"
                                }`}
                            </div> */}
						</div>
					) : (
						<></>
					)
				)}
		</div>
	)
}
export default Leyend
