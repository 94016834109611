import { Reducer } from "react";

interface State {
    isLoading: boolean,
    actionModes: ActionMode[]
}

const initialState: State = {
    isLoading: false,
    actionModes: []
}

export enum ActionModeActions {
    RequestActionModes = 'REQUEST_ACTION_MODES',
    SetActionModes = 'SET_ACTION_MODES'
}

const reducer: Reducer<State, { type: ActionModeActions, payload: any }> = (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case ActionModeActions.RequestActionModes:
            return { ...state, isLoading: true }
        case ActionModeActions.SetActionModes:
            return { ...state, actionModes: payload, isLoading: false }
        default:
            return state;
    }
}

export default reducer