import { Reducer } from "react";

interface State {
    isLoading: boolean,
    installations: Installation[],
}

const initialState: State = {
    isLoading: false,
    installations: [],
}

export enum InstallationActions {
    RequestInstallations = 'REQUEST_INSTALLATIONS',
    SetInstallations = 'SET_INSTALLATIONS',
}

const reducer: Reducer<State, { type: InstallationActions, payload: any }> = (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case InstallationActions.RequestInstallations:
            return { ...state, isLoading: true }
        case InstallationActions.SetInstallations:
            return { ...state, isLoading: false, installations: payload }
        default:
            return state;
    }
}

export default reducer