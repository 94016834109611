/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import { Canvg, presets } from 'canvg'
import {
	Button,
	Checkbox,
	FormControlLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core'
import AppCheckbox from '../../Shared/Checkbox'
import { useAppSelector } from '../../../hooks/storeHooks'
import { getChannelInterval } from '../../../services/channel'
import {
	mainColor,
	secondaryColor,
	singleChannelChartLineColor,
} from '../../../styles/styleVars'
import moment from 'moment-timezone'
import { DateTimePicker } from '@material-ui/pickers'
import { fetchConditionGroup } from '../../../services/conditionGroup'
import Icon from '@mdi/react'
import { mdiSync } from '@mdi/js'
import axios from 'axios'
import { authHeader, hasPermission } from '../../../services/auth'
import ColorPicker from 'material-ui-color-picker'
import 'moment/locale/es'
import { Permissions } from '../../../config/Permissions'
const DEFAULT_DELTA = +process.env
	.REACT_APP_SINGLE_CHANNEL_CHART_DEFAULT_DELTA_IN_SECONDS as number

const UPDATE_MILLISECONDS = 60000
const AXIS_WIDTH_FRACTION = 0.08
const margin = { top: 20, right: 20, bottom: 20, left: 20 }
const TOP_LABEL_HEIGHT = 30
const MAX_POINTS_IN_CHART = 1000

const SingleChannelSignalChart: React.FC = () => {
	const [nearestPoint, setNearestPoint] =
		useState<{ [field_name_origin: string]: number; timestamp: number }>(
			null
		)
	const [selectedPoint1, setSelectedPoint1] =
		useState<{ [field_name_origin: string]: number; timestamp: number }>(
			null
		)
	const [selectedPoint2, setSelectedPoint2] =
		useState<{ [field_name_origin: string]: number; timestamp: number }>(
			null
		)
	const channel = useAppSelector(
		(s) => s.channelReducer.selectedChannel,
		(a, b) => a.id === b.id
	)
	const timeZone = useAppSelector((s) => s.timeReducer.timeZone)
	const container = useRef<HTMLDivElement>()
	const [mouse, setMouse] = useState({ x: 0, y: 0 })
	const [centerFixed, setCenterFixed] = useState(false)
	const [centerXFixed, setCenterXFixed] = useState(false)
	const [showRanges, setShowRanges] = useState(false)
	const [showDots, setShowDots] = useState(false)
	const [dotColor, setDotColor] = useState(secondaryColor)
	const [dragging, setDragging] = useState(false)
	const [dragStart, setDragStart] = useState(0)
	const [width, setWidth] = useState(0)
	const [height, setHeight] = useState(0)
	const [loading, setLoading] = useState(false)
	const [yRange, setYRange] = useState({ min: 0, max: 0 })
	const [selectedConditionGroup, setSelectedConditionGroup] =
		useState<number>(null)
	const [conditionGroups, setConditionGroups] =
		useState<ConditionGroup[]>(null)
	const [yRangeInputValues, setYRangeInputValues] = useState({
		min: '',
		max: '',
	})
	const [timeRange, setTimeRange] = useState({
		start: moment().tz(timeZone).subtract(DEFAULT_DELTA, 'seconds'),
		end: moment().tz(timeZone),
	})
	const [data, setData] =
		useState<{ [field_name_origin: string]: number; timestamp: number }[]>()
	const [, setRefreshInterval] = useState(null)

	const updateSize = () => {
		if (container.current) {
			setWidth(container?.current?.offsetWidth)
			setHeight(container?.current?.offsetHeight)
		}
	}

	useEffect(() => {
		setConditionGroups([])
		setSelectedPoint1(null)
		setSelectedPoint2(null)
		channel?.condition_groups?.forEach((c) =>
			fetchConditionGroup(c).then((cg) =>
				setConditionGroups((prev) => [...prev, cg])
			)
		)
	}, [channel])

	useEffect(() => {
		getData({ setY: true })

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeRange])

	useEffect(() => {
		const min = parseFloat(yRangeInputValues.min)
		const max = parseFloat(yRangeInputValues.max)
		setYRange({
			min: typeof min === 'number' ? min : yRange.min,
			max: typeof max === 'number' ? max : yRange.max,
		})
	}, [yRangeInputValues])

	useEffect(() => {
		centerXFixed
			? setRefreshInterval(setInterval(getData, 5000))
			: setRefreshInterval((i) => {
					clearInterval(i)
					return null
			  })
	}, [centerXFixed])

	const getData = async ({ setY } = { setY: false }) => {
		const start = timeRange.start.utc().format('YYYY-MM-DD HH:mm:ss')
		const end = timeRange.end.utc().format('YYYY-MM-DD HH:mm:ss')
		setLoading(true)
		const data = await getChannelInterval(channel, start, end)
		setData(() => data)
		setLoading(false)
		setY && centerY(data)
	}

	const exportDataToExcel = async () => {
		const response = await axios
			.get(
				process.env.REACT_APP_API_URL +
					'channels/' +
					channel.id +
					'/excel/?start=' +
					timeRange.start.utc().format('YYYY-MM-DD HH:mm:ss') +
					'&end=' +
					timeRange.end.utc().format('YYYY-MM-DD HH:mm:ss'),
				{ headers: authHeader(), responseType: 'blob' }
			)
			.then((response) => {
				var link = document.createElement('a')
				link.href = window.URL.createObjectURL(
					new Blob([response.data], {
						type: 'application/vnd.ms-excel',
					})
				)
				link.download = channel.label + '.xlsx'
				document.body.appendChild(link)
				link.click()
			})
			.catch((error) => {})
	}

	const yScale = () =>
		d3
			.scaleLinear()
			.domain(
				data
					? [
							+yRange.max ??
								d3.max(
									data,
									(d) => d[channel.field_name_origin]
								),
							+yRange.min ??
								Math.min(
									0,
									d3.min(
										data,
										(d) => d[channel.field_name_origin]
									)
								),
					  ]
					: [0, 0]
			)
			.range([
				margin.top + TOP_LABEL_HEIGHT,
				(height * 3) / 4 - margin.bottom,
			])

	const xScale = () =>
		d3
			.scaleLinear()
			.domain(
				data
					? [
							Math.min(
								timeRange.start.unix(),
								d3.min(data, (d) => d.timestamp)
							),

							Math.max(
								timeRange.end.unix(),
								d3.max(data, (d) => d.timestamp)
							),
					  ]
					: [0, 0]
			)
			.range([
				width * AXIS_WIDTH_FRACTION + margin.left,
				width - margin.right,
			])

	const xScaleInverse = () =>
		d3
			.scaleLinear()
			.domain(
				data
					? [
							Math.min(
								timeRange.start.unix(),
								d3.min(data, (d) => d.timestamp)
							),

							Math.max(
								timeRange.end.unix(),
								d3.max(data, (d) => d.timestamp)
							),
					  ]
					: [0, 0]
			)
			.range([
				width * AXIS_WIDTH_FRACTION + margin.left,
				width - margin.right,
			]).invert

	useEffect(() => {
		getData({ setY: true })
		updateSize()
		window.addEventListener('resize', () => {
			updateSize()
		})
	}, [channel])

	const center = () => {
		centerY()
		centerX()
	}
	const centerX = () => {
		setTimeRange({
			start: moment().tz(timeZone).subtract(DEFAULT_DELTA, 'seconds'),
			end: moment().tz(timeZone),
		})
	}
	const centerY = (
		newData?: { [field_name_origin: string]: number; timestamp: number }[]
	) => {
		if (newData) {
			setYRange({
				max: d3.max(newData, (d) => d[channel.field_name_origin]),
				min: d3.min(newData, (d) => d[channel.field_name_origin]),
			})
			setYRangeInputValues({
				max: d3
					.max(newData, (d) => d[channel.field_name_origin])
					?.toString(),
				min: d3
					.min(newData, (d) => d[channel.field_name_origin])
					?.toString(),
			})
		} else {
			data &&
				setYRange({
					max: d3.max(data, (d) => d[channel.field_name_origin]),
					min: Math.min(
						0,
						d3.min(data, (d) => d[channel.field_name_origin])
					),
				})
			setYRangeInputValues({
				max: d3
					.max(data, (d) => d[channel.field_name_origin])
					.toString(),
				min: d3
					.min(data, (d) => d[channel.field_name_origin])
					.toString(),
			})
		}
	}

	const reducedData = () => {
		var aux = data
		while (aux?.length > MAX_POINTS_IN_CHART) {
			aux = aux.filter(
				(_, i, arr) => i % 2 === 0 || i === 0 || i === arr.length - 1
			)
		}
		return aux
	}

	const exportPng = async () => {
		//@ts-ignore
		const canvas = new OffscreenCanvas(width, height)
		const ctx = canvas.getContext('2d')
		const v = await Canvg.from(
			ctx,
			`<svg>${document.getElementById('svg').innerHTML}</svg>`,
			presets.offscreen()
		)

		// Render only first frame, ignoring animations and mouse.
		await v.render()

		const blob = await canvas.convertToBlob()
		const pngUrl = URL.createObjectURL(blob)
		const link = document.createElement('a')
		link.href = pngUrl
		link.target = '_blank'
		link.click()
		link.remove()
	}

	const displayData = reducedData()

	return (
		<div className='h-full flex flex-col mb-2  justify-between bg-white rounded'>
			<div className='w-full h-2/3 ' ref={container}>
				<canvas
					className='hidden'
					width={width}
					height={height}
					id='canvas'
				></canvas>
				<svg
					width={width}
					height={height}
					id='svg'
					onMouseDownCapture={() => {
						if (!centerXFixed) {
							setDragStart(mouse.x)
							setDragging(true)
						}
					}}
					onMouseMoveCapture={(e) => {
						const currentTargerRect =
							e.currentTarget.getBoundingClientRect()
						setMouse({
							x:
								e.pageX -
								currentTargerRect.left -
								width * AXIS_WIDTH_FRACTION,
							y: e.pageY - currentTargerRect.top,
						})
					}}
					onMouseUpCapture={() => {
						if (!centerXFixed) {
							setDragging(false)
							if (Math.abs(dragStart - mouse.x) > 10) {
								const v1 = xScaleInverse()(
									dragStart + width * AXIS_WIDTH_FRACTION
								)
								const v2 = xScaleInverse()(
									mouse.x + width * AXIS_WIDTH_FRACTION
								)
								setTimeRange({
									start: moment.unix(Math.min(v1, v2)),
									end: moment.unix(Math.max(v1, v2)),
								})
							} else {
								if (
									hasPermission(
										Permissions.SelectSingleChannelPoints
									)
								) {
									setSelectedPoint2(selectedPoint1)
									setSelectedPoint1(nearestPoint)
								}
							}
						}
					}}
					onMouseLeave={() => setDragging(false)}
				>
					<defs>
						<linearGradient
							id='Gradient1'
							gradientTransform='rotate(90)'
						>
							<stop
								className='stop1'
								offset='0%'
								stopOpacity='.2'
								stopColor={secondaryColor}
							/>
							<stop
								className='stop3'
								offset='100%'
								stopColor={secondaryColor}
								stopOpacity='.8'
							/>
						</linearGradient>
					</defs>
					<text
						x={width / 2}
						y={5 + margin.top}
						textAnchor='middle'
						className='font-semibold uppercase'
					>
						{'Canal: ' +
							channel.label +
							', Unidad: ' +
							channel.parameter.unit}
					</text>
					<text
						x={width / 2}
						y={5 + margin.top + 20}
						className='font-normal	 text-sm'
						textAnchor='middle'
					>
						{`Inicio: ${timeRange.start.format(
							'YYYY-MM-DD HH:mm:ss'
						)},
                         Fin: ${timeRange.end.format('YYYY-MM-DD HH:mm:ss')} `}
					</text>

					{!!data?.length && (
						<>
							<polyline
								width={width}
								height={height}
								stroke={singleChannelChartLineColor}
								fill='none'
								strokeWidth={3}
								points={displayData
									.sort((a, b) => a.timestamp - b.timestamp)
									.reduce(
										(acc, value) =>
											`${acc}${xScale()(
												value.timestamp
											)},${Math.max(
												Math.min(
													yScale()(
														value[
															channel
																.field_name_origin
														]
													),
													(height * 3) / 4
												),
												margin.top + TOP_LABEL_HEIGHT
											)} `,
										''
									)}
							/>
							{yScale()
								.ticks()
								.map((tickValue) => (
									<g key={tickValue}>
										<line
											x1={width * AXIS_WIDTH_FRACTION}
											y1={yScale()(tickValue)}
											x2={width}
											y2={yScale()(tickValue)}
											stroke='#DDD'
										></line>
										<text
											x={width * AXIS_WIDTH_FRACTION - 3}
											y={yScale()(tickValue)}
											textAnchor='end'
										>
											{tickValue.toFixed(2)}
											{' ' + channel.parameter.symbol}
										</text>
									</g>
								))}
							{xScale()
								.ticks()
								.map((tickValue) => (
									<g key={tickValue}>
										<line
											x1={xScale()(tickValue)}
											y1={TOP_LABEL_HEIGHT + margin.top}
											x2={xScale()(tickValue)}
											y2={(height * 3) / 4 - margin.top}
											stroke='#DDD'
										></line>
										<text
											x={xScale()(tickValue)}
											y={(height * 3) / 4}
											className='text-xs'
											textAnchor='end'
											transform={`rotate(-45,${xScale()(
												tickValue
											)} ${(height * 3) / 4})`}
										>
											{moment
												.unix(tickValue)
												.tz(timeZone)
												.format('DD/MM/YYYY HH:mm:ss')}
										</text>
									</g>
								))}
						</>
					)}
					{showRanges &&
						selectedConditionGroup &&
						conditionGroups
							.find((c) => c.id === +selectedConditionGroup)
							?.conditions.map((c) => {
								const v1 =
									c.value_1 === '-inf'
										? -Infinity
										: c.value_1 === 'inf'
										? Infinity
										: +c.value_1
								const v2 =
									c.value_2 === '-inf'
										? -Infinity
										: c.value_2 === 'inf'
										? Infinity
										: +c.value_2
								let top = Math.max(v1, v2)
								let bottom = Math.min(v1, v2)

								const y1 = yScale()(
									Math.max(
										Math.min(top, +yRange.max),
										+yRange.min
									)
								)
								const y2 = yScale()(
									Math.max(
										Math.min(bottom, +yRange.max),
										+yRange.min
									)
								)

								return (
									<rect
										key={c.name}
										x={width * AXIS_WIDTH_FRACTION}
										width={
											width * (1 - AXIS_WIDTH_FRACTION)
										}
										y={y1}
										height={Math.abs(y1 - y2)}
										fill={c.color}
										opacity={0.2}
									></rect>
								)
							})}
					{selectedPoint1 && (
						<circle
							z={-20}
							cx={xScale()(selectedPoint1.timestamp)}
							cy={yScale()(
								selectedPoint1[channel.field_name_origin]
							)}
							fill={mainColor}
							r='5'
						></circle>
					)}
					{selectedPoint2 && (
						<circle
							z={-20}
							cx={xScale()(selectedPoint2.timestamp)}
							cy={yScale()(
								selectedPoint2[channel.field_name_origin]
							)}
							fill={mainColor}
							r='5'
						></circle>
					)}
					{showDots &&
						displayData?.map((v) => (
							<circle
								z={-20}
								cx={xScale()(v.timestamp)}
								cy={yScale()(v[channel.field_name_origin])}
								fill={dotColor}
								r='3.5'
							></circle>
						))}
					{nearestPoint &&
						!!data?.length &&
						xScale()(data[0].timestamp) <
							mouse.x + AXIS_WIDTH_FRACTION * width && (
							<>
								<circle
									z={-20}
									cx={xScale()(nearestPoint.timestamp)}
									cy={yScale()(
										nearestPoint[channel.field_name_origin]
									)}
									stroke={dotColor}
									fill='white'
									r='4'
								></circle>
								<text
									x={xScale()(nearestPoint.timestamp) + 10}
									y={
										yScale()(
											nearestPoint[
												channel.field_name_origin
											]
										) - 10
									}
									className='pointer-events-none'
								>
									{nearestPoint[
										channel.field_name_origin
									]?.toFixed(2) + channel.parameter.symbol}
								</text>
								<text
									className='pointer-events-none'
									x={xScale()(nearestPoint.timestamp) + 10}
									y={
										yScale()(
											nearestPoint[
												channel.field_name_origin
											]
										) + 10
									}
								>
									{moment
										.unix(nearestPoint.timestamp)
										.tz(timeZone)
										.format('HH:mm:ss DD-MM-YYYY')}
								</text>
							</>
						)}
					{selectedPoint1 && (
						<circle
							z={-20}
							cx={xScale()(selectedPoint1.timestamp)}
							cy={yScale()(
								selectedPoint1[channel.field_name_origin]
							)}
							fill={mainColor}
							r='5'
						></circle>
					)}
					{selectedPoint2 && (
						<circle
							z={-20}
							cx={xScale()(selectedPoint2.timestamp)}
							cy={yScale()(
								selectedPoint2[channel.field_name_origin]
							)}
							fill={mainColor}
							r='5'
						></circle>
					)}
					{!!data && !data.length && (
						<text x={width / 2} y={height / 2} textAnchor='middle'>
							No hay datos
						</text>
					)}
					{dragging && (
						<rect
							x={
								Math.min(mouse.x, dragStart) +
								AXIS_WIDTH_FRACTION * width
							}
							y={TOP_LABEL_HEIGHT + margin.top}
							width={Math.abs(mouse.x - dragStart)}
							height={
								(height * 3) / 4 - TOP_LABEL_HEIGHT - margin.top
							}
							fill='url(#Gradient1)'
						></rect>
					)}
					{displayData?.map((d, i, array) => {
						const left = xScale()(
							d.timestamp -
								(i === 0
									? 0
									: Math.abs(
											array[i - 1].timestamp - d.timestamp
									  ) / 2)
						)
						const right = xScale()(
							d.timestamp +
								(i === array.length - 1
									? 0
									: Math.abs(
											array[i + 1].timestamp - d.timestamp
									  ) / 2)
						)
						return (
							<rect
								onMouseEnter={() => {
									setNearestPoint(d)
								}}
								x={left}
								y={TOP_LABEL_HEIGHT + margin.top}
								height={(height * 3) / 4}
								width={Math.abs(left - right)}
								fill='transparent'
							></rect>
						)
					})}
				</svg>
				{loading && (
					<div className='flex justify-center'>
						<Icon
							path={mdiSync}
							className='absolute top-1/4 text-gray-500 w-7'
							spin
						></Icon>
					</div>
				)}
			</div>
			<div className='w-full m-1  p-1  rounded flex'>
				<div className='m-1 p-1 border-gray-300 flex border rounded'>
					<div>
						<div className='p-1'>
							<FormControlLabel
								control={
									<Checkbox
										color='primary'
										checked={centerXFixed}
										onChange={(e) => {
											e.target.checked && centerX()
											setCenterXFixed(e.target.checked)
										}}
									/>
								}
								label='Tiempo real'
							></FormControlLabel>
						</div>

						<div className='m-1 p-1'>
							<div className='p-1'>
								<DateTimePicker
									value={timeRange.start.tz(timeZone)}
									onChange={(time) => {
										setTimeRange((s) => ({
											...s,
											start: time,
										}))
										// adjustRange()
									}}
									label='Inicio'
									InputLabelProps={{
										shrink: true,
									}}
									ampm={false}
									disableFuture
									disabled={centerXFixed}
								></DateTimePicker>
							</div>
							<div className='p-1'>
								<DateTimePicker
									disabled={centerXFixed}
									disableFuture
									value={timeRange.end.tz(timeZone)}
									onChange={(time) => {
										setTimeRange((s) => ({
											...s,
											end: time,
										}))
										// adjustRange()
									}}
									label='Fin'
									InputLabelProps={{
										shrink: true,
									}}
									ampm={false}
								></DateTimePicker>
							</div>
						</div>
					</div>
					<div className='flex p-2 flex-col h-full gap-1 items-center justify-around'>
						<Button
							fullWidth
							variant='outlined'
							onClick={() =>
								setTimeRange({
									start: moment()
										.tz(timeZone)
										.subtract(1, 'hour'),
									end: moment().tz(timeZone),
								})
							}
							disabled={centerXFixed}
						>
							Ultima Hora
						</Button>
						<Button
							fullWidth
							variant='outlined'
							onClick={() =>
								setTimeRange({
									start: moment()
										.tz(timeZone)
										.subtract(1, 'day'),
									end: moment().tz(timeZone),
								})
							}
							disabled={centerXFixed}
						>
							Ultimo Día
						</Button>
						<Button
							fullWidth
							variant='outlined'
							onClick={() =>
								setTimeRange({
									start: moment()
										.tz(timeZone)
										.subtract(1, 'week'),
									end: moment().tz(timeZone),
								})
							}
							disabled={centerXFixed}
						>
							Ultima Semana
						</Button>
						<Button
							fullWidth
							variant='contained'
							className='mt-6'
							color='default'
							onClick={exportPng}
						>
							Exportar imagen
						</Button>
						<Button
							fullWidth
							variant='contained'
							className='mt-6'
							color='default'
							onClick={exportDataToExcel}
						>
							Exportar Datos
						</Button>
					</div>
				</div>
				<div className='m-1 p-1 border-gray-300 border rounded'>
					<div className='p-1'>
						<FormControlLabel
							control={
								<Checkbox
									color='primary'
									checked={centerFixed}
									onChange={(e) => {
										centerY()
										setCenterFixed(e.target.checked)
									}}
								/>
							}
							label='Autoajuste Eje Y'
						></FormControlLabel>
					</div>
					<div className='m-1 p-1'>
						<div className='p-1'>
							<TextField
								id='min'
								type='number'
								placeholder='Y min'
								disabled={centerFixed}
								onChange={(e) => {
									setYRangeInputValues((s) => ({
										...s,
										min: e.target.value,
									}))
								}}
								label='Y Min'
								variant='outlined'
								value={yRangeInputValues.min}
								InputLabelProps={{
									shrink: true,
								}}
							></TextField>
						</div>
						<div className='p-1'>
							<TextField
								id='max'
								type='number'
								placeholder='Y max'
								value={yRangeInputValues.max}
								onChange={(e) => {
									setYRangeInputValues((s) => ({
										...s,
										max: e.target.value,
									}))
								}}
								disabled={centerFixed}
								variant='outlined'
								label='Y Max'
								InputLabelProps={{
									shrink: true,
								}}
							></TextField>
						</div>
					</div>
				</div>

				<div className='p-1 m-1 border-gray-300 border rounded'>
					<div className='flex items-center'>
						<div>
							<ColorPicker
								name='color'
								id='color'
								value={dotColor}
								size='medium'
								fullWidth
								label='Color'
								onChange={(color) => setDotColor(color)}
								InputProps={{
									value: dotColor,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								className='relative bottom-0'
							/>
						</div>
						<div
							className=' ml-1 w-5 h-5 rounded-full shadow'
							style={{
								backgroundColor: dotColor,
							}}
						></div>
					</div>
					<div className='p-x-1'>
						<AppCheckbox
							label='Mostrar puntos'
							checked={showDots}
							onChange={(e, checked) => setShowDots(checked)}
						></AppCheckbox>
					</div>
					{!!conditionGroups?.length && (
						<>
							<div className='p-x-1'>
								<FormControlLabel
									control={
										<Checkbox
											color='primary'
											checked={showRanges}
											onChange={(e) =>
												setShowRanges(e.target.checked)
											}
										/>
									}
									label='Mostrar Umbrales'
								></FormControlLabel>
							</div>
							<div className='p-x-1'>
								<Select
									variant='outlined'
									fullWidth
									label='Grupo de condición'
									value={selectedConditionGroup}
									onChange={(e) => {
										setSelectedConditionGroup(
											e.target.value as number
										)
									}}
									disabled={!showRanges}
								>
									{conditionGroups?.map((c) => (
										<MenuItem key={c.id} value={c.id}>
											{c.name}
										</MenuItem>
									))}
								</Select>
							</div>
						</>
					)}
				</div>
				{(selectedPoint1 || selectedPoint2) && (
					<div className='p-1 m-1 border-gray-300 flex border rounded'>
						<div className='p-1'>
							{selectedPoint1 && (
								<div className='border-gray-300 border rounded p-1'>
									<div className='text-center text-xs text-gray-600'>
										Punto 1
									</div>
									<div className='flex space-x-1 justify-between'>
										<div>Valor:</div>
										<div>
											{selectedPoint1[
												channel.field_name_origin
											]?.toFixed(2) +
												channel.parameter.symbol}
										</div>{' '}
									</div>
									<div className='flex space-x-1 justify-between'>
										<div>Fecha:</div>
										<div>
											{moment
												.unix(selectedPoint1.timestamp)
												.tz(timeZone)
												.format('HH:mm DD-MM-YYYY')}
										</div>{' '}
									</div>
								</div>
							)}
							{selectedPoint2 && (
								<div className='border-gray-300 border rounded p-1 mt-1'>
									<div className='text-center text-xs text-gray-600'>
										Punto 2
									</div>
									<div className='flex space-x-1 justify-between'>
										<div>Valor:</div>
										<div>
											{selectedPoint2[
												channel.field_name_origin
											]?.toFixed(2) +
												channel.parameter.symbol}
										</div>{' '}
									</div>
									<div className='flex space-x-1 justify-between'>
										<div>Fecha:</div>
										<div>
											{moment
												.unix(selectedPoint2.timestamp)
												.tz(timeZone)
												.format('HH:mm DD-MM-YYYY')}
										</div>{' '}
									</div>
								</div>
							)}
						</div>
						{selectedPoint1 && selectedPoint2 && (
							<div className='p-1'>
								<div className='border-gray-300 border rounded p-1 '>
									<div className='flex space-x-1 justify-between'>
										<div>Diferencia:</div>
										<div>
											{(
												selectedPoint2[
													channel.field_name_origin
												] -
												selectedPoint1[
													channel.field_name_origin
												]
											)?.toFixed(2) +
												channel.parameter.symbol}
										</div>{' '}
									</div>
									<div className='flex space-x-1 justify-between'>
										<div>Intervalo:</div>
										<div>
											{moment
												.duration(
													moment
														.unix(
															selectedPoint2.timestamp
														)
														.diff(
															moment.unix(
																selectedPoint1.timestamp
															)
														)
												)
												.locale('es')
												.humanize()}
										</div>
									</div>
									<div className='flex space-x-1 justify-between'>
										<div>Razon:</div>
										<div>
											{(
												(selectedPoint2[
													channel.field_name_origin
												] -
													selectedPoint1[
														channel
															.field_name_origin
													]) /
												(selectedPoint2.timestamp -
													selectedPoint1.timestamp)
											)?.toFixed(5) +
												channel.parameter.symbol}
											/s
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	)
}
export default SingleChannelSignalChart
