import store from '../store'
import { DataloggerActions } from '../reducers/DataloggerReducer'
import axios from 'axios'
import { authHeader } from './auth'
const API_URL = process.env.REACT_APP_API_URL

let live_reload = true
let interval = null
const REFRESH_TIME = +process.env.REACT_APP_SENSOR_MONITORING_REFRESH_TIME

export const getDataloggers= async () => {
	try {
		store.dispatch({
			type: DataloggerActions.RequestDataloggers,
			payload: { },
		})
		const response = await axios.get<Datalogger[]>(
			API_URL + `dataloggers/`,
			{ headers: authHeader() }
		)
		store.dispatch({
			type: DataloggerActions.SetDataloggers,
			payload: {
				dataloggers: response.data,
			},
		})
	} catch (e) {
	}
}
