import { Reducer } from "react";

interface State {
    settings: Setting[],
    isLoading: boolean
}

const initialState: State = {
    settings: [],
    isLoading: false
}

export enum SettingActions {
    RequestSettings = 'REQUEST_SETTINGS',
    SetSettings = 'SET_SETTINGS'
}

const reducer: Reducer<State, { type: SettingActions, payload: any }> = (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case SettingActions.RequestSettings:
            return { ...state, isLoading: true }
        case SettingActions.SetSettings:
            return { ...state, settings: payload, isLoading: false }
        default:
            return state;
    }
}

export default reducer