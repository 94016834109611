import AppBar from "@material-ui/core/AppBar"
import AntofagastaMineralsLogo from "../../assets/images/AntofagastaMineralsLogo.svg"
import Clock from "react-live-clock"
import useStyles from "../../styles/appStyles"
import store from "../../store"
import { useAppSelector } from "../../hooks/storeHooks"
import Icon from "@mdi/react"
import { mdiAccount, mdiAlert, mdiCog } from "@mdi/js"
import { mainColor } from "../../styles/styleVars.js"
import { AlertActions } from "../../reducers/AlertReducer"
import AlertsPopup from "../AlertsContainer/AlertsPopup"
import { useEffect, useRef, useState } from "react"
import {
    stopAlertSync,
    syncAlerts,
    useRaisedAlertStatus,
} from "../../services/alert"
import { Menu, MenuItem, Switch } from "@material-ui/core"
import { setIsAdmin } from "../../services/auth"
import { loadTimeZones, setTimeZone } from "../../services/time"
import { Permissions } from "../../config/Permissions"
import AlertModal from "../AlertModal/AlertModal"

const TopBar: React.FC = () => {
    const classes = useStyles(true)()
    const alertStatus = useRaisedAlertStatus()
    const isEditing = useAppSelector((s) => s.authReducer.isEditing)
    const [timezoneMenuOpen, setTimezoneMenuOpen] = useState(false)
    const timeDisplay = useRef()
    const timeZone = useAppSelector((s) => s.timeReducer.timeZone)
    const user = useAppSelector((s) => s.authReducer.user)
    useEffect(() => {
        syncAlerts()
        loadTimeZones()
        return () => {
            stopAlertSync()
        }
    }, [])

    const alerts = useAppSelector((s) => s.alertReducer.alerts)
    const timeZones = useAppSelector((s) => s.timeReducer.timeZones)
    const permissions = useAppSelector((s) => s.authReducer.permissions)

    //   const alertsPopupVisible = useAppSelector((s)=>s.alertReducer.alertsPopupVisible)

    const handleClickOpenAlertsPopup = () => {
        store.dispatch({ type: AlertActions.ShowAlertsPopup, payload: {} })
    }

    return (
        <AppBar className={classes.appBar} position="fixed">
            <AlertsPopup></AlertsPopup>
            <div className={`flex align-center p-2 ${classes.toolbarContent}`}>
                <div className="logoadjust">
                    <img
                        src={AntofagastaMineralsLogo}
                        width="40px"
                        alt="Geosinergia"
                    />
                </div>
                <div className="text-gray-900 m-auto inline-flex items-center">
                    <div className="bg-white px-4 py-2 rounded-full text-xl flex gap-2">
                        <Clock
                            format={"D/M/YYYY HH:mm:ss"}
                            ticking={true}
                            timezone={timeZone}
                        />
                        <div
                            onClick={() => setTimezoneMenuOpen(true)}
                            className="cursor-pointer"
                            ref={timeDisplay}
                        >
                            {timeZone}
                        </div>
                    </div>
                    <div
                        className="bg-white mx-2 p-2 cursor-pointer rounded-full text-2xl inline-flex items-center"
                        onClick={handleClickOpenAlertsPopup}
                    >
                        <Icon
                            path={mdiAlert}
                            title="Alertas"
                            size={1}
                            color={mainColor}
                        />
                    </div>
                    <div
                        className=""
                        style={{ transform: "translate(-20px,10px)" }}
                        onClick={handleClickOpenAlertsPopup}
                    >
                        <div className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                            {alerts.length}
                        </div>
                    </div>
                </div>
                <div className="text-gray-900 mt-1 mb-1 ml-3 flex items-center">
                    <div className="bg-white p-2 gap-2 flex items-center rounded-full  text-xl">
                        {user?.username}
                        <Icon path={mdiAccount} size={1} color="black" />
                    </div>
                </div>
                <div className="text-gray-600 mt-1 mb-1 ml-3 flex items-center">
                    <div className="bg-white  flex items-center rounded-full text-2xl">
                        {!!permissions?.find(
                            (p) => p === Permissions.EditMode
                        ) && (
                                <Switch
                                    value={isEditing}
                                    onChange={(e) => setIsAdmin(e.target.checked)}
                                    checked={isEditing}
                                    color="primary"
                                ></Switch>
                            )}
                        <Icon path={mdiCog} size={1} className="mr-2" />
                    </div>
                </div>
            </div>
            {alertStatus && (
                <AlertModal alertStatus={alertStatus} open={true}></AlertModal>
            )}
            <Menu
                open={timezoneMenuOpen}
                onClose={() => setTimezoneMenuOpen(false)}
                anchorEl={timeDisplay.current}
            >
                {timeZones.map((name) => (
                    <MenuItem
                        key={name}
                        onClick={() => {
                            setTimeZone(name)
                            setTimezoneMenuOpen(false)
                        }}
                    >
                        {name}
                    </MenuItem>
                ))}
            </Menu>
        </AppBar>
    )
}
export default TopBar
