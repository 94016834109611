/* eslint-disable array-callback-return */
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { authHeader } from '../../../services/auth'
import * as d3 from 'd3'
import Bar from './Bar'
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import { useAppSelector } from '../../../hooks/storeHooks'
import Tick from './Tick'
import { getSensorById } from '../../../services/sensor'
const UPDATE_MILLISECONDS = +process.env.REACT_APP_DELTA_CHART_REFRESH_TIME
const AXIS_WIDTH_FRACTION = 0.08
const margin = { top: 20, right: 20, bottom: 20, left: 20 }
const groupId = 2
const TOP_LABEL_HEIGHT = 30

const DeltaProfileChart: React.FC = () => {
	const [data, setData] = useState<{
		channels: Channel[]
		description: string
		name: string
	}>({
		channels: [],
		description: null,
		name: null,
	})
	const hoverChannel = useAppSelector((s) => s.channelReducer.hoverChannel)
	const [width, setWidth] = useState(0)
	const [height, setHeight] = useState(0)
	const [range, setRange] = useState({
		min: +process.env.REACT_APP_DELTA_CHART_DEFAULT_Y_MIN,
		max: +process.env.REACT_APP_DELTA_CHART_DEFAULT_Y_MAX,
	})
	const [rangeValues, setRangeValues] = useState({
		min: process.env.REACT_APP_DELTA_CHART_DEFAULT_Y_MIN.toString(),
		max: process.env.REACT_APP_DELTA_CHART_DEFAULT_Y_MAX.toString(),
	})
	const [centerFixed, setCenterFixed] = useState(false)
	const [showRanges, setShowRanges] = useState(true)
	const container = useRef<HTMLDivElement>()
	const conditionGroup = useAppSelector(
		(s) => s.conditionGroupReducer.conditionGroup
	)

	const updateSize = () => {
		if (container.current) {
			setWidth(container?.current?.offsetWidth)
			setHeight(container?.current?.offsetHeight)
		}
	}
	useEffect(() => {
		getData()
		const interval = setInterval(getData, UPDATE_MILLISECONDS)
		updateSize()
		window.addEventListener('resize', () => {
			updateSize()
		})
		return () => clearInterval(interval)
	}, [])

	const getData = async () => {
		const response = await axios.get(
			process.env.REACT_APP_API_URL + 'channels_groups/' + groupId + '/',
			{ headers: authHeader() }
		)
		setData({
			...response.data,
			channels: response.data?.channels?.sort(
				(a, b) =>
					(parseInt(a.label.split('-')[1]) ?? 0) -
					(parseInt(b.label.split('-')[1]) ?? 0)
			),
		})
	}
	const center = () => {
		setRange({
			max: d3.max(data.channels, (d) => d.last_reading_value),
			min: d3.min(data.channels, (d) => d.last_reading_value),
		})

		setRangeValues({
			max: d3.max(data.channels, (d) => d.last_reading_value).toString(),
			min: d3.min(data.channels, (d) => d.last_reading_value).toString(),
		})
	}

	useEffect(() => {
		const min = parseFloat(rangeValues.min)
		const max = parseFloat(rangeValues.max)
		setRange({
			min: typeof min === 'number' ? min : range.min,
			max: typeof max === 'number' ? max : range.max,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rangeValues])

	const yScale = d3
		.scaleLinear()
		.domain([
			+range.max
				? +range.max
				: d3.max(data.channels, (d) => d.last_reading_value),
			+range.min
				? +range.min
				: d3.min(data.channels, (d) => d.last_reading_value),
		])
		.range([margin.top + TOP_LABEL_HEIGHT, (height * 3) / 4])

	const xScale = d3
		.scaleBand()
		.domain(data.channels.map((d) => d.field_name_origin!))
		.range([width * AXIS_WIDTH_FRACTION, width])
		.padding(0.15)

	const barHeight = (value) =>
		Math.abs(
			Math.max(
				margin.top + TOP_LABEL_HEIGHT,
				Math.min((height * 3) / 4, yScale(Math.max(0, value)))
			) -
				Math.max(
					margin.top + TOP_LABEL_HEIGHT,
					Math.min((height * 3) / 4, yScale(Math.min(0, value)))
				)
		)

	const adjustRange = () =>
		setRange((s) => {
			if (range.min && range.max)
				console.log({
					max: Math.max(s.min, s.max).toString(),
					min: Math.min(s.min, s.max),
				})
			return {
				max: Math.max(s.min, s.max),
				min: Math.min(s.min, s.max),
			}
		})

	return (
		<div className='flex h-full '>
			<div className='w-5/6' ref={container}>
				<svg width={width} height={height}>
					<text
						x={width / 2}
						y={5 + margin.top}
						textAnchor='middle'
						className='font-normal'
					>
						{data?.description ?? data?.name}
					</text>
					<g>
						{yScale.ticks().map((tickValue) => (
							<Tick
								key={tickValue}
								y={yScale(tickValue)}
								value={tickValue}
								symbol={data?.channels[0]?.parameter.symbol}
								x1={width * AXIS_WIDTH_FRACTION}
								x2={width}
								textX={(
									width * AXIS_WIDTH_FRACTION -
									3
								).toFixed(2)}
							></Tick>
						))}
					</g>

					{showRanges &&
						conditionGroup &&
						conditionGroup.conditions.map((c) => {
							const v1 =
								c.value_1 === '-inf'
									? -Infinity
									: c.value_1 === 'inf'
									? Infinity
									: +c.value_1
							const v2 =
								c.value_2 === '-inf'
									? -Infinity
									: c.value_2 === 'inf'
									? Infinity
									: +c.value_2
							let top = Math.max(v1, v2)
							let bottom = Math.min(v1, v2)

							const y1 = yScale(
								Math.max(Math.min(top, +range.max), +range.min)
							)
							const y2 = yScale(
								Math.max(
									Math.min(bottom, +range.max),
									+range.min
								)
							)

							return (
								<rect
									key={c.name}
									x={width * AXIS_WIDTH_FRACTION}
									width={width * (1 - AXIS_WIDTH_FRACTION)}
									y={y1}
									height={Math.abs(y1 - y2)}
									fill={c.color}
									opacity={0.2}
								></rect>
							)
						})}

					{data.channels
						.sort(
							(a, b) =>
								(parseInt(a.label.split('-')[1]) ?? 0) -
								(parseInt(b.label.split('-')[1]) ?? 0)
						)
						.map((d) => {
							const sensor = getSensorById(d.sensor)
							return (
								<g key={d.id}>
									{
										<Bar
											key={d.id}
											x={xScale(d.field_name_origin)}
											y={Math.max(
												margin.top + TOP_LABEL_HEIGHT,
												Math.min(
													(height * 3) / 4,
													yScale(
														Math.max(
															0,
															d.last_reading_value
														)
													)
												)
											)}
											height={barHeight(
												d.last_reading_value
											)}
											width={xScale.bandwidth()}
											color={
												sensor?.is_operative
													? d.last_condition?.color
													: sensor?.condition?.color
											}
											label={
												d.field_name_origin.split(
													'-'
												)[1]
											}
											inverted={d.last_reading_value < 0}
											value={d.last_reading_value}
											highlight={
												d.id === hoverChannel?.id
											}
										></Bar>
									}
									<text
										key={`${d.id}` + d.field_name_origin}
										x={xScale(d.field_name_origin) + 40}
										y={(height * 3) / 4 + margin.top}
										className='text-xs font-semibold text-gray-600'
										textAnchor='end'
										transform={`rotate(-45,${
											xScale(d.field_name_origin) + 40
										} ${(height * 3) / 4 + margin.top})`}
									>
										{d.label}
									</text>
								</g>
							)
						})}
				</svg>
			</div>
			<div className='w-1/6 m-2 p-1 shadow bg-gray-100 rounded'>
				<div className='p-1 border-gray-300 border rounded'>
					<div className='p-1'>
						<FormControlLabel
							control={
								<Checkbox
									color='primary'
									checked={centerFixed}
									onChange={(e) => {
										if (!range.min || !range.max) {
											center()
										}
										e.target.checked && center()
										setCenterFixed(e.target.checked)
									}}
								/>
							}
							label='Autoajuste Eje Y'
						></FormControlLabel>
					</div>
					<div className='flex'>
						<div className='p-1'>
							<TextField
								id='min'
								type='number'
								inputProps={{ step: '0.1' }}
								value={rangeValues.min ?? 0}
								disabled={centerFixed}
								onChange={(e) => {
									setRangeValues((s) => ({
										...s,
										min: e.target.value,
									}))
									adjustRange()
								}}
								label='Y Min'
								variant='outlined'
								InputLabelProps={{
									shrink: true,
								}}
							></TextField>
						</div>
						<div className='p-1'>
							<TextField
								id='max'
								type='number'
								inputProps={{ step: '0.1' }}
								value={rangeValues.max ?? 0}
								onChange={(e) => {
									setRangeValues((s) => ({
										...s,
										max: e.target.value,
									}))
									adjustRange()
								}}
								disabled={centerFixed}
								variant='outlined'
								label='Y Max'
								InputLabelProps={{
									shrink: true,
								}}
							></TextField>
						</div>
					</div>
				</div>
				<div className='p-1 mt-1 border-gray-300 border rounded'>
					<div className='p-1'>
						<FormControlLabel
							control={
								<Checkbox
									color='primary'
									checked={showRanges}
									onChange={(e) => {
										if (!range.min || !range.max) {
											center()
										}
										setShowRanges(e.target.checked)
									}}
								/>
							}
							label='Mostrar Umbrales'
						></FormControlLabel>
					</div>
				</div>
			</div>
		</div>
	)
}
export default DeltaProfileChart
