import moment from "moment-timezone"
import { TimeActions } from "../reducers/TimeReducer"
import store from "../store"

export const setTimeZone = (timeZone: string) => {
    store.dispatch({ type: TimeActions.SetTimeZone, payload: timeZone })
    moment.tz.setDefault(timeZone)
}

export const loadTimeZones = async () => {
    const timeZones = moment.tz.names()
    store.dispatch({ type: TimeActions.SetTimeZones, payload: timeZones })
}