import { Card, CardContent } from "@material-ui/core"
import { mdiTarget, mdiEye, mdiEyeOff, mdiAutorenew } from "@mdi/js"
import Icon from "@mdi/react"
import { useEffect } from "react"
import { useAppSelector } from "../../../../hooks/storeHooks"
import { centerMap } from "../../../../services/map"
import {
    removeProjectUnit,
    selectProjectUnit,
} from "../../../../services/projectUnit"
import { getSensorsByProjectUnit } from "../../../../services/sensor"
import SensorCardLine from "./SensorCardLine"

interface Props {
    projectUnit: ProjectUnit
}

const ProjectUnitCard: React.FC<Props> = ({ projectUnit }) => {
    const isSelected = useAppSelector((s) =>
        s.projectReducer.selectedProjectUnits.some(
            (p) => p.id === projectUnit.id
        )
    )

    const sensors = useAppSelector(
        (s) => s.sensorReducer.sensorsByProjectUnit[projectUnit.id] ?? []
    )

    const isLoading = useAppSelector(
        (s) =>
            !s.sensorReducer.sensorsByProjectUnit[projectUnit.id]?.length &&
            (!!s.sensorReducer.isLoading[projectUnit.id] ?? false)
    )
    useEffect(() => {
        getSensorsByProjectUnit(projectUnit)
        selectProjectUnit(projectUnit)
        return () => {
            removeProjectUnit(projectUnit)
        }
    }, [projectUnit])

    return (
        <Card className="text-xs mb-4">
            <div className="flex h-8 items-center">
                <div
                    className={`${
                        isSelected ? "bg-green-500" : "bg-gray-200"
                    } w-8 flex flex-col p-1 cursor-pointer`}
                    onClick={() => {
                        isSelected
                            ? removeProjectUnit(projectUnit)
                            : selectProjectUnit(projectUnit)
                    }}
                >
                    <Icon
                        color={isSelected ? "white" : "#AAA"}
                        path={isSelected ? mdiEye : mdiEyeOff}
                        size={1}
                    ></Icon>
                </div>
                <div className="p-3 overflow-ellipsis">{projectUnit.name}</div>
                <div className="flex-1"></div>
                <div
                    className=" flex flex-col mr-3 h-12 p-1 w-7 justify-center cursor-pointer"
                    onClick={() =>
                        centerMap({
                            lat: projectUnit.localization.position
                                .coordinates[1],
                            lng: projectUnit.localization.position
                                .coordinates[0],
                        })
                    }
                >
                    <Icon color="black" path={mdiTarget}></Icon>
                </div>
            </div>
            <CardContent
                style={{ backgroundColor: "#EDEDED" }}
                className="overflow-auto"
            >
                {isLoading ? (
                    <div className="w-full flex justify-center">
                        <Icon
                            path={mdiAutorenew}
                            color={"#AAA"}
                            className="w-7 m-auto"
                            spin
                        ></Icon>
                    </div>
                ) : !!sensors?.length ? (
                    sensors.sort((a,b) => (+a.label.split('-')[2] > +b.label.split('-')[2]) ? 1 : ((+b.label.split('-')[2] > +a.label.split('-')[2]) ? -1 : 0)).map((s) => <SensorCardLine key={s.id} sensor={s} />)
                ) : (
                    isSelected && <div>Los datos se sincronizaran en breve</div>
                )}
            </CardContent>
        </Card>
    )
}
export default ProjectUnitCard
