import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Table,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core'
import {
	mdiArrowCollapseVertical,
	mdiFolderDownload,
	mdiTableLargePlus,
} from '@mdi/js'
import Icon from '@mdi/react'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Permissions } from '../../config/Permissions'
import { Routes } from '../../config/Routes'
import { useAppSelector } from '../../hooks/storeHooks'
import { fetchSensors } from '../../services/sensor'
import useStyles from '../../styles/appStyles'
import DeleteButton from '../Shared/DeleteButton'
import QuestionModal from '../Shared/QuestionModal'
import ChannelFormModal from './ChannelFormModal'
import SensorFormModal from './SensorFormModal'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getChannels, resetVirtualDelta } from '../../services/channel'
import { ParameterIcons } from '../Utilities/ParameterIcons'
import DownloadModal from './DownloadModal'
import { getDataloggers } from '../../services/datalogger'
import DeleteSensorModal from './DeleteSensorModal'
import { withError } from '../../services/snackbarNotification'

const InventoryContainer: React.FC = () => {
	const classes = useStyles(true)()
	const sensors = useAppSelector((s) => s.sensorReducer.sensors)
	const dataloggers = useAppSelector((s) => s.dataloggerReducer.dataloggers)
	const channels = useAppSelector((s) => s.channelReducer.channels)
	const permissions = useAppSelector((s) => s.authReducer.permissions)
	const isEditing = useAppSelector((s) => s.authReducer.isEditing)
	const [targetSensor, setTargetSensor] = useState<Sensor>(null)
	const [targetChannel, setTargetChannel] = useState<Channel>(null)
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [downloadModalOpen, setDownloadModalOpen] = useState(false)
	const [resetModalOpen, setResetModalOpen] = useState(false)
	const [newSensorModalOpen, setNewSensorModalOpen] = useState(false)
	const [newChannelModalOpen, setNewChannelModalOpen] = useState(false)

	useEffect(() => {
		getDataloggers()
		fetchSensors()
	}, [channels])

	if (!permissions.find((p) => p === Permissions.ViewInventory))
		return <Redirect to={Routes.Monitoring}></Redirect>

	return (
		<div className={`${classes.bodyContent} p-4 overflow-auto`}>
			<div className='text-center font-medium mb-5'>
				Sensores{' '}
				{isEditing && (
					<Button
						variant='contained'
						color='primary'
						className='float-right'
						onClick={() => setNewSensorModalOpen(true)}
					>
						Nuevo
					</Button>
				)}
			</div>
			{dataloggers.map((datalogger) => (<>
				<div style={{ width: '100%' }}>
					<div style={{ display: "inline-block", fontSize: "1.2em", width: "30%" }}>Gateway {datalogger.installation.equipment.serial_number}</div>
				</div>
				{/* <div style={{ width: '100%' }}>
					<div style={{ display: "inline-block", fontSize: "1.2em", width: "30%" }}>Sensor</div>
					<div style={{ display: "inline-block", fontSize: "1.2em", width: "30%" }}>Coordenadas</div>
					<div style={{ display: "inline-block", fontSize: "1.2em", width: "25%" }}>Número de serie</div>
					{isEditing && (
						<div style={{ display: "inline-block", fontSize: "1.2em" }}>
							Acciones
						</div>
					)}
				</div> */}
				{sensors.filter((s) => s.datalogger === datalogger.id)
					.sort(
						(a, b) =>
							parseInt(a.label.split('-').pop()) -
							parseInt(b.label.split('-').pop())
					)
					.map((s) => (<>
						<Accordion style={{ width: '100%' }}>
							<AccordionSummary className={'w-full'}
								expandIcon={<ExpandMoreIcon />}
								aria-label="Expand">
								<div key={s.label} style={{ width: '100%' }}>
									<div style={{ display: "inline-block", width: "30%" }}>Sensor {s.label}</div>
									<div style={{ display: "inline-block", width: "35%" }}>{`Este: ${s.installation.localization.position.coordinates[0]} Norte: ${s.installation.localization.position.coordinates[1]}`}</div>
									<div style={{ display: "inline-block", width: "20%" }}>
										{s.installation.equipment.serial_number}
									</div>
									{isEditing && (
										<div style={{ display: "inline-block", width: "15%", textAlign: "center" }}>
											<div style={{ display: "inline-block", width: "33.33%" }}
												onClick={() => {
													setTargetSensor(s)
													setDownloadModalOpen(true)
												}}
											>
												<Icon
													className="w-5 h-5 text-center m-auto"
													path={mdiFolderDownload}
													title='Exportar todos los datos'

												></Icon>
											</div>
											<div style={{ display: "inline-block", width: "33.33%" }}
												onClick={() => {
													setTargetSensor(s)
													setNewChannelModalOpen(true)
												}}
											>
												<Icon
													className="w-5 h-5 text-center m-auto"
													path={mdiTableLargePlus}
													title='Añadir canal virtual'

												></Icon>
											</div>
											<div style={{ display: "inline-block", width: "33.33%" }}
												title='Eliminar Sensor'
											>
												<DeleteButton
													onClick={() => {
														setTargetSensor(s)
														setDeleteModalOpen(true)
													}}
												></DeleteButton>
											</div>
										</div>
									)}
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<div style={{ display: "inline-block", width: "100%" }}>
									<Table className='w-full'>
										<TableHead>
											<TableRow className={'bg-gray-300'}>
												<TableCell>Id</TableCell>
												<TableCell>Canal</TableCell>
												<TableCell>Principal</TableCell>
												<TableCell>Icono</TableCell>
												<TableCell>Unidad</TableCell>
												<TableCell>Formula</TableCell>
												<TableCell>Acciones</TableCell>
											</TableRow>
										</TableHead>
										{
											s.channels.map((channel) => {
												const obj = channels.find(o => o.id === channel);
												return obj ? (
													<TableRow>
														<TableCell>{obj.id}
														</TableCell>
														<TableCell>{obj.label}
														</TableCell>
														<TableCell>{obj.id === s.default_channel?.id ? 'Si' : ''}
														</TableCell>
														<TableCell>
															<Icon
																className="w-5"
																path={
																	ParameterIcons[
																	obj.parameter?.icon || "default"
																	]
																}
																color="#777"
															></Icon>
														</TableCell>
														<TableCell>{obj.parameter.unit}
														</TableCell>
														{obj.is_virtual && (<>
															<TableCell>
																{obj.function.formula}
															</TableCell>
															<TableCell>
																{isEditing && (<>
																	<div
																		style={{ display: "inline-block", width: "50%" }}
																		onClick={() => {
																			setTargetChannel(obj)
																			setResetModalOpen(true)
																		}}>
																		<Icon
																			className="transition-all w-5"
																			path={mdiArrowCollapseVertical}
																			title='Resetear canal'
																		></Icon>
																	</div>
																</>
																)}
															</TableCell>
														</>)}
														{!obj.is_virtual && (<>
															<TableCell>
															</TableCell>
															<TableCell>
															</TableCell>
														</>)}
													</TableRow>
												) : (null)
											})
										}
									</Table>
								</div>
							</AccordionDetails>
						</Accordion>
					</>
					))}
				<br />
			</>))}
			{/* <QuestionModal
				onYes={async () => {
					await deleteSensor(targetSensor)
					fetchSensors()
					getDataloggers()
					getChannels()
				}}
				onClose={() => setDeleteModalOpen(false)}
				open={deleteModalOpen}
				text={`Seguro que desea eliminar el sensor ${targetSensor?.label} ?\n\n Si no descarga antes los datos no se podrán recuperar.`}
			></QuestionModal> */}
			<DeleteSensorModal
				onClose={() => {
					setTargetSensor(null)
					setDeleteModalOpen(false)
				}}
				open={deleteModalOpen}
				targetSensor={targetSensor}>
			</DeleteSensorModal>

			<QuestionModal
				onYes={withError(async () => {
					await resetVirtualDelta(targetChannel)
					getChannels()
				})}
				onClose={() => setResetModalOpen(false)}
				open={resetModalOpen}
				text={`Seguro que desea resetear el canal ${targetChannel?.label} ?`}
			></QuestionModal>
			<SensorFormModal
				onClose={() => {
					getChannels()
					setNewSensorModalOpen(false)
				}}
				open={newSensorModalOpen}
			></SensorFormModal>
			<ChannelFormModal
				sensor={targetSensor}
				onClose={() => setNewChannelModalOpen(false)}
				open={newChannelModalOpen}
			></ChannelFormModal>
			<DownloadModal
				open={downloadModalOpen}
				onClose={() => {
					setDownloadModalOpen(false)
				}}
				sensor={targetSensor}>
			</DownloadModal>
		</div>
	)
}
export default InventoryContainer
