import {
    Dialog,
    DialogContentText,
    DialogTitle,
    DialogContent,
    Button,
    DialogActions,
    makeStyles,
    Theme,
    createStyles,
} from "@material-ui/core"
import { useEffect, useState } from "react"
import { pauseAlert, resolveAlert } from "../../services/alert"
import { useChannelById } from "../../services/channel"
import { useSensorById } from "../../services/sensor"

import { TextField } from "@material-ui/core"
import moment from "moment-timezone"
import Spinner from "../Shared/Spinner"
import { useAppSelector } from "../../hooks/storeHooks"
import { withError } from "../../services/snackbarNotification"

interface Props {
    alertStatus: AlertStatus
    open: boolean
    onClose?: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void
}

const useStyles = (color: string) =>
    makeStyles((theme: Theme) =>
        createStyles({
            root: { backgroundColor: color },
        })
    )

const AlertModal: React.FC<Props> = ({ alertStatus, open, onClose }) => {
    const sensor = useSensorById(alertStatus.sensor)
    const channel = useChannelById(alertStatus.channel)
    const [loading, setLoading] = useState(false)
    const classes = useStyles(alertStatus.condition.color)()
    const [comment, setComment] = useState(null)
    const timeZone = useAppSelector((s) => s.timeReducer.timeZone)
    useEffect(() => {
        new Audio("assets/sounds/sonar.wav").play()
    }, [alertStatus])

    const getValue = (row) => {
        // console.log(row)
        if (row.condition.condition_type === "time") {
            // alert()
            return moment
                .duration(
                    row.value,
                    "seconds"
                )
                .humanize()
        } else {
            return row.value
        }
    }

    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
            <DialogTitle classes={{ root: classes.root }}>
                <div className="flex">
                    <>Alerta {channel?.label}</>
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {!!sensor && <div>Sensor: {sensor.label}</div>}
                    {!!channel && <div>Canal: {channel.label}</div>}
                    <div>Hora: {moment(alertStatus.update).tz(timeZone).format("HH:mm")}</div>
                    <div>Condición: {alertStatus.condition.name}</div>
                    <div>Estado: {alertStatus.status}</div>
                    <div>Valor: {getValue(alertStatus)}</div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <div className="ml-1">
                            <TextField
                                className="ml-1"
                                id="alert_comment"
                                type="text"
                                value={comment}
                                onChange={(e) => {
                                    setComment(e.target.value)
                                }}
                                disabled={false}
                                variant="outlined"
                                label="Comentario"
                            // InputLabelProps={{
                            //     shrink: true,
                            // }}
                            ></TextField>
                        </div>
                        <Button
                            onClick={withError(() => {
                                setLoading(true)
                                pauseAlert(alertStatus, comment).then(() =>
                                    setLoading(false)
                                )
                                setComment("")
                            })}
                            color="primary"
                            disabled={loading || !comment || comment.length < 5}
                        >
                            Pausar
                        </Button>
                        <Button
                            onClick={withError(async () => {
                                setLoading(true)
                                resolveAlert(alertStatus, comment).then(() =>
                                    setLoading(false)
                                )
                                setComment("")
                            })}
                            color="primary"
                            disabled={loading || !comment || comment.length < 5}
                        >
                            Resolver
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    )
}
export default AlertModal
