import { Reducer } from "react";

interface State {
    isLoading: boolean,
    channels: Channel[],
    selectedChannel: Channel
    selectedChannels: Channel[]
    hoverChannel: Channel
}

const initialState: State = {
    isLoading: false,
    channels: [],
    selectedChannels: [],
    selectedChannel: null,
    hoverChannel: null
}

export enum ChannelActions {
    RequestChannels = 'REQUEST_CHANNELS',
    SetChannels = 'SET_CHANNELS',
    AddSelectedChannel = 'ADD_SELECTED_CHANNEL',
    RemoveSelectedChannel = 'REMOVE_SELECTED_CHANNEL',
    SetSelectedChannel = 'SET_SELECTED_CHANNEL',
    SetHoverChannel = 'SET_HOVER_CHANNEL',
}

const reducer: Reducer<State, { type: ChannelActions, payload: any }> = (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case ChannelActions.RequestChannels:
            return { ...state, isLoading: true }
        case ChannelActions.SetChannels:
            return { ...state, isLoading: false, channels: payload }
        case ChannelActions.SetSelectedChannel:
            return { ...state, selectedChannel: payload }
        case ChannelActions.SetHoverChannel:
            return { ...state, hoverChannel: payload }
        case ChannelActions.AddSelectedChannel:
            return { ...state, selectedChannels: !!state.selectedChannels.find(sc => sc.id === payload.id) ? state.selectedChannels : [...state.selectedChannels, payload] }
        case ChannelActions.RemoveSelectedChannel:
            return { ...state, selectedChannels: state.selectedChannels.filter(sc => sc.id !== payload.id) }
        default:
            return state;
    }
}

export default reducer