import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    MenuItem,
    Select,
} from "@material-ui/core"
import React from "react"
import { useAppSelector } from "../../../hooks/storeHooks"
import { useForm } from "../../../hooks/useForm"
import { getChannels } from "../../../services/channel"
import {
    assignChannel,
    getConditionGroups,
} from "../../../services/conditionGroup"
import { addSnackbarNotification, withError } from "../../../services/snackbarNotification"

interface Props {
    open: boolean
    onClose?: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void
}

const AddChannelToConditionGroupModal: React.FC<Props> = ({
    open,
    onClose,
}) => {
    const conditionGroup = useAppSelector(
        (s) => s.conditionGroupReducer.selectedConditionGroup
    )
    const channels = useAppSelector((s) => s.channelReducer.channels)
    const [values, , handleChange] = useForm<{ channel: number }>({
        channel: 0,
    })
    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose()
            }}
            maxWidth="xs"
            fullWidth
        >
            <DialogContent>
                <Select
                    onChange={handleChange}
                    id="channel"
                    name="channel"
                    value={values.channel}
                    fullWidth
                >
                    <MenuItem value={0}>Seleccione un canal</MenuItem>
                    {channels
                        .filter(
                            (ch) =>
                                !conditionGroup?.channels.find(
                                    (cgc) => cgc === ch.id
                                )
                        )
                        .map((ch) => (
                            <MenuItem key={ch.id} value={ch.id}>
                                {ch.label}
                            </MenuItem>
                        ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={withError(async () => {
                        await assignChannel(
                            channels.find((ch) => ch.id === values.channel),
                            conditionGroup
                        )
                        addSnackbarNotification({
                            message: "Canal asignado",
                            type: "success",
                        })
                        getConditionGroups()
                        getChannels()
                        onClose()
                    })}
                    variant="contained"
                >
                    Agregar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default AddChannelToConditionGroupModal
