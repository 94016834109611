import "./App.css"
import "./style.css"
import React, { useEffect } from "react"

import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom"
import TopBar from "./components/TopBar/TopBar"
import FunctionsMenu from "./components/FunctionsMenu/FunctionsMenu"
import AlertsContainer from "./components/AlertsContainer/AlertsContainer"
import MonitoringContainer from "./components/MonitoringContainer/MonitoringContainer"
import useStyles from "./styles/appStyles"
import { useAppSelector } from "./hooks/storeHooks"
import ProtectedRoute from "./components/Utilities/ProtectedRoute"
import Login from "./components/Login/Login"
import AnalysisContainer from "./components/AnalysisContainer/AnalysisContainer"
import Theme from "./components/Utilities/Theme"
import MomentUtils from "@date-io/moment"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DocumentContainer from "./components/DocumentsContainer/DocumentContainer"
import InventoryContainer from "./components/InventoryContainer/InventoryContainer"
import { Routes } from "./config/Routes"
import SnackbarNotificationContainer from "./components/SnackbarNotificationsContainer/SnackbarNotificationContainer"
import MultiChannelContainer from "./components/MultiChannelContainer/MultiChannelContainer"
import { getSettings } from "./services/setting"
import FileRepository from "./components/FileRepository/FileRepository"
import { getPermissions } from "./services/auth"

const App: React.FC = () => {
    const isAuthenticated = useAppSelector((s) => !!s.authReducer.token)
    const isLoading = useAppSelector((s) => !!s.authReducer.isLoading)
    const classes = useStyles(isAuthenticated)()

    useEffect(() => {
        isAuthenticated && getSettings()
        isAuthenticated && getPermissions()
    }, [isAuthenticated])

    return (
        <Theme>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <div className={classes.root}>
                    <BrowserRouter>
                        {!isAuthenticated && <Redirect to={Routes.Login} />}
                        {isAuthenticated && <TopBar />}
                        {isAuthenticated && <FunctionsMenu />}
                        <div className={classes.content}>
                            <div
                                className={
                                    isAuthenticated ? classes.toolbar : ""
                                }
                            ></div>
                            <Switch>
                                <ProtectedRoute path={Routes.Monitoring} exact>
                                    <MonitoringContainer />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    path={Routes.MultiChannel}
                                    exact
                                >
                                    <MultiChannelContainer />
                                </ProtectedRoute>
                                <ProtectedRoute path={Routes.Alerts} exact>
                                    <AlertsContainer />
                                </ProtectedRoute>
                                <ProtectedRoute path={Routes.Analysis} exact>
                                    <AnalysisContainer />
                                </ProtectedRoute>
                                <ProtectedRoute path={Routes.Documents} exact>
                                    <DocumentContainer />
                                </ProtectedRoute>
                                <ProtectedRoute path={Routes.Inventory} exact>
                                    <InventoryContainer />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    path={Routes.FileRepository}
                                    exact
                                >
                                    <FileRepository></FileRepository>
                                </ProtectedRoute>
                                <Route exact path={Routes.Login}>
                                    <Login />
                                </Route>
                            </Switch>
                        </div>
                    </BrowserRouter>
                </div>
                <SnackbarNotificationContainer></SnackbarNotificationContainer>
            </MuiPickersUtilsProvider>
        </Theme>
    )
}

export default App
