import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
} from '@material-ui/core'
import { mdiFileUpload } from '@mdi/js'
import { useEffect, useRef, useState } from 'react'
import { useForm } from '../../hooks/useForm'
import { fetchRepositoryElements, fetchRootNodes, uploadFile } from '../../services/repositoryElement'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
	parent: RepositoryElement
}

const UploadFileModal: React.FC<Props> = ({ open, onClose, parent }) => {
	const [values, setValues, handleChange] = useForm({
		name: '',
		parent: { name: parent.name, id: parent.id, full_path: parent.full_path },
		allow_upload: true,
	})
	const inputFile = useRef<HTMLInputElement>(null)
	const [file, setFile] = useState<File>(null)


	useEffect(() => {
		setValues({ ...values, parent: { name: parent.name, id: parent.id, full_path: parent.full_path } })
	}, [parent]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
			<DialogContent className='p-2'>
				<div className='w-full'>
					<div className='w-full text-lg'>
						Subir archivo a {values.parent.full_path + '/' + values.parent.name + '/'}
					</div>
					<div className='w-full'>
						<TextField
							id='name'
							fullWidth
							color='primary'
							label='Nombre'
							size='medium'
							value={values.name}
							onChange={handleChange}
						/>
						<Button
							variant='outlined'
							onClick={() => {
								inputFile.current?.click()
							}}
						// className='p-2 rounded-xl text-center space-x-2  border h-full font-semibold uppercase text-blue-800 cursor-pointer border-blue-500 border-dashed flex justify-center items-center'
						>
							Seleccione un Archivo
							<div className='w-10'>
								<img src={mdiFileUpload} alt=''></img>
							</div>
							<input
								type='file'
								name='file'
								id='file'
								style={{ display: 'none' }}
								ref={inputFile}
								onChange={(e) => setFile(e.target.files[0])}
							// {...getInputProps()}
							/>
						</Button>
						{file && JSON.stringify(file)}
						<br />
						{file && file.name}
					</div>
					<div className='w-full'>
						<TextField
							id='parent'
							fullWidth
							color='primary'
							label='Ruta'
							size='medium'
							value={values.parent.full_path + '/' + values.parent.name}
							onChange={handleChange}
							disabled={true}
						/>
						{/* <TextField
							id='id'
							fullWidth
							color='primary'
							label='Id'
							size='medium'
							value={values.parent.id}
							onChange={handleChange}
							disabled={true}
						/> */}
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					color='primary'
					className='m-2'
					variant='contained'
					onClick={async () => {
						const newElement = {
							name: values.name,
							parent: values.parent.id,
							allow_upload: values.allow_upload,
							element_type: 'f' as const,
							file_content: file
						}
						const val = await uploadFile(newElement)
						if (val) {
							fetchRepositoryElements()
							fetchRootNodes()
							onClose()
						}
					}}
				>
					Guardar
				</Button>
			</DialogActions>
		</Dialog>
	)
}
export default UploadFileModal
