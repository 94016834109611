import { useEffect } from "react"
import { useFetch } from "../../../hooks/useFetch"
import Spinner from "../../Shared/Spinner"
import AlertLogTable from "./AlertLogTable"

interface Props {
    channel: Channel
}

const ConditionGroupHistory: React.FC<Props> = ({ channel }) => {
    const [alertLogs, loading, fetch] = useFetch<AlertStatus[]>(
        channel
            ? process.env.REACT_APP_API_URL +
            `alert_logs/?channel=${channel?.id}`
            : ""
    )

    const logs = channel
        ? alertLogs?.filter((al) => al.channel === channel.id)
        : null

    useEffect(fetch, [channel])

    return loading ? (
        <div>
            <Spinner></Spinner>
        </div>
    ) : (
        <>
            {logs?.length ? (
                <AlertLogTable alertLogs={logs}></AlertLogTable>
            ) : (
                <div className="text-lg text-gray-600 text-center">
                    Sin registros
                </div>
            )}
        </>
    )
}
export default ConditionGroupHistory
