import React from "react"
import Grid from "@material-ui/core/Grid"
import Tooltip from "@material-ui/core/Tooltip"
import Drawer from "@material-ui/core/Drawer"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Icon from "@mdi/react"
import GeoSinergiaLogo from "../../assets/images/GeoSinergiaLogo.svg"
import useStyles from "../../styles/appStyles"

import {
    mdiToyBrickMarkerOutline,
    mdiAlertBoxOutline,
    mdiChartBar,
    mdiExitToApp,
    mdiClipboardText,
    mdiChartAreaspline,
    mdiFileStar,
} from "@mdi/js"

import { NavLink } from "react-router-dom"
import { logout } from "../../services/auth"
import { useAppSelector } from "../../hooks/storeHooks"
import { Permissions } from "../../config/Permissions"
import { Routes } from "../../config/Routes"

const FunctionsMenu: React.FC = () => {
    const classes = useStyles(true)()

    const permissions = useAppSelector((s) => s.authReducer.permissions)

    return (
        <>
            <Drawer
                variant="permanent"
                anchor="left"
                open
                className={`drawerstyle ${classes.drawer}`}
            >
                <div
                    className={`${classes.toolbarContent} flex flex-col justify-center`}
                >
                    <img
                        src={GeoSinergiaLogo}
                        className="m-auto"
                        width="40px"
                        alt="Geosinergia"
                    />
                </div>
                <Divider />
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="stretch"
                    style={{ height: "100%" }}
                >
                    <Grid item>
                        <List component="nav" aria-label="main mailbox folders">
                            <Tooltip
                                title="MONITOREO"
                                placement="right"
                                aria-label="add"
                            >
                                <ListItem
                                    button
                                    key="li_monit"
                                    component={NavLink}
                                    to="/"
                                >
                                    <ListItemIcon className="f_menu_icon">
                                        <Icon
                                            path={mdiToyBrickMarkerOutline}
                                            title=""
                                            size={1}
                                            color="white"
                                        />
                                    </ListItemIcon>
                                </ListItem>
                            </Tooltip>
                            {!!permissions.find(
                                (p) => p === Permissions.ViewAlerts
                            ) && (
                                    <Tooltip
                                        title="ALERTAS"
                                        placement="right"
                                        aria-label="add"
                                    >
                                        <ListItem
                                            button
                                            key="li_alerts"
                                            component={NavLink}
                                            to="/alerts"
                                        >
                                            <ListItemIcon className="f_menu_icon">
                                                <Icon
                                                    path={mdiAlertBoxOutline}
                                                    title=""
                                                    size={1}
                                                    color="white"
                                                />
                                            </ListItemIcon>
                                        </ListItem>
                                    </Tooltip>
                                )}
                            {!!permissions.find(
                                (p) => p === Permissions.ViewAnalisys
                            ) && (
                                    <Tooltip
                                        title="ANALISIS"
                                        placement="right"
                                        aria-label="add"
                                    >
                                        <ListItem
                                            button
                                            key="li_alerts"
                                            component={NavLink}
                                            to="/analysis"
                                        >
                                            <ListItemIcon className="f_menu_icon">
                                                <Icon
                                                    path={mdiChartBar}
                                                    title=""
                                                    size={1}
                                                    color="white"
                                                />
                                            </ListItemIcon>
                                        </ListItem>
                                    </Tooltip>
                                )}
                            {/* <Tooltip
                                title="REPORTES"
                                placement="right"
                                aria-label="add"
                            >
                                    <ListItem 
                                    button
                                    key="li_alerts"
                                    component={NavLink}
                                    to="/reports">
                                        <ListItemIcon className="f_menu_icon">
                                            <Icon
                                                path={mdiTextBoxOutline}
                                                title=""
                                                size={1}
                                                color="white"
                                            />
                                        </ListItemIcon>
                                    </ListItem>
                            </Tooltip> */}
                            {/* <Tooltip
                                title="DOCUMENTOS"
                                placement="right"
                                aria-label="add"
                            >
                                <ListItem
                                    button
                                    key="li_alerts"
                                    component={NavLink}
                                    to="/documents"
                                >
                                    <ListItemIcon className="f_menu_icon">
                                        <Icon
                                            path={mdiBookshelf}
                                            title=""
                                            size={1}
                                            color="white"
                                        />
                                    </ListItemIcon>
                                </ListItem>
                            </Tooltip> */}
                            {permissions.find(
                                (p) => p === Permissions.ViewMultiChannel
                            ) && (
                                    <Tooltip
                                        title="MultiChannel"
                                        placement="right"
                                        aria-label="add"
                                    >
                                        <ListItem
                                            button
                                            key="li_multichannel"
                                            component={NavLink}
                                            to={Routes.MultiChannel}
                                        >
                                            <ListItemIcon className="f_menu_icon">
                                                <Icon
                                                    path={mdiChartAreaspline}
                                                    title=""
                                                    size={1}
                                                    color="white"
                                                />
                                            </ListItemIcon>
                                        </ListItem>
                                    </Tooltip>
                                )}
                            {!!permissions.find(
                                (p) => p === Permissions.ViewInventory
                            ) && (
                                    <Tooltip
                                        title="INVENTARIO"
                                        placement="right"
                                        aria-label="add"
                                    >
                                        <ListItem
                                            button
                                            key="li_inventory"
                                            component={NavLink}
                                            to="/inventory"
                                        >
                                            <ListItemIcon className="f_menu_icon">
                                                <Icon
                                                    path={mdiClipboardText}
                                                    title=""
                                                    size={1}
                                                    color="white"
                                                />
                                            </ListItemIcon>
                                        </ListItem>
                                    </Tooltip>
                                )}
                            {!!permissions.find(
                                (p) => p === Permissions.ViewFileRepository
                            ) && (
                                    <Tooltip
                                        title="REPOSITORIO"
                                        placement="right"
                                        aria-label="add"
                                    >
                                        <ListItem
                                            button
                                            key="li_inventory"
                                            component={NavLink}
                                            to={Routes.FileRepository}
                                        >
                                            <ListItemIcon className="f_menu_icon">
                                                <Icon
                                                    path={mdiFileStar}
                                                    title=""
                                                    size={1}
                                                    color="white"
                                                />
                                            </ListItemIcon>
                                        </ListItem>
                                    </Tooltip>
                                )}
                        </List>
                    </Grid>
                    <Grid item>
                        <Tooltip
                            title="CERRAR SESIÓN"
                            placement="right"
                            aria-label="add"
                            onClick={() => logout()}
                        >
                            <ListItem button>
                                <ListItemIcon className="f_menu_icon">
                                    <Icon
                                        path={mdiExitToApp}
                                        title=""
                                        size={1}
                                        color="white"
                                        className="drawerIcon"
                                    />
                                </ListItemIcon>
                            </ListItem>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Drawer>
        </>
    )
}
export default FunctionsMenu
