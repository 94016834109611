import { Button } from "@material-ui/core"
import { withError } from "../../../../services/snackbarNotification"


interface Props {
	label: string
	saveCurrentView: Function
}

const SaveMapButton: React.FC<Props> = ({ label, saveCurrentView }) => {
	return (
		<div
			className='bg-gray-50 rounded font-bold text-xs p-2'
			style={{
				position: 'absolute',
				right: 200,
				top: 10,
				zIndex: 10000,
			}}
		>
			<div className='text-center p-1 text-base'>Vista</div>
			<div>
				<Button
					color='primary'
					variant='contained'
					className='mr-2'
					onClick={withError(() =>
						saveCurrentView())
					}
				>
					{label}
				</Button>
			</div>
		</div>
	)
}
export default SaveMapButton
