import { Reducer } from "react";

interface State {
    timeZone: string
    timeZones: string[]
}

const initialState: State = {
    timeZone: process.env.REACT_APP_DEFAULT_TIMEZONE || 'UTC',
    timeZones: []
}

export enum TimeActions {
    SetTimeZone = 'SET_TIME_ZONE',
    SetTimeZones = 'SET_TIME_ZONES',
}

const reducer: Reducer<State, { type: TimeActions, payload: any }> = (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case TimeActions.SetTimeZone:
            return { ...state, timeZone: payload }
        case TimeActions.SetTimeZones:
            return { ...state, timeZones: payload }
        default:
            return state;
    }
}

export default reducer