import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core"
import moment from "moment-timezone"
import { useAppSelector } from "../../../hooks/storeHooks"
import { getUserById } from "../../../services/auth"

interface Props {
    alertLogs: AlertStatus[]
}

const AlertLogTable: React.FC<Props> = ({ alertLogs }) => {
    const channel = useAppSelector((s) => s.channelReducer.selectedChannel)
    const timeZone = useAppSelector((s) => s.timeReducer.timeZone)
    return (
        <>
            {channel && (
                <div className="text-center text-lg text-gray-600 mb-2">
                    {`Historial de alertas del canal ${channel?.label}`}
                </div>
            )}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Fecha</TableCell>
                            <TableCell>Hora</TableCell>
                            <TableCell>Condición</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell>Estado</TableCell>
                            <TableCell>Usuario</TableCell>
                            <TableCell>Comentario</TableCell>
                            <TableCell>Color</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {alertLogs.map((alertLog) => {
                            const time = moment(alertLog.update).tz(timeZone)
                            return (
                                <TableRow key={alertLog.id}>
                                    <TableCell>
                                        {time.format("DD-MM-YYYY")}
                                    </TableCell>
                                    <TableCell>
                                        {time.format("HH:mm")}
                                    </TableCell>
                                    <TableCell>
                                        {alertLog.condition.name}
                                    </TableCell>
                                    <TableCell>{alertLog.value}</TableCell>
                                    <TableCell>{alertLog.status}</TableCell>
                                    <TableCell>
                                        {getUserById(alertLog.user)?.username}
                                    </TableCell>
                                    <TableCell>{alertLog.comment}</TableCell>
                                    <TableCell>
                                        {" "}
                                        <div
                                            className="w-5 border  h-5 shadow rounded-full"
                                            style={{
                                                backgroundColor:
                                                    alertLog.condition.color ??
                                                    "#FFF",
                                            }}
                                        ></div>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
export default AlertLogTable
