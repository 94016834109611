import store from '../store'
import { SensorActions } from '../reducers/SensorReducer'
import axios from 'axios'
import { authHeader } from './auth'
import { useAppSelector } from '../hooks/storeHooks'
import { addSnackbarNotification } from './snackbarNotification'
const API_URL = process.env.REACT_APP_API_URL

let live_reload = true
let interval = null
const REFRESH_TIME = +process.env.REACT_APP_SENSOR_MONITORING_REFRESH_TIME

export const getSensorsByProjectUnit = async (projectUnit: ProjectUnit) => {
	try {
		store?.dispatch({
			type: SensorActions.RequestSensors,
			payload: { projectUnitId: projectUnit?.id },
		})
		const response = await axios.get(
			API_URL + `project_units/${projectUnit.id}/sensors/`,
			{ headers: authHeader() }
		)
		store.dispatch({
			type: SensorActions.SetProjectUnitSensors,
			payload: {
				projectUnitId: projectUnit.id,
				sensors: response.data.sort((a, b) => a.id - b.id),
			},
		})
	} catch (e) {
	}
}

export const setSelectedSensor = async (sensor: Sensor) => {
	store.dispatch({ type: SensorActions.SetSelectedSensor, payload: sensor })
}

export const syncSensors = () => {
	live_reload = true
	if (interval) clearInterval(interval)
	interval = setInterval(() => {
		if (live_reload) {
			store
				.getState()
				.projectReducer.selectedProjectUnits.forEach((p) =>
					getSensorsByProjectUnit(p)
				)
		} else {
			clearInterval(interval)
		}
	}, REFRESH_TIME)
	store
		?.getState()
		?.projectReducer.selectedProjectUnits.forEach((p) =>
			getSensorsByProjectUnit(p)
		)
}

export const fetchSensors = async () => {
	store.dispatch({
		type: SensorActions.RequestSensors,
		payload: {},
	})
	const result = await axios.get(API_URL + `sensors/`, {
		headers: authHeader(),
	})
	store.dispatch({ type: SensorActions.SetSensors, payload: result.data })
}

export const removeSensorsByProjectUnit = (projectUnit: ProjectUnit) => {
	store.dispatch({
		type: SensorActions.RemoveProjectUnitSensors,
		payload: projectUnit.id,
	})
}

export const stopSync = () => {
	live_reload = false
	interval && clearInterval(interval)
}

export const useSensorById = (id: number) => {
	return useAppSelector((s) => {
		const sensors = Object.values(
			s.sensorReducer.sensorsByProjectUnit
		).reduce<Sensor[]>((acc, v) => [...acc, ...v], [])
		return sensors.find((s) => s.id === id)
	})
}

export const deleteSensor = async (sensor: Sensor, unlink: boolean) => {
	try {
		const response = await axios.post(
			API_URL + `sensors/delete_sensor/`,
			{
				mesa: sensor.label.split('-').pop(),
				serial: sensor.installation.equipment.serial_number,
				unlink: unlink ? 1 : 0
			},
			{ headers: authHeader() }
		)
		addSnackbarNotification({ message: `Sensor ${sensor.label} eliminado.`, type: 'success' })
	} catch (e) {
		addSnackbarNotification({ message: e, type: 'error' })
	}
}

export const createSensor = async (data: {
	mesa: string
	serial: string
	norte: string
	este: string
	dataloggerSerialNumber: string
}) => {
	var retVal = false
	var response: any
	try {
		response = await axios.post(
			API_URL + `sensors/add_sensor/`,
			data,
			{ headers: authHeader() }
		)
		retVal = true
		addSnackbarNotification({ message: `Sensor LS-CV020-${data.mesa} creado.`, type: 'success' })
	} catch (e) {
		addSnackbarNotification({ message: e.response.status + " " + e.response.data.message, type: 'error' })
	}
	return retVal
}

export const getSensorById = (id: number) => {
	var sensor: Sensor
	Object.values(store.getState().sensorReducer.sensorsByProjectUnit).forEach(
		(sl) =>
			sl.forEach((s) => {
				if (s.id === id) sensor = s
			})
	)
	return sensor
}

export const clearSensors = () => {
	interval && clearInterval(interval)
	interval = null
	live_reload = false
	store.dispatch({ type: SensorActions.ClearSensors, payload: {} })
}

export const createDefaultDeltaChannel = async (sensor: Sensor) => {
	var retVal = false
	var response: any
	try {
		response = await axios.post(
			API_URL + `sensors/${sensor.id}/add_v_delta_channel/`,
			{},
			{ headers: authHeader() }
		)
		retVal = true
		addSnackbarNotification({ message: 'Canal virtual delta creado correctamente', type: 'success' })
	} catch (e) {
		retVal = false
		addSnackbarNotification({ message: e.response.status + " " + e.response.data.message, type: 'error' })
	}
	return retVal
}

export const exportAllChannelsToExcel = async (sensor: Sensor) => {
	try {
		const response = await axios.get(
			API_URL + `sensors/${sensor.id}/export_all_channels_to_excel/`,
			{ headers: authHeader() }
		)
			// .then((response) => {
			// 	var link = document.createElement('a')
			// 	link.href = window.URL.createObjectURL(
			// 		new Blob([response.data], {
			// 			type: 'application/octet-stream',
			// 		})
			// 	)
			// 	link.download = sensor.label + '.zip'
			// 	document.body.appendChild(link)
			// 	link.click()
			// })
		addSnackbarNotification({ message: 'Datos exportados correctamente', type: 'success' })
	} catch (e) {
		addSnackbarNotification({ message: "Error", type: 'error' })
		// addSnackbarNotification({ message: e.response.status  + " " + e.response.data.message, type: 'error' })
	}
}