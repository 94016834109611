import { TreeItem, TreeView } from '@material-ui/lab'
import {
	mdiDownload,
	mdiFileDocumentOutline,
	mdiFileExcelOutline,
	mdiFileOutline,
	mdiFilePdfOutline,
	mdiFilePlus,
	mdiFolder,
	mdiFolderPlus,
	mdiReload,
} from '@mdi/js'
import { Icon } from '@mdi/react'
import { useState } from 'react'
import { useEffect } from 'react'
import useStyles from '../../styles/appStyles'
import { useAppSelector } from '../../hooks/storeHooks'
import {
	fetchRepositoryElements,
	fetchRootNodes,
} from '../../services/repositoryElement'
import {
	Button,
	FormControlLabel,
	IconButton,
	LinearProgress,
	Radio,
	RadioGroup,
} from '@material-ui/core'
import axios from 'axios'
import { authHeader } from '../../services/auth'
import PdfViewer from './PdfViewer'
import NewFolderModal from './NewFolderModal'
import UploadFileModal from './UploadFileModal'

interface BlobObj {
	id: number
	name: string
	blob: Blob
}

const FileRepository: React.FC = () => {
	const rootNodes = useAppSelector(
		(s) => s.repositoryElementReducer.rootNodes
	)
	const repositoryElements = useAppSelector(
		(s) => s.repositoryElementReducer.nodes
	)
	const [currentFolder, setCurrentFolder] = useState(null)
	const [currentFile, setCurrentFile] = useState(null)
	const [expandedNodes, setExpandedNodes] = useState([])
	const [viewType, setViewType] = useState<'list' | 'icons'>('list')
	const [blobs, setBlobs] = useState<BlobObj[]>([])
	const [isLoadingBlob, setIsLoadingBlob] = useState(false)
	const [currentBlob, setCurrentBlob] = useState(null)
	const [newFolderModalOpen, setNewFolderModalOpen] = useState(false)
	const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false)

	const loadBlob = async (node: RepositoryElement) => {
		setIsLoadingBlob(true)
		try {
			const foundBlob = blobs.find((blobObj) => blobObj.id === node.id)
			if (foundBlob) {
				setCurrentBlob(foundBlob)
			} else {
				const response = await axios.get(
					process.env.REACT_APP_API_URL +
						`repository/${node.id}/download/`,
					{
						headers: authHeader(),
						responseType: 'blob',
					}
				)
				const filename = response.headers['content-disposition']
					.split('="')[1]
					.split('"')[0]
				const newBlob = {
					id: node.id,
					blob: new Blob([response.data], {
						type: response.headers['content-type'],
					}),
					name: filename,
				}
				setBlobs(blobs.concat(newBlob))
				setCurrentBlob(newBlob)
				const fileURL = URL.createObjectURL(newBlob.blob)
			}
		} catch (err) {}
		setIsLoadingBlob(false)
	}

	const downloadFile = (url: string) => {
		const link = document.createElement('a')
		link.href = url
		link.setAttribute('download', currentBlob.name)
		link.setAttribute('target', '_blank')
		document.body.appendChild(link)
		link.click()
		link.parentNode.removeChild(link)
	}

	const fileView = (node: RepositoryElement) => {
		return (
			<div style={{ flexDirection: 'row' }}>
				{currentFile &&
				currentBlob?.blob &&
				currentFile.extension === 'pdf' ? (
					<PdfViewer
						name={currentBlob.name}
						report={URL.createObjectURL(currentBlob.blob)}
					/>
				) : (
					<>
						<div className='sticky animate-pulse'>
							<IconButton
								color='primary'
								onClick={() =>
									currentBlob &&
									downloadFile(
										URL.createObjectURL(currentBlob.blob)
									)
								}
							>
								<Icon
									path={mdiDownload}
									className='w-8 h-8'
									color={'#333'}
								></Icon>
							</IconButton>
						</div>
						<div> No se puede previsualizar </div>
					</>
				)}
			</div>
		)
	}

	const humanFileSize = (size: number) => {
		const i = Math.floor(Math.log(size) / Math.log(1024))
		const aux = (size / Math.pow(1024, i)).toFixed(2)
		return aux + ['B', 'kB', 'MB', 'GB', 'TB'][i]
	}

	const explorerTopBar = () => {
		return (
			<>
				<div className='w-full bg-gray-100 flex flex-row'>
					{currentFolder && (
						<div className='w-2/4 text-bg flex-start'>
							{currentFolder.full_path === 'None'
								? '/' + currentFolder.name + '/'
								: currentFolder.full_path +
								  '/' +
								  currentFolder.name +
								  '/'}
							<br />
							{/* {currentFile && console.log(currentFile) } */}
							{currentFile &&
								`${currentFile.file
									.split('/')
									[
										currentFile.file.split('/').length - 1
									].toString()} (${humanFileSize(
									currentFile.size
								)})`}
							{/* {currentFile && JSON.stringify(currentFile)} */}
						</div>
					)}
					<div className='w-2/4 flex flex-row flex-end'>
						<div className='mr-0'>
							<RadioGroup
								aria-label='gender'
								name='gender1'
								value={viewType}
								onChange={handleViewType}
								row
								style={{ flexDirection: 'row-reverse' }}
							>
								<FormControlLabel
									value='list'
									control={<Radio color='primary' />}
									label='Lista'
									color='secondary'
								/>
								<FormControlLabel
									value='icons'
									control={<Radio color='primary' />}
									label='Iconos'
									color='secondary'
								/>
							</RadioGroup>
						</div>
					</div>
				</div>
				{isLoadingBlob && (
					<div className='w-full'>
						<LinearProgress />
					</div>
				)}
			</>
		)
	}

	const nodeIcon = (node: RepositoryElement) => {
		var iconPath = mdiFolder
		if (node.element_type === 'f') {
			switch (node.extension) {
				case 'pdf':
					iconPath = mdiFilePdfOutline
					break
				case 'doc':
					iconPath = mdiFileDocumentOutline
					break
				case 'docx':
					iconPath = mdiFileDocumentOutline
					break
				case 'xls':
					iconPath = mdiFileExcelOutline
					break
				case 'xlsx':
					iconPath = mdiFileExcelOutline
					break
				default:
					iconPath = mdiFileOutline
					break
			}
		}
		return iconPath
	}

	const folderView = (node: RepositoryElement) => {
		return (
			<>
				<div
					className={`flex ${
						viewType === 'icons' ? 'gap-2' : 'flex-col'
					}`}
				>
					{node?.children?.length === 0 && (
						<div>No hay elementos</div>
					)}
					{node?.children?.length > 0 &&
						node.children.map((child_id) => {
							const child_node = repositoryElements.find(
								(n) => n.id === child_id
							)
							return (
								child_node && (
									<div
										onClick={() => {
											switch (child_node.element_type) {
												case 'd':
													setCurrentFolder(child_node)
													setCurrentFile(null)
													setExpandedNodes(
														expandedNodes.concat(
															child_node.parent
														)
													)
													break
												case 'f':
													setCurrentFile(child_node)
													loadBlob(child_node)
													break
											}
										}}
										title={child_node.name}
										key={child_node.id}
										className={
											viewType === 'icons'
												? 'rounded-lg w-44 h-44  cursor-pointer flex flex-col justify-around items-center p-4 bg-gray-200 hover:bg-gray-300 transition-all'
												: 'w-full'
										}
									>
										{viewType === 'icons' ? (
											<>
												<div className='w-20 h-20'>
													<Icon
														path={nodeIcon(
															child_node
														)}
														className='w-20 h-20 text-white'
														color={
															child_node.element_type ===
															'd'
																? '#BF8000'
																: '#666'
														}
													/>
												</div>
												<div
													className='font-medium text-lg text-darkgrey truncate'
													style={{
														maxWidth: '10rem',
													}}
												>
													{child_node.name}
												</div>
											</>
										) : (
											<>
												<div className='w-full flex'>
													<p
														className={
															'inline-flex'
														}
													>
														<Icon
															path={nodeIcon(
																child_node
															)}
															size={1}
															color={
																child_node.element_type ===
																'd'
																	? '#BF8000'
																	: '#666'
															}
														/>
														{child_node.name}
													</p>
												</div>
											</>
										)}
									</div>
								)
							)
						})}
				</div>
			</>
		)
	}

	const renderTree = (node: RepositoryElement) => {
		return (
			<TreeItem
				key={node.id}
				nodeId={node.id.toString()}
				id={node.id.toString()}
				label={node.name}
				onLabelClick={() => {
					if (node.element_type == 'd') {
						setCurrentFolder(node)
						setCurrentFile(null)
						if (expandedNodes.find((id) => id === node.id)) {
							setExpandedNodes(
								expandedNodes.filter((id) => id !== node.id)
							)
						} else {
							setExpandedNodes(expandedNodes.concat(node.id))
						}
					}
					if (node.element_type == 'f') {
						setCurrentFile(node)
						const parent = repositoryElements.find(
							(n) => n.id === node.parent
						)
						setCurrentFolder(parent)
						loadBlob(node)
					}
				}}
				icon={
					<Icon
						path={nodeIcon(node)}
						className='w-6'
						color={node.element_type === 'd' ? '#BF8000' : '#666'}
					/>
				}
			>
				{Array.isArray(node.children)
					? node.children.map((node_id) => {
							const child_node = repositoryElements?.find(
								(n) => n.id === node_id
							)
							if (child_node) {
								return renderTree(child_node)
							}
					  })
					: null}
			</TreeItem>
		)
	}

	useEffect(() => {
		fetchRepositoryElements()
		!rootNodes.length && fetchRootNodes()
		rootNodes.length && setCurrentFolder(rootNodes[0])
		rootNodes.length && setExpandedNodes([rootNodes[0].id])
	}, [])

	const handleViewType = (e) => {
		setViewType(e.target.value)
	}

	const treeView = () => (
		<>
			<div className='p-2 w-full flex items-center justify-around'>
				<Button
					onClick={() => {
						fetchRepositoryElements()
						fetchRootNodes()
						setBlobs([])
						rootNodes.length && setCurrentFolder(rootNodes[0])
						setCurrentFile(null)
						rootNodes.length && setExpandedNodes([rootNodes[0].id])
					}}
					variant='outlined'
				>
					<Icon path={mdiReload} className='w-6' color='#333' />
				</Button>
				<Button
					variant='outlined'
					onClick={() => {
						setNewFolderModalOpen(true)
					}}
					disabled={!currentFolder?.allow_upload}
				>
					<Icon path={mdiFolderPlus} className='w-6' color='#333' />
				</Button>
				<Button
					variant='outlined'
					onClick={() => {
						setUploadFileModalOpen(true)
					}}
					disabled={!currentFolder?.allow_upload}
				>
					<Icon path={mdiFilePlus} className='w-6' color='#333' />
				</Button>
			</div>
			<TreeView
				expanded={expandedNodes.map((n) => n.toString())}
				selected={currentFolder ? [currentFolder.id.toString()] : []}
				defaultExpanded={
					rootNodes.length ? [rootNodes[0].id.toString()] : []
				}
			>
				{rootNodes.length &&
					rootNodes.map((n) => {
						return renderTree(n)
					})}
			</TreeView>
		</>
	)

	const classes = useStyles(true)()

	return (
		<>
			<div className={`flex ${classes.bodyContent} `}>
				<div className='w-1/5 bg-gray-200 p-2 flex flex-col overflow-auto'>
					{treeView()}
				</div>
				<div className='w-4/5 bg-gray-100 p-2 flex flex-col overflow-auto '>
					{!currentFile?.name.includes('pdf') && explorerTopBar()}
					<div className='w-full'>
						{/* {'currentFolder: ' + currentFolder && currentFolder ? currentFolder.name : ''} */}
						{currentFile
							? fileView(currentFile)
							: folderView(currentFolder)}
					</div>
				</div>
			</div>
			{currentFolder && (
				<NewFolderModal
					open={newFolderModalOpen}
					onClose={() => {
						setCurrentFolder(
							repositoryElements.find(
								(n) => n.id === currentFolder.id
							)
						)
						setNewFolderModalOpen(false)
						fetchRepositoryElements()
						fetchRootNodes()
						setBlobs([])
						rootNodes.length && setCurrentFolder(rootNodes[0])
						setCurrentFile(null)
						rootNodes.length && setExpandedNodes([rootNodes[0].id])
					}}
					parent={currentFolder}
				></NewFolderModal>
			)}
			{currentFolder && (
				<UploadFileModal
					open={uploadFileModalOpen}
					onClose={() => {
						setCurrentFolder(
							repositoryElements.find(
								(n) => n.id === currentFolder.id
							)
						)
						setUploadFileModalOpen(false)
						fetchRepositoryElements()
						fetchRootNodes()
						setBlobs([])
						rootNodes.length && setCurrentFolder(rootNodes[0])
						setCurrentFile(null)
						rootNodes.length && setExpandedNodes([rootNodes[0].id])
					}}
					parent={currentFolder}
				></UploadFileModal>
			)}
		</>
	)
}
export default FileRepository
