import { Reducer } from 'react'

interface State {
	isLoading: { [projectUnitId: number]: boolean }
	isFetching: boolean
	sensorsByProjectUnit: { [projectUnitId: number]: Sensor[] }
	selectedSensor: Sensor
	sensors: Sensor[]
}

const initialState: State = {
	isLoading: {},
	sensorsByProjectUnit: {},
	selectedSensor: null,
	sensors: [],
	isFetching: false,
}

export enum SensorActions {
	RequestSensorsByProjectUnit = 'REQUEST_SENSORS_BY_PROJECT_UNIT',
	RequestSensors = 'REQUEST_SENSORS',
	SetProjectUnitSensors = 'SET_PROJECT_UNIT_SENSORS',
	SetSensors = 'SET_SENSORS',
	SetSelectedSensor = 'SET_SELECTED_SENSOR',
	RemoveProjectUnitSensors = 'REMOVE_PROJECT_UNIT_SENSOSRS',
	GetChannelsBySensor = 'GET_CHANNELS_BY_SENSOR',
	ClearSensors = 'CLEAR_SENSORS',
}

const reducer: Reducer<State, { type: SensorActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case SensorActions.RequestSensorsByProjectUnit:
			return {
				...state,
				isLoading: {
					...state.isLoading,
					[payload.projectUnitId]: true,
				},
			}
		case SensorActions.RequestSensors:
			return {
				...state,
				isFetching: true,
			}
		case SensorActions.SetProjectUnitSensors:
			return {
				...state,
				isLoading: {
					...state.isLoading,
					[payload.projectUnitId]: false,
				},
				sensorsByProjectUnit: {
					...state.sensorsByProjectUnit,
					[payload.projectUnitId]: payload.sensors,
				},
			}
		case SensorActions.SetSelectedSensor:
			return { ...state, selectedSensor: payload }
		case SensorActions.SetSensors:
			return { ...state, sensors: payload }
		case SensorActions.RemoveProjectUnitSensors:
			return {
				...state,
				sensorsByProjectUnit: {
					...state.sensorsByProjectUnit,
					[payload]: [],
				},
			}
		case SensorActions.ClearSensors:
			return initialState
		default:
			return state
	}
}

export default reducer
