/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core"
import * as d3 from "d3"
import Table from "@material-ui/core/Table"
import { mdiCheck, mdiCloseThick, mdiPlus } from "@mdi/js"
import Icon from "@mdi/react"
import { useEffect, useState } from "react"
import { useSize } from "../../../hooks/useSize"
import {
    addCondition,
    changeConditionGroupProperties,
    deleteCondition,
    getConditionGroups,
    unassignChannel,
} from "../../../services/conditionGroup"
import { ACTION_ICON_MAP } from "../../../styles/actionIcons"
import Spinner from "../../Shared/Spinner"
import EditableLabel from "../../Shared/EditableLabel"
import { changeConditionProperties } from "../../../services/condition"
import EditableColorLabel from "../../Shared/EditableColorLabel"
import { useAppSelector } from "../../../hooks/storeHooks"
import ColorPicker from "material-ui-color-picker"
import { useForm } from "../../../hooks/useForm"
import { Permissions } from "../../../config/Permissions"
import AddChannelToConditionGroupModal from "./AddChannelToConditionGroupModal"
import QuestionModal from "../../Shared/QuestionModal"
import { getChannels } from "../../../services/channel"
import { getActionModeById } from "../../../services/actionMode"
import AsignActionModesModal from "./AsignActionModesModal"
import { withError } from "../../../services/snackbarNotification"

interface Props {
    conditionGroupId: number
}

const initialConditionValues = {
    value_1: "",
    value_2: "",
    name: "",
    condition_type: "btw",
    color: "#FFF",
    description: "",
    priority: 1,
}

const ConditionGroupTable: React.FC<Props> = ({ conditionGroupId }) => {
    const conditionGroup = useAppSelector((s) =>
        s.conditionGroupReducer.conditionGroups.find(
            (cg) => cg.id === conditionGroupId
        )
    )
    const [targetChannel, setTargetChannel] = useState<Channel>(null)
    const [asignActionModesModalOpen, setAsignActionModesModalOpen] =
        useState(false)
    const [unassignChannelModalOpen, setUnassignChannelModalOpen] =
        useState(false)
    const [deleteConditionModalOpen, setDeleteConditionModalOpen] =
        useState(false)
    const [targetCondition, setTargetCondition] = useState(null)
    const loading = useAppSelector(
        (s) => s.conditionGroupReducer.loadingConditionGroups
    )
    const [addChannelModalOpen, setAddChannelModalOpen] = useState(false)
    const [conditionValues, setConditionValues, handleConditionChange] =
        useForm<Partial<Condition>>(initialConditionValues)
    const [rangeContainer, width, height] = useSize<HTMLDivElement>()
    const [parsedConditions, setParsedRanges] = useState([])
    const canEdit = useAppSelector(
        (s) =>
            s.authReducer.isEditing &&
            !!s.authReducer.permissions.find(
                (p) => p === Permissions.ModifyConditions
            )
    )

    const channels = useAppSelector((s) =>
        s.channelReducer.channels.filter((ch) =>
            conditionGroup?.channels.includes(ch.id)
        )
    )
    useEffect(() => {
        getConditionGroups()
    }, [conditionGroupId])

    useEffect(() => {
        if (conditionGroup) {
            setParsedRanges(
                conditionGroup.conditions.map((c) => {
                    const v1 = c.value_1.includes("e")
                        ? Infinity * (c.value_1.includes("-") ? -1 : 1)
                        : +c.value_1
                    const v2 = c.value_2.includes("e")
                        ? Infinity * (c.value_2.includes("-") ? -1 : 1)
                        : +c.value_2
                    return {
                        ...c,
                        value_1: Math.min(v1, v2),
                        value_2: Math.max(v1, v2),
                    }
                })
            )
        }
    }, [conditionGroup])

    const yScale = (v: number): number => {
        const min = parsedConditions.reduce(
            (acc, v) =>
                v.value_1 === -Infinity ? acc : Math.min(acc, v.value_1),
            Infinity
        )
        const max = parsedConditions.reduce(
            (acc, v) =>
                v.value_2 === Infinity ? acc : Math.max(acc, v.value_2),
            -Infinity
        )
        return d3
            .scaleLinear()
            .domain([min - (max - min) / 3, max + (max - min) / 3])
            .range([0, width])(v)
    }

    return loading && !conditionGroup ? (
        <Spinner></Spinner>
    ) : (
        <div className="mb-3 flex-1 flex flex-col overflow-auto">
            <div className="mb-2">
                {" "}
                <EditableLabel
                    value={conditionGroup?.name}
                    id="name"
                    label="Nombre"
                    onSubmit={(name) =>
                        changeConditionGroupProperties(conditionGroup, {
                            name,
                        }).then(getConditionGroups)
                    }
                    permissions={[Permissions.ModifyConditions]}
                    fullwidth
                />
            </div>
            <div className="h-3/5 overflow-auto p-1 ">
                <TableContainer component={Paper}>
                    <Table className="w-full" aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Desde</TableCell>
                                <TableCell>Hasta</TableCell>
                                <TableCell>Acción</TableCell>
                                <TableCell>Color</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!conditionGroup?.conditions.length &&
                                conditionGroup.conditions.map(
                                    (c: Condition) => (
                                        <TableRow key={c.name}>
                                            <TableCell>
                                                <EditableLabel
                                                    value={c.name}
                                                    id="name"
                                                    label="Nombre"
                                                    onSubmit={(name) =>
                                                        changeConditionProperties(
                                                            c,
                                                            {
                                                                name,
                                                            }
                                                        ).then(
                                                            getConditionGroups
                                                        )
                                                    }
                                                    permissions={[
                                                        Permissions.ModifyConditions,
                                                    ]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <EditableLabel
                                                    value={c.value_1}
                                                    id="name"
                                                    label="Valor 1"
                                                    onSubmit={(value_1) =>
                                                        changeConditionProperties(
                                                            c,
                                                            {
                                                                value_1,
                                                            }
                                                        ).then(
                                                            getConditionGroups
                                                        )
                                                    }
                                                    permissions={[
                                                        Permissions.ModifyConditions,
                                                    ]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <EditableLabel
                                                    value={c.value_2}
                                                    id="value_2"
                                                    label="Valor 2"
                                                    onSubmit={(value_2) =>
                                                        changeConditionProperties(
                                                            c,
                                                            {
                                                                value_2,
                                                            }
                                                        ).then(
                                                            getConditionGroups
                                                        )
                                                    }
                                                    permissions={[
                                                        Permissions.ModifyConditions,
                                                    ]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <div
                                                    className={`flex text-center `}
                                                >
                                                    {!!c.action_modes
                                                        ?.length ? (
                                                        c.action_modes.map(
                                                            (am) => (
                                                                <div
                                                                    title={
                                                                        getActionModeById(
                                                                            am
                                                                        ).name
                                                                    }
                                                                    onClick={() => {
                                                                        if (
                                                                            canEdit
                                                                        ) {
                                                                            setTargetCondition(
                                                                                c
                                                                            )
                                                                            setAsignActionModesModalOpen(
                                                                                true
                                                                            )
                                                                        }
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        className={`w-7 ml-1 text-gray-500  transition-all rounded-full p-1 ${canEdit
                                                                            ? "cursor-pointer"
                                                                            : ""
                                                                            }`}
                                                                        path={
                                                                            ACTION_ICON_MAP[
                                                                            getActionModeById(
                                                                                am
                                                                            )
                                                                                .task
                                                                            ] ??
                                                                            mdiCheck
                                                                        }
                                                                    ></Icon>
                                                                </div>
                                                            )
                                                        )
                                                    ) : (
                                                        <div
                                                            onClick={() => {
                                                                if (canEdit) {
                                                                    setTargetCondition(
                                                                        c
                                                                    )
                                                                    setAsignActionModesModalOpen(
                                                                        true
                                                                    )
                                                                }
                                                            }}
                                                        >
                                                            <Icon
                                                                className={`w-7 ml-1 text-gray-500  transition-all rounded-full p-1 ${canEdit
                                                                    ? "cursor-pointer"
                                                                    : ""
                                                                    }`}
                                                                path={
                                                                    mdiCloseThick
                                                                }
                                                            ></Icon>
                                                        </div>
                                                    )}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <EditableColorLabel
                                                    id="color"
                                                    label="Color"
                                                    value={c.color}
                                                    onSubmit={(color) =>
                                                        changeConditionProperties(
                                                            c,
                                                            {
                                                                color,
                                                            }
                                                        ).then(
                                                            getConditionGroups
                                                        )
                                                    }
                                                    permissions={[
                                                        Permissions.ModifyConditions,
                                                    ]}
                                                ></EditableColorLabel>
                                            </TableCell>

                                            {canEdit && (
                                                <TableCell
                                                    align="right"
                                                    className="text-right"
                                                    size="small"
                                                >
                                                    <div
                                                        className="w-5 h-5"
                                                        onClick={() => {
                                                            setTargetCondition(
                                                                c
                                                            )
                                                            setDeleteConditionModalOpen(
                                                                true
                                                            )
                                                        }}
                                                    >
                                                        <Icon
                                                            path={mdiCloseThick}
                                                            className="text-gray-700 hover:text-red-500 cursor-pointer transition-all"
                                                        ></Icon>
                                                    </div>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    )
                                )}
                        </TableBody>
                    </Table>
                    {canEdit && (
                        <>
                            <div className="flex mt-2  justify-around">
                                <div>
                                    <TextField
                                        id="name"
                                        fullWidth
                                        color="primary"
                                        label="Nombre"
                                        size="medium"
                                        value={conditionValues.name}
                                        onChange={handleConditionChange}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        id="value_1"
                                        fullWidth
                                        color="primary"
                                        label="Valor 1"
                                        size="medium"
                                        value={conditionValues.value_1}
                                        onChange={handleConditionChange}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        id="value_2"
                                        fullWidth
                                        color="primary"
                                        label="Valor 2"
                                        size="medium"
                                        value={conditionValues.value_2}
                                        onChange={handleConditionChange}
                                    />
                                </div>
                                <div>
                                    <div className="flex items-center">
                                        <div>
                                            <ColorPicker
                                                name="color"
                                                id="color"
                                                value={conditionValues.color}
                                                size="medium"
                                                fullWidth
                                                label="Color"
                                                onChange={(color) =>
                                                    setConditionValues((v) => ({
                                                        ...v,
                                                        color,
                                                    }))
                                                }
                                                InputProps={{
                                                    value: conditionValues.color,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                        <div
                                            className=" ml-1 w-5 h-5 rounded-full shadow"
                                            style={{
                                                backgroundColor:
                                                    conditionValues.color,
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center my-2">
                                <div
                                    className="rounded-full text-main-1 cursor-pointer hover:text-opacity-75 transition-all w-7 m-auto"
                                    onClick={async () => {
                                        await addCondition(
                                            {
                                                ...initialConditionValues,
                                                ...conditionValues,
                                                action_modes: [],
                                            },
                                            conditionGroup
                                        )
                                        getConditionGroups()
                                        setConditionValues(
                                            initialConditionValues
                                        )
                                    }}
                                >
                                    <Icon path={mdiPlus}></Icon>
                                </div>
                            </div>
                        </>
                    )}
                    <div
                        className="h-32 w-full"
                        ref={rangeContainer}
                        onClick={() =>
                            parsedConditions.forEach((c) =>
                                console.log(
                                    parseInt(c.value_1),
                                    parseInt(c.value_2)
                                )
                            )
                        }
                    >
                        <svg width={width} height={height}>
                            {parsedConditions.map((c, i) => {
                                const x1 =
                                    c.value_1 === -Infinity
                                        ? 0
                                        : yScale(c.value_1)
                                const x2 =
                                    c.value_2 === Infinity
                                        ? width
                                        : yScale(c.value_2)
                                const conditionWidth = Math.abs(x1 - x2)
                                return (
                                    <>
                                        <rect
                                            x={x1}
                                            y={0}
                                            width={conditionWidth}
                                            height={height / 2}
                                            fill={c.color}
                                            opacity={0.5}
                                            stroke={"#555"}
                                        ></rect>
                                        <text
                                            x={x1 + conditionWidth / 2}
                                            color="white"
                                            y={height / 4}
                                            textAnchor="middle"
                                        >
                                            {c.name}
                                        </text>
                                        <text
                                            x={x1 + conditionWidth}
                                            color="#777"
                                            y={(height * 3) / 4}
                                            textAnchor={
                                                i ===
                                                    parsedConditions.length - 1
                                                    ? "end"
                                                    : "middle"
                                            }
                                        >
                                            {c.value_2}
                                        </text>
                                        <text
                                            x={x1}
                                            color="#777"
                                            y={(height * 3) / 4}
                                            textAnchor={
                                                i === 0 ? "start" : "middle"
                                            }
                                        >
                                            {c.value_1}
                                        </text>
                                    </>
                                )
                            })}
                        </svg>
                    </div>
                </TableContainer>
            </div>
            <div className="flex-1 overflow-auto  mt-2">
                <div className="flex justify-between">
                    Canales asignados{" "}
                    {canEdit && (
                        <Button
                            variant="contained"
                            color="primary"
                            className="p-1"
                            onClick={() => setAddChannelModalOpen(true)}
                        >
                            Agregar
                        </Button>
                    )}
                </div>
                <TableContainer component={Paper} className="overflow-auto">
                    <Table className="" size="small">
                        <TableBody>
                            {channels?.map((ch) => (
                                <TableRow>
                                    <TableCell>{ch.label}</TableCell>

                                    <TableCell
                                        align="right"
                                        className="text-right"
                                    >
                                        {canEdit && (
                                            <div className="flex justify-end">
                                                <div
                                                    className="w-5 h-5"
                                                    onClick={() => {
                                                        setTargetChannel(ch)
                                                        setUnassignChannelModalOpen(
                                                            true
                                                        )
                                                    }}
                                                >
                                                    <Icon
                                                        path={mdiCloseThick}
                                                        className="text-gray-700 hover:text-red-500 cursor-pointer transition-all"
                                                    ></Icon>
                                                </div>
                                            </div>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <AddChannelToConditionGroupModal
                open={addChannelModalOpen}
                onClose={() => setAddChannelModalOpen(false)}
            ></AddChannelToConditionGroupModal>
            <AsignActionModesModal
                open={asignActionModesModalOpen}
                onClose={() => setAsignActionModesModalOpen(false)}
                condition={targetCondition}
            ></AsignActionModesModal>
            <QuestionModal
                onYes={withError(() =>
                    unassignChannel(targetChannel, conditionGroup).then(() => {
                        getConditionGroups()
                        getChannels()
                    }))
                }
                open={unassignChannelModalOpen}
                text={`¿Seguro que desea remover el canal ${targetChannel?.label} del este grupo?`}
                onClose={() => setUnassignChannelModalOpen(false)}
                snackbarNotificationText="Canal removido del grupo"
            ></QuestionModal>
            <QuestionModal
                onYes={withError(() =>
                    deleteCondition(targetCondition, conditionGroup).then(
                        () => {
                            getConditionGroups()
                            getChannels()
                        }
                    ))
                }
                open={deleteConditionModalOpen}
                text={`¿Seguro que desea eliminar esta condición?`}
                onClose={() => setDeleteConditionModalOpen(false)}
                snackbarNotificationText="Condición eliminada"
            ></QuestionModal>
        </div>
    )
}
export default ConditionGroupTable
