import realAxios from 'axios'
import { ActionModeActions } from '../reducers/ActionModeReducer'
import realStore from '../store'
import { authHeader } from './auth'

const API_URL = process.env.REACT_APP_API_URL

const ACTION_MODE_URL = process.env.REACT_APP_PATH_ACTION_MODDES



export const getActionModes = async (axios = realAxios, store = realStore) => {
    store.dispatch({ type: ActionModeActions.RequestActionModes, payload: {} })
    const response = await axios
        .get(API_URL + ACTION_MODE_URL,
            { headers: authHeader() }
        )
    store.dispatch({ type: ActionModeActions.SetActionModes, payload: response.data })
}


export const getActionModeById = (id: number, store = realStore) => store.getState().actionModeReducer.actionModes.find(am => am.id === id)

