interface Props {
    y: number
    x1: number
    x2: number
    symbol: string
    value: number
    textX: string
}

const Tick: React.FC<Props> = ({ y, x1, x2, symbol, value, textX }) => {
    return (
        <g>
            <line x1={x1} y1={y} x2={x2} y2={y} stroke="#DDD"></line>
            <text
                x={textX}
                y={y}
                textAnchor="end"
                className="font-light text-gray-600"
            >
                {value}
                {" " + symbol}
            </text>
        </g>
    )
}
export default Tick
